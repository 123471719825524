import Vue from "vue";

import OsGridRef, { LatLon, Dms } from "./util/geodesy/osgridref.js";

export function getGeoLocationCurrentPosition(){
    return new Promise(function (success, error) {
        navigator.geolocation.getCurrentPosition(success, error,  {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        });
    });
}

export function latLonToAgsPoint(AgsPointConstructorFunction, lat, lon, spatialReference){
  
  const gridRef = new LatLon(lat, lon).toOsGrid();
  return new AgsPointConstructorFunction({
    x: gridRef.easting,
    y: gridRef.northing,
    spatialReference: spatialReference
  });    
}

export function coordStringToAgsPoint(AgsPointConstructorFunction, coordString, spatialReference, coordType) {
  
  let gridRef;      
  
  if (coordType === "gridref") {
    gridRef = OsGridRef.parse(coordString);          
  } else if (coordType === "latlon") {
    
    let strings = coordString.trim().split(",");    

    let lat = Dms.parse(strings[0].trim());
    let lon = Dms.parse(strings[1].trim());
    let latLong = new LatLon(lat, lon);         
    gridRef = latLong.toOsGrid();    
  } else if (coordType === "xy") {
    let strings = coordString.trim().split(",");

    if (strings.length != 2) {
      return false;
    }

    gridRef = new OsGridRef(strings[0].trim(), strings[1].trim());      
  } else {
    return null;
  }

  return new AgsPointConstructorFunction({
    x: gridRef.easting,
    y: gridRef.northing,    
    z: null,
    hasZ: false,
    m: null,
    hasM: false,
    spatialReference: spatialReference
  });         
}

export function agsPointToCoordString(point, coordinateMode) {

  let gridref;

  switch (coordinateMode){
    case "xy":        
      return point.x.toFixed(3) + ", " + point.y.toFixed(3);  
    case "xyNonPrecise":        
      return point.x.toFixed(0) + ", " + point.y.toFixed(0);  
    case "latlon":        
      gridref = new OsGridRef(point.x, point.y);
      const pWgs84 = gridref.toLatLon();
      return pWgs84.toString();            
    case "gridref":        
      gridref = new OsGridRef(point.x, point.y);
      return gridref.toString(8);
  }    
}

export function isXYString(xyString) {
  
  let strings = xyString.trim().split(",");

  if (strings.length != 2) {
    return false;
  }

  if (strings.some(coord => {
    return (coord.trim() === "")
  })) {
    return false;
  }       

  try {
    new OsGridRef(strings[0].trim(), strings[1].trim());  
  } catch {
    return false;
  }

  return true;
}

export function isLatLongString(latLongString) {
  let strings = latLongString.trim().split(",");

  if (strings.length != 2) {
    return false;
  }
  
  try {
    let lat = Dms.parse(strings[0].trim());
    let lon = Dms.parse(strings[1].trim());
    new LatLon(lat, lon);
  } catch {
    return false;
  }

  return true;  
}

export function isGridRefString(gridRefString) {
  try {
    OsGridRef.parse(gridRefString);
  } catch {
    return false;
  }

  return true;    
}

export function isValidCoordString(coordString, coordMode) {
  switch (coordMode) {
    case "xy":        
    case "xyNonPrecise":        
      return isXYString(coordString);
      break;    
    case "latlon":        
      return isLatLongString(coordString);
      break;           
    case "gridref":        
      return isGridRefString(coordString);
      break;
  }      
}

export function distanceBetweenPoints(x1, y1, x2, y2) {  
  return Math.sqrt(Math.pow(x2 - x1, 2) + (Math.pow(y2 - y1, 2)));  
}

export function getPolylineDistance(polyline) {

  let distance = 0;

  for (let pathIndex = 0; pathIndex < polyline.paths.length; pathIndex++)
  {
    var path = polyline.paths[pathIndex];        
    
    for (var pointIndex = 1; pointIndex < path.length; pointIndex++) {  
      let x1 = path[pointIndex-1][0];  
      let y1 = path[pointIndex-1][1];  
      let x2 = path[pointIndex][0];  
      let y2 = path[pointIndex][1];  

      distance += distanceBetweenPoints(x1, y1, x2, y2);
    }
  }

  return distance;
}

export function getPointAlongPolyline(polyline, distance, AgsPoint) {  

  var travelledDistance = 0;  

  for (let pathIndex = 0; pathIndex < polyline.paths.length; pathIndex++)
  {
    var path = polyline.paths[pathIndex];        
    
    for (var pointIndex = 1; pointIndex < path.length; pointIndex++) {  
      let x1 = path[pointIndex-1][0];  
      let y1 = path[pointIndex-1][1];  
      let x2 = path[pointIndex][0];  
      let y2 = path[pointIndex][1];  
      
      let pathDistance = distanceBetweenPoints(x1, y1, x2, y2);  
      travelledDistance += pathDistance;  
      if (travelledDistance === distance)  
        return polyline.getPoint(pathIndex, pointIndex);  
      else if (travelledDistance > distance) {  
        let distanceDiff = pathDistance - (travelledDistance - distance);  
        let angle = Math.atan2(y2-y1, x2-x1);  
        let x3 = distanceDiff * Math.cos(angle);  
        let y3 = distanceDiff * Math.sin(angle);  
        return new AgsPoint(x1 + x3, y1 + y3, polyline.spatialReference);  
      }  
    }         
  }  
  return null;  
}