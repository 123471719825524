<template>
    <div class="mapCopyright d-flex flex-column">
        <template v-for="(copyright, index) in copyrights">
            <div :key="index">{{copyright}}</div>            
        </template>
    </div>
</template>

<script>
export default {  
    props: {
        copyrights: {
            type: Array
        }
    },        
}
</script>

<style lang="scss">

@import "././../variables";

.mapCopyright {        
    font-size: $fontSizeSm;
    color: $textColour;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: $fontSizeXsm;
    color:white;
    white-space: nowrap; 
    text-shadow:
    -1px -1px 0 rgba(30, 30, 30, 0.8),
    1px -1px 0 rgba(30, 30, 30, 0.8),
    -1px 1px 0 rgba(30, 30, 30, 0.8),
    1px 1px 0 rgba(30, 30, 30, 0.8);

}
</style>
