<template>    
    <portal :to="'menus/' + menuName">       
      <div class="userProfile h-100 d-flex flex-column">
        <SliderHeader 
        text="USER PROFILE" 
        :menuName="menuName"
        @openMenuItem="$emit('openMenuItem', $event)" 
        @closeMenuItem="$emit('closeMenuItem', $event)">
        </SliderHeader>                    
          <div class="p-2">
            <div class="d-flex flex-row mb-3">
              <div class="p-2">
                <template v-if="user.photoURL">
                  <img class="rounded-circle" :src="user.photoURL" alt="Image 1"/>
                </template>
                <template v-else>                 
                  <font-awesome-icon :icon="['far', 'user']"/>
                </template>        
              </div>
              <div class="ml-3 p-2">
                <div class="d-flex flex-column mb-3">
                  <div class="displayName font-weight-bold mb-1">{{user.displayName}}</div>
                  <div class="small">{{user.userPrincipalName}}</div>
                  <div class="small">{{user.jobTitle}}</div>
                  <div>
                  
                  <div class="mt-2"><b-link @click="$emit('signOut')">Sign Out</b-link> </div>
<!-- 
                  <div class="small mt-3">
                  Role Membership:
                  <ul class="mt-1" style="list-style-type: none;">
                  <li style="margin-left: -2rem;" v-for="role in userProfile.roles">
                  {{ role }}
                  </li>
                  </ul>
                  </div> -->
                  </div>
                  <!-- <div class="small">{{userProfile.userId}}</div> -->
                </div>
              </div>
            </div>
          </div>  
      </div>        
    </portal>
</template>

<script>

import SliderHeader from './shared/SliderHeader.vue';

export default {
  components: { SliderHeader },  
  props:
  {
    userProfile:{
      type: Object
    },
  },
  data() {
    return {
      menuName: "userProfile"
    }
  }
}    
</script>

<style lang="scss" scoped>

@import "../variables";

.userProfile {
  color: $textColour;
  background-color: $panelColour;

  .displayName{
    font-size: 1.1rem;
    color: $textColourLight;
  }
  a {
    color: $pColour;
    font-size: 0.9rem;
  }
}

</style>