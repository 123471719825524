<template>
  <div class="resizable">   
    <template v-if="isTouchResizing">
      <font-awesome-layers        
      class="resize-arrow position-fixed"
      :style="arrow1Style"
      >
        <font-awesome-icon           
        :style="circleStyle"      
        class="circle"  
        transform="grow-4"      
        icon="circle"/>              
        <font-awesome-icon                   
        class="arrow"
        transform="right-11 grow-15"
        :icon="arrow1Icon"/>                    
      </font-awesome-layers>      
      <font-awesome-layers    
      class="resize-arrow position-fixed"
      :style="arrow2Style"
      >
        <font-awesome-icon           
        :style="circleStyle"      
        class="circle"  
        transform="grow-4"      
        icon="circle"/>              
        <font-awesome-icon                   
        class="arrow"
        transform="right-11 grow-15"
        :icon="arrow2Icon"/>                    
      </font-awesome-layers>           
    </template>
    <slot></slot>       
    <template v-if="resizeTop">
      <div class="resizable-top">        
      </div>
    </template>
    <template v-if="resizeRight">
      <div class="resizable-right"></div>
    </template>
    <template v-if="resizeBottom">
      <div class="resizable-bottom">      
      </div>
    </template>
    <template v-if="resizeLeft">
        <div class="resizable-left"></div>
    </template>
  </div>
</template>

<script>
export default {
  props: {    
    resizeTop: Boolean, 
    resizeRight: Boolean,        
    resizeBottom: Boolean,
    resizeLeft: Boolean,  
    minSize: 
    {
      default: 0,
      type: Number
    },
    maxSize: 
    {
      default: undefined,
      type: Number,
    },
    size: {
      type: Number      
    },    
  },
  data() {
    return {      
      isTouchResizing: false,
      resizeArrowSize: 38,
      resizeArrowOffset: 48,      
      mouseX: 0, 
      mouseY: 0,
      offsetX: 0, 
      offsetY: 0,
      resizingTop: false,
      resizingRight: false,   
      resizingBottom: false,   
      resizingLeft: false,   
      width: undefined,    	
      height: undefined							
    }
  }, 
  computed: {            

    arrow1Icon() {
      if (this.resizingTop || this.resizingBottom) {
        return "arrow-up"
      }

      if (this.resizingLeft || this.resizingRight) {
        return "arrow-left"
      }      

      return "";
    },

    arrow2Icon() {
      if (this.resizingTop || this.resizingBottom) {
        return "arrow-down"
      }

      if (this.resizingLeft || this.resizingRight) {
        return "arrow-right"
      }      

      return "";
    },

    arrow1Style() {
      if (this.resizingTop || this.resizingBottom) {
        return {
          left: this.mouseX - 18 + "px",
          top: this.mouseY - (this.resizeArrowSize / 2) - this.resizeArrowOffset + "px"
        }
      }      

      if (this.resizingLeft || this.resizingRight) {
        return {
          left: this.mouseX - (this.resizeArrowSize / 2) - 12 - this.resizeArrowOffset + "px",
          top: this.mouseY - 8 + "px"
        }
      }      
    },

    arrow2Style() {
      if (this.resizingTop || this.resizingBottom) {
        return {
          left: this.mouseX - 18  + "px",
          top: this.mouseY - (this.resizeArrowSize / 2)  + 70 + "px"
        }
      }      

      if (this.resizingLeft || this.resizingRight) {
        return {
          left: this.mouseX - (this.resizeArrowSize / 2) + 12 + this.resizeArrowOffset + "px",
          top: this.mouseY - 8 + "px"
        }
      }      
    },

    circleStyle() {
      return {
        height: this.resizeArrowSize + "px",
        width: this.resizeArrowSize + "px"
      }
    },  

    isResizing()
    {
      return this.resizingTop ||this.resizingRight || this.resizingBottom || this.resizingLeft;
    }    
  },
  mounted() {            	    
    document.documentElement.addEventListener('mousedown', this.mouseDown, true);     
    document.documentElement.addEventListener('mousemove', this.mouseMove, true);      
    document.documentElement.addEventListener('mouseup', this.handleUp, true);    
    document.documentElement.addEventListener('touchstart', this.touchStart, true);           
    document.documentElement.addEventListener('touchmove', this.touchMove, true);           
    document.documentElement.addEventListener('touchend', this.touchEnd, true);           
  },
  beforeDestroy() {
    document.documentElement.removeEventListener('mousedown', this.mouseDown, true);      
    document.documentElement.removeEventListener('mousemove', this.mouseMove, true);      
    document.documentElement.removeEventListener('mouseup', this.handleUp, true);    
    document.documentElement.removeEventListener('touchstart', this.touchStart, true);           
    document.documentElement.removeEventListener('touchmove', this.touchMove, true);           
    document.documentElement.removeEventListener('touchend', this.touchEnd, true);               
  },     
  watch: {
    size(value) {
      if (this.resizingTop || this.resizingBottom) {
        this.height = value;        
        this.$el.style.height = this.height + "px";
      }

      if (this.resizingLeft || this.resizingRight) {
        this.width = value;        
        this.$el.style.width = this.width + "px";
      }
    }
  },
  methods: {    

    eventPath(evt) {      
      var path = (evt.composedPath && evt.composedPath()) || evt.path, 
          target = evt.target;

      if (path != null) {
        // Safari doesn't include Window, but it should.
        return (path.indexOf(window) < 0) ? path.concat(window) : path;
      }

      if (target === window) {
        return [window];
      }

      function getParents(node, memo) {
        memo = memo || [];
        var parentNode = node.parentNode;

        if (!parentNode) {
          return memo;
        }
        else {
          return getParents(parentNode, memo.concat(parentNode));
        }
      }

      return [target].concat(getParents(target), window);
    },

    touchStart(event) {            
            
      // Check if visible (i.e. every parent is not set to display-hidden)
      // https://stackoverflow.com/questions/19669786/check-if-element-is-visible-in-dom
      if (this.$el.offsetParent === null) {
        return;
      }

      if ((this.resizeLeft && this.eventPath(event)[0].classList.contains('resizable-left')) ||
          (this.resizeTop && this.eventPath(event)[0].classList.contains('resizable-top')) ||
          (this.resizeRight && this.eventPath(event)[0].classList.contains('resizable-right')) ||
          (this.resizeBottom && this.eventPath(event)[0].classList.contains('resizable-bottom'))) {
        this.isTouchResizing = true;
        this.handleDown(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
      }
    },

    touchMove(event) {      
      if (event.changedTouches.length === 1) {
        this.handleMove(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
      }
    },    

    touchEnd(event) {      
      if (event.changedTouches.length === 1) {
        this.handleUp(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
      }

      this.isTouchResizing = false;
    },    

    mouseDown(event) {      
      
      // Check if visible (i.e. every parent is not set to display-hidden)
      // https://stackoverflow.com/questions/19669786/check-if-element-is-visible-in-dom
      if (this.$el.offsetParent === null) {
        return;
      }

      this.isTouchResizing = false;

      if ((this.resizeLeft && this.eventPath(event)[0].classList.contains('resizable-left')) ||
          (this.resizeTop && this.eventPath(event)[0].classList.contains('resizable-top')) ||
          (this.resizeRight && this.eventPath(event)[0].classList.contains('resizable-right')) ||
          (this.resizeBottom && this.eventPath(event)[0].classList.contains('resizable-bottom'))) {
        this.handleDown(event.clientX, event.clientY);
        event.preventDefault && event.preventDefault();          
      }
    },

    mouseUp(event) {            
      this.handleUp(event.clientX, event.clientY);      
    },

    mouseMove(event) {      
      this.handleMove(event.clientX, event.clientY);
    },      

    handleMove(x, y) {      
      if (this.isResizing) {
        let diffX = x - this.mouseX + this.offsetX,
            diffY = y - this.mouseY + this.offsetY;
            
        this.offsetX = 0;
        this.offsetY = 0;
        let size;

        if (this.resizingTop)
        {
            if (this.height - diffY < this.minSize)
                this.offsetY = (diffY - (diffY = this.height - this.minSize));
            else if (this.maxSize && this.height - diffY > this.maxSize)
                this.offsetY = (diffY - (diffY = this.height - this.maxSize));
                                
            this.height -= diffY;            
            size = this.height;
        }

        if (this.resizingRight)
        {                  
            if (this.width + diffX < this.minSize)
                this.offsetX = (diffX - (diffX = this.minSize - this.width));
            else if (this.maxSize && this.width + diffX > this.maxSize)
                this.offsetX = (diffX - (diffX = this.maxSize - this.width));
                                            
            this.width += diffX;            
            size = this.width;
        } 

        if (this.resizingBottom)
        {                  
            if (this.height + diffY < this.minSize)
                this.offsetY = (diffY - (diffY = this.minSize - this.height));
            else if (this.maxSize && this.height + diffY > this.maxSize)
                this.offsetY = (diffY - (diffY = this.maxSize - this.height));
                                            
            this.height += diffY;            
            size = this.height;
        } 
        
        if (this.resizingLeft)
        {
            if (this.width - diffX < this.minSize)
                this.offsetX = (diffX - (diffX = this.width - this.minSize));
            else if (this.maxSize && this.width - diffX > this.maxSize)
                this.offsetX = (diffX - (diffX = this.width - this.maxSize));
                                
            this.width -= diffX;            
            size = this.width;
        }
      
        this.mouseX = x;
        this.mouseY = y;

        this.$emit('resizing', this, { size: size });
      }
    },   

    handleDown(x, y) {							                
  
      if (this.resizeTop || this.resizeLeft || this.resizeBottom || this.resizeRight)
      {                
        if (this.resizeLeft || this.resizeRight)
        {
          document.body.style.cursor = 'col-resize'
          this.width = this.$el.clientWidth;
        }          

        if (this.resizeTop || this.resizeBottom)
        {
          document.body.style.cursor = 'row-resize'
          this.height = this.$el.clientHeight;
        }          
      
        this.offsetX = 0;
        this.offsetY = 0;
        this.mouseX = x;
        this.mouseY = y;
        this.resizingTop = this.resizeTop;
        this.resizingRight = this.resizeRight;
        this.resizingBottom = this.resizeBottom;
        this.resizingLeft = this.resizeLeft;           
      }
    },
    
    handleUp() {       
      if (this.isResizing) {
        
        document.body.style.cursor = '';

        if (this.resizingLeft || this.resizingRight) {
          this.resizingLeft = false;
          this.resizingRight = false;
          this.$emit('resized', this, { size: this.width });
        }

        if (this.resizingTop || this.resizingBottom) {
          this.resizingTop = false,        
          this.resizingBottom = false;
          this.$emit('resized', this, { size: this.height });
        }
      }
    },

    stripPx(pxString)
    {
      return parseInt(pxString, 10);
    },
  },
}
</script>

<style lang="scss" scoped>

@import "../../variables"; 

.grip {
  border-color: black;
  background-color: $panelColourSuperLight;
  cursor: inherit;
}

.circle {
  color: black;
}

.arrow {
  color: white;
}

.resize-arrow {
  z-index: 1;
}

.resizable > .resizable-top {
		display: block;
		position: absolute;
		z-index: 90;
		touch-action: none;
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		cursor: row-resize;
		height: 14px;
		top: -8px;		
		width: 100%;
}

.resizable > .resizable-right {
		display: block;
		position: absolute;
		z-index: 90;
		touch-action: none;
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		cursor: col-resize;
		width: 14px;
		right: -8px;
		top: 0;
		height: 100%;
}

.resizable > .resizable-bottom {
		display: block;
		position: absolute;
		z-index: 90;
		touch-action: none;
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		cursor: row-resize;
		height: 14px;
		bottom: -8px;		
		width: 100%;
}

.resizable > .resizable-left {
		display: block;
		position: absolute;
		z-index: 90;
		touch-action: none;
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		cursor: col-resize;
		width: 14px;
		left: -8px;        
		top: 0;
		height: 100%;
}

</style>