<template>
  <div 
  class="layerTreeItem"  
  >    
    <template v-if="passesFilter">            
      <template v-if="depth > -1">          
        <div class="d-flex align-items-center">            
          <template v-if="item.sublayers">  <!-- Group layers -->
            <div class="d-flex flex-column w-100">         
              <div class="d-flex treeLine min-width-0">
                <button :class="{'expandCollapseHover' : expandCollapseHover}" 
                @click="setExpanded(!isExpanded)" 
                @mouseover="expandCollapseHover = true" 
                @mouseleave="expandCollapseHover = false">
                  <font-awesome-icon class="caret" :icon="isExpanded ? 'caret-down' : 'caret-right'"/>
                </button>                                    
                <button :class="{'expandCollapseHover' : expandCollapseHover }" 
                class="itemNameButton"
                @click="setExpanded(!isExpanded)" 
                @mouseover="expandCollapseHover = true" 
                @mouseleave="expandCollapseHover = false">
                  <span>{{ item.title }}</span>              
                  <template v-if="$appGlobals.environment === 'development' && item.parent">
                      <span> ({{item.globalId}})</span>
                    </template>    
                </button>                       
                <div class="d-flex ml-auto rightAlignedChildren">        
                  <template v-if="enableReorder">              
                    <template v-if="canMoveTowardsBack(item) || canMoveTowardsFront(item)">
                      <button                      
                      :class="{notActive : !canMoveTowardsBack(item) }"
                      @click="moveItemTowardsBack(item)"                                        
                      >
                        <font-awesome-icon                                                                             
                        transform="grow-3"
                        icon="arrow-down"/>
                      </button>
                      <button
                      :class="{notActive : !canMoveTowardsFront(item) }"
                      @click="moveItemTowardsFront(item)"                    
                      >
                      <font-awesome-icon                                                                             
                      transform="grow-3"
                      icon="arrow-up"/>
                      </button>                      
                    </template>
                  </template>        
                  <template v-if="item.id === 'privateLayers'">                                   
                    <button                                         
                    v-b-popover.hover.top="'Import new private layer'" 
                    class="bg-transparent border-0 p-0 mr-2 text-center"                       
                    @click="$emit('importIntoLayer')">
                        <font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon>
                    </button>                              
                  </template>  
                  <template v-if="(enableReorder && (canMoveTowardsBack(item) || canMoveTowardsFront(item))) || item.id === 'privateLayers'">
                    <div class="seperator"/>
                  </template>                    
                  <button                     
                  :class="{notActive : !item.visible}"
                  class="bg-transparent border-0 p-0 text-center"                       
                  @click="layerVisibilityChange(item)">
                    <font-awesome-icon                                                     
                    transform="grow-3"
                    :icon="item.visible ? 'eye' : ['far', 'eye']"/>
                  </button>          
                </div>          
              </div>
            </div>
          </template>
          <template v-else> <!-- Sub layers -->               
            <div class="d-flex flex-column w-100">                                 
              <div class="d-flex treeLine min-width-0">
                <template v-if="item.rootLayer.id !== 'privateLayers'">
                  <button                 
                  class="favouriteButton flex-shrink-0 bg-transparent border-0 p-0 text-center" @click="favouriteToggle(item)">
                    <font-awesome-icon :icon="item.favourite ? 'star' : ['far', 'star']"/>
                  </button>
                </template>
                <button 
                :class="{notActive : (tooZoomedIn || tooZoomedOut) }" 
                class="itemNameButton"
                @click="layerVisibilityChange(item)"
                @mouseover="visibilityHover = true" 
                @mouseleave="visibilityHover = false">
                    <span>{{ item.title }}</span>
                    <template v-if="$appGlobals.environment === 'development'">
                      <span> ({{item.globalId}})</span>
                    </template>
                </button>                                       
                <template v-if="showLegendSymbology && isVisibleActive(item) && item.legend && item.legend.length === 1">
                  <div class="d-flex align-items-center mr-2"> 
                    <img :src="item.legend[0].imageDataUrl">     
                  </div>
                </template>      
                <div class="d-flex ml-auto rightAlignedChildren">                                                                                                                                 
                  <template v-if="hasLayerProperties(item)">
                    <button                                   
                    v-b-popover.hover.left="'Layer properties (metadata)'"              
                    :class="{notActive : !item.isSelected}"
                    class="nudgeLeft bg-transparent border-0 p-0 text-center"                       
                    @click="$emit('info', item)"
                    >
                      <font-awesome-icon                                                     
                      transform="grow-3"                      
                      :icon="item.isSelected ? 'info-circle' : ['far', 'info-circle']"
                      />
                    </button>                                                                     
                  </template>
                  <template v-if="enableReorder">
                    <template v-if="canMoveTowardsBack(item) || canMoveTowardsFront(item)">
                      <button                        
                        :class="{notActive : !canMoveTowardsBack(item) }"
                        @click="moveItemTowardsBack(item)"                      
                      >
                      <font-awesome-icon                                                                             
                      transform="grow-3"
                      icon="arrow-down"/>
                      </button>
                      <button                      
                        :class="{notActive : !canMoveTowardsFront(item) }"
                        @click="moveItemTowardsFront(item)"                    
                        >
                        <font-awesome-icon                                                                             
                        transform="grow-3"
                        icon="arrow-up"/>
                      </button>                                        
                    </template>
                  </template>                  
                  <template v-if="item.rootLayer.id === 'privateLayers'">
                    <button                                            
                      @click="$emit('editLayer', item)"
                      class="mr-2"
                      >
                      <font-awesome-icon                                                                             
                      transform="grow-2"
                      icon="pencil-alt"/>
                    </button>
                    <button                                            
                      @click="$emit('deleteLayer', item)"
                      >
                      <font-awesome-icon                                                                             
                      transform="grow-2"
                      icon="trash-alt"/>
                    </button>
                  </template>                                                        
                  <div class="seperator"/>                      
                  <button                   
                  :class="{notActive : !item.visible, 'visibilityHover' : visibilityHover}"
                  @mouseover="visibilityHover = true" 
                  @mouseleave="visibilityHover = false"
                  class="bg-transparent border-0 p-0 text-center"                       
                  @click="layerVisibilityChange(item)">
                      <font-awesome-icon                                                     
                      transform="grow-3"
                      :icon="item.visible ? 'eye' : ['far', 'eye']"/>
                  </button>                                                                     
                </div>                                          
              </div>
              <template v-if="showLegendSymbology && isVisibleActive(item) && item.legend && item.legend.length > 1">
                <div :style="childrenWrapperVerticalLine" class="childrenWrapper">                      
                  <template v-for="(legendItem, index) in item.legend">
                    <div :key="index" class="treeLine min-width-0 d-flex align-items-center">            
                      <span 
                      :class="{ 'notActive' : (tooZoomedIn || tooZoomedOut)}"
                      class="legendName"                             
                      >
                        {{legendItem.label}}
                      </span>                                              
                      <img class="ml-2" :src="legendItem.imageDataUrl">                                                 
                    </div>
                  </template>                      
                </div>
              </template>
            </div>
          </template>
        </div>                      
      </template>
      <template v-if="item.sublayers">           
        <div 
        v-if="isExpanded || depth === -1" 
        :style="childrenWrapperVerticalLine" 
        :class="{'d-flex': isExpanded}"
        class="childrenWrapper flex-column">            
          <template v-for="childLayer in orderedSubLayers">
            <LayerTreeItem        
            :ref="'layer_' + childLayer.id"                      
            :item="childLayer"
            :root="root"
            :depth="depth + 1"
            :key="childLayer.id"
            :filterString="filterString"
            :showVisibleOnly="showVisibleOnly"
            :showFavouritesOnly="showFavouritesOnly"            
            :showLegendSymbology="showLegendSymbology"
            :orderAlphabetically="orderAlphabetically"
            :enableReorder="enableReorder"
            @info="info"
            @layerVisibilityChange="layerVisibilityChange"
            @reorderLayerItem="$emit('reorderLayerItem', $event)"       
            @importIntoLayer="$emit('importIntoLayer', $event)"      
            @deleteLayer="$emit('deleteLayer', $event)"      
            @editLayer="$emit('editLayer', $event)"                 
            >                
            </LayerTreeItem>
          </template>                       
        </div>                  
      </template>
    </template>
    <template v-else-if="depth === -1">
      <div class="mt-2 ml-2" v-if="showFavouritesOnly">
        No favourites added.          
        Favourites can be added from the above "All" and "Visible" tabs.          
        Click on the * (Star) button beside a layer to mark it as a favourite.              
      </div>        
      <div class="mt-2 ml-2" v-if="showVisibleOnly">
        No visible layers.
      </div>        
    </template>     
  </div>
</template>

<script>

import Vue from 'vue';
import { default as FeatureMixin } from "./FeatureMixin.vue";

export default {         
    name: 'LayerTreeItem',      
    mixins: [ FeatureMixin ],    
    props: {                   
      root: {
        type: Object,
        required: true
      },
      item: {
        type: Object,
        required: true
      },
      depth: { 
        type: Number,
        required: true
      },
      filterString: { 
        type: String,
        required: false
      },
      showVisibleOnly: {
        type: Boolean,
      },
      showFavouritesOnly: {
        type: Boolean,
      },           
      showLegendSymbology: {
        type: Boolean,
        default: true
      },
      orderAlphabetically: {
        type: Boolean
      },
      enableReorder: {
        type: Boolean
      }
		},                 
    data() {
      return {
        expandCollapseHover: false,
        visibilityHover: false,      
        glyphWidth: 20,     
        refreshComputed: false,
        debounceTimer: null,
        debounceMs: 250,        
      }
    },           
    created(){
      this.mapView.watch("scale", (newValue, oldValue, propertyName) => {           

        if (this.debounceTimer !== null) {
          clearTimeout(this.debounceTimer);
        }        

        this.debounceTimer = setTimeout(() => {          
          this.debounceTimer = null;
          this.refreshComputed = !this.refreshComputed;
          this.$forceUpdate();
        }, this.debounceMs);        
      })
    },
    computed: {

      isExpanded() {
        if (this.showFavouritesOnly) {
          return this.item.expandedInFavsTree;
        } else if (this.showVisibleOnly) {
          return this.item.expandedInVisibleTree;
        } else {
          return this.item.expandedInAllTree;
        }
      },      

      subLayersAlphabetical() {         
        let privateLayer =  this.item.sublayers.find(subLayer => subLayer.id === "privateLayers");
        let sortedGroupLayers = this.item.sublayers.filter(subLayer => subLayer !== privateLayer && subLayer.sublayers).sort((a, b) => a.title.localeCompare(b.title));        

        if (privateLayer) {
          sortedGroupLayers = [privateLayer, ...sortedGroupLayers];
        }
        
        let sortedLayers = this.item.sublayers.filter(subLayer => !subLayer.sublayers).sort((a, b) => a.title.localeCompare(b.title));
        return sortedGroupLayers.concat(sortedLayers);
      },

      orderedSubLayers() {                  

        let subLayers = []

        if (this.orderAlphabetically) {           
          return this.subLayersAlphabetical;
        } else {
          return [...this.item.sublayers].reverse();
        }            
      },

      tooZoomedIn() {

        // Create dependency so that when the value is changed, computed function will re-eval
        this.refreshComputed; 
        return (this.item.maxScale > 0) ? this.mapView.scale < this.item.maxScale : false;
      },

      tooZoomedOut() {
        // Create dependency so that when the value is changed, computed function will re-eval
        this.refreshComputed; 
        return (this.item.minScale > 0) ? this.mapView.scale > this.item.minScale : false;
      }, 

      passesFilter() {
        return this.isShown(this.item, this.showVisibleOnly, this.showFavouritesOnly, this.filterString)
      },   

      childrenWrapperVerticalLine() {
          if (this.depth > -1)
          {
              return {                
                  borderLeftStyle: "solid",
                  marginLeft: (this.glyphWidth / 2) + "px",                    
                  paddingLeft:  (this.glyphWidth) / 2 + "px"
              }
          }            
      }
    },
    watch: {       
       filterString(value) {                                                                       
             if (this.filterString.length > 2 && this.item.sublayers && this.passesFilter) {
                this.setExpanded(true);
            }
        },        
        showVisibleOnly(value) {
            if (this.item.sublayers && this.passesFilter) {
                this.setExpanded(true);
            }
        },
        showFavouritesOnly(value) {
            if (this.item.sublayers && this.passesFilter) {
                this.setExpanded(true);
            }
        }
    },
    methods: {                         
      
      setExpanded(expanded) {
        if (this.showFavouritesOnly) {
          this.item.expandedInFavsTree = expanded;
        } else if (this.showVisibleOnly) {
          this.item.expandedInVisibleTree = expanded;
        } else {
          this.item.expandedInAllTree  = expanded;
        }
      },
      
      moveItemTowardsFront(item) {                    
        if (this.canMoveTowardsFront(item)) {
          let visibleSiblings = this.getShownSiblings(item);
          let index = visibleSiblings.indexOf(item);
          let nextShownItem = visibleSiblings[index + 1];
          this.$emit('reorderLayerItem', {item: item, sibling: nextShownItem});
        }
      },

      moveItemTowardsBack(item) {
        if (this.canMoveTowardsBack(item)) {
          let visibleSiblings = this.getShownSiblings(item);
          let index = visibleSiblings.indexOf(item);
          let previousShownItem = visibleSiblings[index - 1];
          this.$emit('reorderLayerItem', {item: item, sibling: previousShownItem});
        }
      },
    
      getShownSiblings(item) {          
        
        let parent = this.item.parent ? this.item.parent : this.root;
        
        return parent.sublayers.filter(subLayer => {
          return this.isShown(subLayer, this.showVisibleOnly, this.showFavouritesOnly, this.filterString);
        });
      },
    
      canMoveTowardsBack(item) {

        if (!item.parent) {
          let itemIndex =  this.root.sublayers.indexOf(item);
          if (itemIndex === this.root.sublayers.length - 1) {
            return false;
          }
        }
                  
        let visibleSiblings = this.getShownSiblings(item);
        return visibleSiblings.indexOf(this.item) > 0;
      },

      canMoveTowardsFront(item) {

        if (!item.parent) {
          let itemIndex =  this.root.sublayers.indexOf(item);
          if (itemIndex === this.root.sublayers.length - 1) {
            return false;
          }
        }
            
        let visibleSiblings = this.getShownSiblings(item);
        let index = visibleSiblings.indexOf(this.item);
        
        if (index < visibleSiblings.length - 1) {
          let nextShownItem = visibleSiblings[index + 1];          
          if (nextShownItem.id === "privateLayers") {
            return false;
          } else {
            return true;
          }
        }
      },

      isShown(layer, showVisibleOnly, showFavouritesOnly, filterString)
      {           
          if (layer.sublayers)
          {
              // group layer.

              if (layer.sublayers.some(function(sublayer) {
                  return this.isShown(sublayer, showVisibleOnly, showFavouritesOnly, filterString);                    
                  }, this))
              {                                       
                  return true;
              }

              if (filterString.length > 2)
              {
                  return false;
              }

              if (showVisibleOnly || showFavouritesOnly) 
              {
                  return false;
              }                

              return true;
          }

          // sublayer (not a group)

          if ((filterString.length > 2) && (layer.title.toLowerCase().indexOf(filterString.toLowerCase()) === -1 )) 
          {
              return false;
          }
          
          if (showVisibleOnly && !this.isVisibleActive(layer)) 
          {
              return false;
          }

          if (showFavouritesOnly && !layer.favourite && layer.rootLayer.id !== "privateLayers") 
          {
              return false;
          }  
          
          if (!layer.includeInLayerManager) {            
              return false;
          }

          return true;                                    
      },

      info(layer) {
          this.$emit('info', layer)
      },

      layerVisibilityChange(layer) {
          this.$emit('layerVisibilityChange', layer)
      },

      favouriteToggle(item) {
          item.favourite = !item.favourite;
      }
  }
}
</script>

<style lang="scss" scoped>

@import "./../variables";

$glyphWidth: 20px;
$itemHeight: 34px;

.layerTreeItem {

    color: $textColourLight;
    font-size: $fontSize;      

    .nudgeLeft {
      padding-right: 0.10rem !important;
    }
  
    .seperator {
      width: 0px;        
      height: 26px;
      border-width: 1px;    
      border-color: $controlSeperator;
      border-style: none none none solid;
    };

    .expandCollapseHover, .visibilityHover {
        color: $textColourHover !important;
    }    	

    button {
        padding: 0px;        
        background: inherit;        
        border:none;
        cursor: pointer;
        color: inherit;

        &:hover {
          color: $textColourHover;
        }    

        &.notActive {
			    color: $textColourDark;
        }
    }

    .treeLine {
        min-height: $itemHeight;                

        .rightAlignedChildren > *:not(:last-child) {
          margin-right: 8px;          
        }
    }

    .itemNameButton {
        overflow: hidden; 
        text-overflow: ellipsis;  
        text-align: left;
        margin-right: 6px;     
        margin-left: 6px;        
        white-space: nowrap;
    }

    .legendName {
      margin-left: 6px;
    }

    .caret, .favouriteButton
    {
        width: $glyphWidth;
        position: relative;
        top: 1px;        
    }    
    
    .itemIcon 
    {
        position: relative;
        top: 1px;
        width: $glyphWidth;                
    }   

    .childrenWrapper {        
        border-width: 1px;        
        border-color: $controlSeperator;
    }    
}

</style>