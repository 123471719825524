<script>

var layerManagerPersistance = {
  methods: {     
    getState(type) {
      if (type === "device") {
        return {
          activeTab: this.activeTab,          
        }
      } else if (type === "project") {
        return {
          filterString: this.filterString
        }
      }
    },

    async restoreState(type, fromVersion, state) {
      if (type === "device") {
        this.activeTab = state.activeTab;        
      } else if (type === "project") {
        this.filterString = state.filterString;      
      }      
    },
  }
};

export default layerManagerPersistance;

</script>