<template> 
  <div>        
    <b-modal id="CreateBookmarkDialog"
    ref="CreateBookmarkDialog"
    :ok-disabled="!allValid"    
    @shown="createBookmarkDialogShown" 
    @ok="okButtonClicked" 
    @hidden="createHidden"         
    centered>     
      <template slot="modal-title">      
        <div class="d-flex justify-content-center align-items-center">
          <font-awesome-icon  :icon="['fas', 'bookmark']" class="mr-2"/>{{dialogTitle}}
        </div> 
      </template>          
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column">
          <div class="d-flex my-2">        
            <span class="d-flex align-items-center" :style="{'width': '80px'}">Name:</span>
            <span><b-form-input ref="nameInput" v-model="name" @keyup.enter="enterButton"/></span>
          </div>
          <template v-if="!feature">
            <div class="d-flex my-2">        
              <h6 class="d-flex mt-1" :style="{'width': '80px'}">Coords:</h6>
              <div class="d-flex flex-column">              
                <span class="d-flex"><DropdownSelect class="flex-grow-1" v-model="coordMode" :items="coordModes"></DropdownSelect></span>
                <b-form-input class="mt-1" v-model="coordString" @keyup.enter="enterButton"/>
              </div>            
            </div>
          </template>
          <div class="d-flex my-2">        
            <span class="d-flex align-items-center" :style="{'width': '80px'}">Scale:</span>
            <span><DropdownSelect class="flex-grow-1" v-model="scale" :items="sortedScales"></DropdownSelect></span>
          </div>
        </div>
      </div>
    </b-modal>
    <ErrorDialog    
    ref="BookmarkAlreadyExistsDialog"
    :title="errorTitle" 
    :details="error" 
    @hidden="errorHidden"/>
  </div>     
</template>

<script>

import axios from 'axios';
import ErrorDialog from './shared/ErrorDialog.vue';
import DropdownSelect from "./shared/DropdownSelect.vue"; 
import { loadModules } from "esri-loader";
import { agsPointToCoordString, coordStringToAgsPoint, isValidCoordString } from "./SpatialUtils.js";
import moment from 'moment';

export default {	
  components: { ErrorDialog, DropdownSelect },   
  props: { 
    feature: {
      type: Object
    },  
    bookmarks: {
      type: Array,
      required: true
    },
    mapCoords: {
      type: Object,
      required: true,
    },    
    mapScale: {
      type: Number,
      required: true
    },
    thumbnailUrl : {
      type:String,
      required:true,
    },
    coordinateMode:{
      type:String,
      required:true
    },
    createBookmarkName: {
      type: String
    },    
    dialogTitle: {
      type: String,
      required: true
    },
    scales: {
      Type: Array
    }
  },
  data() {
    return {
      name: "",
      error: "",
      errorTitle: "Cannot pin bookmark",      
      scale: null,
      coordMode: null,
      coordString: "",         
      coordModes: [              
        { text: "Easting, Northing (x/y)", value: "xy" },                        
        { text: "Longitude, Latitude", value: "latlon" },
        { text: "Grid reference", value: "gridref" },
      ],   
      AgsPoint: null        
    }
  },   
  created: function() {  
    loadModules([ 
      "esri/geometry/Point",
    ]).then(([Point]) => {
      this.AgsPoint = Point;
    })
  }, 
  computed: {
    sortedScales() {      
      let sorted = this.scales.sort((a, b) => { return a - b});      
      return sorted.map(scale => { return { text: scale.toString(), value: scale}});
    },
    allValid() {      
      return (this.name.length > 0) &&
             (isValidCoordString(this.coordString, this.coordMode)) &&
             ((this.scale) && ((this.scale <= this.mapView.constraints.effectiveMinScale) && (this.scale >= this.mapView.constraints.effectiveMaxScale)));
    },   
  },
  methods: { 
    show()
    {
      this.$refs.CreateBookmarkDialog.show();
    },
    hide()
    {
      this.$refs.CreateBookmarkDialog.hide();
    },    
    createBookmarkDialogShown(e) {                        
      this.coordMode = this.coordinateMode === "xyNonPrecise" ? "xy" : this.coordinateMode;
      this.scale = this.mapScale;
      this.coordString = agsPointToCoordString(this.mapCoords, this.coordinateMode);
      this.name = this.createBookmarkName ? this.createBookmarkName : ""
      this.error = "";
      this.$refs.nameInput.focus()
    },
    handleOK() {
      let mapCoords = coordStringToAgsPoint(this.AgsPoint, this.coordString, this.mapView.spatialReference, this.coordMode);
      
      let nameExists = this.bookmarks.find(bookmark => { return bookmark.name.toLowerCase() === this.name.toLowerCase()});       
      let sameLocation = this.bookmarks.some(bookmark => bookmark.coords.equals(mapCoords));

      if (nameExists) {
        this.error = "bookmark " + '""' + this.name + '"' +  "already exists.";      
      } else  if (sameLocation) {
        this.error = "A bookmark already exists at this location.";
      } else {
        this.error = ""
      }     

      if (!this.error) {                       

        const imageHeight = 62;
        const imageWidth = 62;
        let screenMapCenter = this.mapView.toScreen(mapCoords);

        let topLeft = this.mapView.toMap({x: screenMapCenter.x - imageWidth, 
                                          y: screenMapCenter.y - imageHeight});

        let bottomRight = this.mapView.toMap({x: screenMapCenter.x + imageWidth, 
                                              y: screenMapCenter.y + imageHeight});

        var params = {
          bbox: topLeft.x + "," + topLeft.y + "," + bottomRight.x + "," + bottomRight.y,
          size: imageWidth + "," + imageHeight,
          mapScale: 32000,            
          f: "image"
        };

        var esc = encodeURIComponent;
        
        var query = Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');

        const imageSrc = this.thumbnailUrl + "/export?" + query;            

        this.bookmarks.push({
          whenCreated: new Date(),
          name: this.name, 
          coords: mapCoords, 
          feature: this.feature,
          scale: this.scale,          
          thumbnail: {
            srcUrl: imageSrc
          },
          visible: true
        })

        this.name = "";        
      }                  
    },   
    createHidden() {
      if (this.error){        
        this.$refs.BookmarkAlreadyExistsDialog.show();
      }
    },
    enterButton()
    {
      if (!this.allValid) {
        return;
      }
      
      this.handleOK();
      this.hide();
    },
    errorHidden() {      
      this.show();
    },    
    okButtonClicked(bvModalEvt){
      this.handleOK();     
    }    
  }  
} 

</script>

<style lang="scss" scoped>

</style>

<style lang="scss">

@import "././../variables";
 
#CreateBookmarkDialog .modal-dialog {
    max-width: 500px !important;
    margin: 1.75rem auto;
}

</style>