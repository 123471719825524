<template>
  <b-modal id="ErrorDialog"
  ref="ErrorDialog"
  @shown="shown" 
  @hidden="hidden" 
  @hide="hide"
  ok-only 
  centered>
    <template slot="modal-title">        
      <div class="d-flex align-items-center">
      <font-awesome-icon transform="grow-2" icon="exclamation-square" class="exclamationIcon mr-2"/>      
      {{title}}
      </div>
    </template>
    <div ref="details" class="d-flex justify-content-center align-items-center">
      {{detailsString}}
    </div>      
  </b-modal>
</template>

<script>

export default {	
  props: {     
    title: { 
      type: String,
      required: true
    },
    details: {}
  },
  computed: {
    detailsString() {

      if (!this.details) {
        return "";
      }

      if (typeof this.details === 'string' || this.details instanceof String) {
        return this.details
      } else {
        return this.details.message;
      }
    }
  },
  methods: {
    show() {
      this.$refs.ErrorDialog.show();
    },    
    hide() {      
      this.$refs.ErrorDialog.$refs.modal.removeEventListener("keyup", this.enterKeyHandler);  
    },
    shown(e) {      
      this.$refs.ErrorDialog.$refs.modal.addEventListener("keyup", this.enterKeyHandler);
    },
    enterKeyHandler(e) {
      // Enter key
      if (e.keyCode === 13) {          
        this.$refs.ErrorDialog.hide();
      }        
    },
    hidden() {      
      this.$emit('hidden');
    },    
  }  
} 

</script>

<style lang="scss" scoped>

</style>

<style lang="scss">

#ErrorDialog .modal-dialog {
    max-width: 90% !important;    
    width: 90% !important;
    
}

</style>