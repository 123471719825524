<template>
  <button        
    :style="buttonStyle"     
    :class="{contentsSelected: isSelected, inactive: !enabled}"
    class="d-flex align-items-center justify-content-center p-0 position-relative"
    @click="buttonClicked">
      <div 
      class="buttonSlice selected"
      :style="selectionStyle"
      v-show="isSelected"/>
      <template v-if="$slots.default">
        <slot></slot>
      </template>
      <template v-else-if="icon">
        <font-awesome-icon :transform="iconTransform" :class="{ selected: isSelected}" :icon="icon" ></font-awesome-icon>
      </template>      
  </button>        
</template>

<script>

export default {       
  props: {
    enabled: {
      default: true,
    },
    menuItems: {
      type: Object
    },
    subMenuName: {
      type: String,      
    },
    selected: {
      type: Boolean      
    },
    icon: {            
    },
    iconTransform: {            
      type: String
    },
    selectionSide: {
      type: String,
      default: "top"
    },
    height: {
      required: true,
      type: Number,      
    },
    width: {
      required: true,
      type: Number,      
    }
  }, 
  computed: {
    isSelected() {
       if (this.subMenuName) {
         return this.menuItems[this.subMenuName];
       } else {
         return this.selected;
       }
    },
    buttonStyle()
    {
      return {
        width: this.width + "px",
        height: this.height + "px"
      }
    },
    selectionStyle()
    {
      return {
        height: (this.selectionSide === "left" || this.selectionSide === "right") ? "100%" : "2px",
        width: (this.selectionSide === "left" || this.selectionSide === "right") ? "2px" : "100%",
        top: (this.selectionSide !== "bottom") ? "0px": undefined,
        right: (this.selectionSide === "right") ? "0px": undefined,
        bottom: (this.selectionSide === "bottom") ? "0px" : undefined,        
        left: (this.selectionSide !== "right") ? "0px" : undefined,        
      }
    },  
  },
  methods: {
    buttonClicked() {

      if (this.enabled) {
        if (this.subMenuName) {
          if (this.isSelected){
            this.$emit("closeMenuItem", this.subMenuName);
          } else {          
            this.$emit("openMenuItem", this.subMenuName);
          }
        } 
        
        this.$emit("clicked");
      }
    }    
  }
}

</script>

<style lang="scss" scoped>
@import "../../variables";

button
{  
  font-size: 16px;
  border:none;    
  color: inherit;
  background:$panelColour;

  &:hover {        
    color: $textColourHover
  }  

  &.contentsSelected 
  {
    svg {
      color: $pColourDark;
    }
  }

  &.inactive
  {
    svg {
      color: $pColourDarkest;
    }
  }

  .buttonSlice {  
    background-color: $pColourDark;
    position: absolute;  
  }

  .selected {  
    color: $pColourDark;
  }
}

</style>