<template>
 	<portal to="menus/bottom">		
    <portal-target name="menus/bottom/statusBar">
    </portal-target>
 	</portal>
</template>

<script>

export default {         
  props:
  {
    menuItems: {
      type: Object,
    }
  },  
}

</script>