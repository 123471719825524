<template>  
  <Slidable  
  :from="side"
  :slideTimeMs="slideTimeMs"
  @shown="slidableShown"            
  @hidden="slidableHidden"
  @sliding="sliding">    
    <div 
    id="menu" 
    :style="menuStyle" 
    class="position-fixed"
    :class="{ boxShadow: menu.openItem }"
    >      
      <MenuBar      
      :showResizeGrips="showResizeGrips"
      :side="side"
      :slideTimeMs="slideTimeMs"      
      :menu="menu"          
      :size="menuBarSize"		
      @resized="resized"
      @resizing="resizing"
      >      
      </MenuBar>    
    </div>        
  </Slidable>
</template>    

<script>

import MenuBar from "./MenuBar.vue"

export default {  
  name: "Menu",
  components: { MenuBar },
  props: { 
    showResizeGrips: {
			type: Boolean,			
		},   
    menu: {
      type: Object,      
      required: true
    },    
    side: {
      type: String,      
      required: true
    },
    menuBarSize : {
      type: Number,
      Required: true
    },    
    topOffset: {
			type: Number,  
			default: 0
    },
    rightOffset: {
			type: Number,      
			default: 0    
    },    
    bottomOffset: {
			type: Number, 
			default: 0     
    },
    leftOffset: {
			type: Number, 
			default: 0
    },     	
    slideTimeMs: {
			type: Number,
			default: 275
		}	
	}, 	
  computed:
  {       
    menuStyle() {
      
      if (this.side === "left") {
        return  {          
          left: this.leftOffset + "px",
          top: this.topOffset + "px",
          height: "calc(100dvh - " + this.topOffset + "px - " + this.bottomOffset + "px)",
        }                  
      }

      if (this.side === "top") {
        return  {          
          left: this.leftOffset + "px",
          top: this.topOffset + "px",
          width: "calc(100dvw - " + this.leftOffset + "px - " + this.rightOffset + "px)",
        }                  
      }

      if (this.side === "right") {
        return  {          
          right: this.rightOffset + "px",
          top: this.topOffset + "px",
          height: "calc(100dvh - " + this.topOffset + "px - " + this.bottomOffset + "px)",
        }                  
      }

      if (this.side === "bottom") {
        return  {          
          left:  this.leftOffset + "px",
          bottom: this.bottomOffset + "px",
          width: "calc(100dvw - " + this.leftOffset + "px - " + this.rightOffset + "px)",
        }                  
      }
    },     

    isVertical()
    {
      return (this.side === "left" || this.side === "right");      
    },

    isHorizontal()
    {
      return (this.side === "top" || this.side === "bottom");
    },
  },  

  methods: {
    
    resizing(menu, el) {			 
      
      if (this.isVertical) {
        this.menu.visibleSize = el.clientWidth;      
			} else {
				this.menu.visibleSize = el.clientHeight;
      }
            
      this.$emit("resizing", this.menu);      
    },

     resized(menu, el) {			 
      
      if (this.isVertical) {
        this.menu.visibleSize = el.clientWidth;      
			} else {
				this.menu.visibleSize = el.clientHeight;
      }
            
      this.$emit("resized", this.menu);      
    },
    
    slidableShown(el) {      
			
			if (this.isVertical) {
				this.menu.visibleSize = el.clientWidth;
			} else {
				this.menu.visibleSize = el.clientHeight;
      }            
       
      this.$emit("resized", this.menu);
    },

    slidableHidden(el) {      
      this.menu.visibleSize = 0;
      this.$emit("resized", this.menu);
    },

    sliding(progress) {      

      if (this.isVertical) {
  			this.menu.visibleSize = Math.abs(progress.el.clientWidth - progress.diffX);
      } else {
        this.menu.visibleSize = Math.abs(progress.el.clientHeight - progress.diffY);
      }
		  
      this.$emit("resizing", this.menu);
    },  
  }
}

</script>

<style lang="scss">
.boxShadow {
  box-shadow: 0 0 16px rgba(22, 22, 22, 0.6);
}
</style>