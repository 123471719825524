<template>  
  <div class="topRightToolbar d-flex">    
    <ToggleButton            
    v-b-popover.hover.bottom="'Navigate and select'" 
    class="button-border-top button-border-left button-border-bottom radius-bottom-left"
    :height="48"
    :width="48"
    :selected="activePointerTool === 'defaultTool'"    
    @clicked="$emit('activePointerToolChanged', 'defaultTool')">      
    <font-awesome-icon icon="mouse-pointer" transform="grow-1"/>
    </ToggleButton>       
    <div class="seperatorContainer button-border-top button-border-bottom d-flex align-items-center"><div class="seperator mr-1"></div></div>  
    <div class="d-flex flex-column">    
      <div class="d-flex">
        <ToggleButton        
        v-b-popover.hover.bottom="'Zoom to rectangle'"
        class="button-border-top button-border-right button-border-bottom mr-2"        
        :height="48"
        :width="48"
        :selected="activePointerTool === 'zoomTo'"    
        @clicked="$emit('activePointerToolChanged', 'zoomTo')">           
          <font-awesome-layers>
            <font-awesome-icon :icon="['fal', 'square-dashed']" transform="grow-6 left-6"/>          
            <font-awesome-icon icon="search" transform="right-4 down-6 grow-1"/>                      
          </font-awesome-layers>
        </ToggleButton>                          
      </div>     
    </div>  
    <div class="d-flex flex-column" @focusout="identifyFocusOut">    
      <div class="d-flex">
        <ToggleButton        
        v-b-popover.hover.bottom="multiSelectToolTip(identifyShape)"
        class="button-border-left button-border-top"
        :class="{ 'button-border-bottom': !identifyShapesOpen}"
        :height="identifyShapesOpen ? 46 : 48"
        :width="48"
        :selected="activePointerTool === 'identify'"    
        @clicked="$emit('activePointerToolChanged', 'identify'); identifyShapesOpen = false">
          <font-awesome-layers>
            <font-awesome-icon icon="mouse-pointer" transform="right-10 grow-1"/>
            <template v-if="identifyShape === 'rectangle'">
              <font-awesome-icon :icon="['far', 'square']" transform="shrink-6 left-12 up-6"/>
            </template>
            <template v-if="identifyShape === 'polyline'">
              <font-awesome-icon :icon="['fas', 'wave-sine']" transform="shrink-6 left-12 up-6"/>
            </template>
            <template v-if="identifyShape === 'circle'">
              <font-awesome-icon :icon="['far', 'circle']" transform="shrink-6 left-12 up-6"/>
            </template>
            <template v-if="identifyShape === 'polygon'">
              <font-awesome-icon :icon="['fal', 'draw-polygon']" transform="shrink-6 left-12 up-6"/>
            </template>
            <template v-if="identifyShape === 'point'">
              <font-awesome-icon :icon="['fas', 'circle']" transform="shrink-6 left-12 up-6"/>       
            </template>         
          </font-awesome-layers>  
        </ToggleButton>                    
        <ToggleButton                
        class="button-border-bottom button-border-top"
        :height="48"
        :width="12"            
        @clicked="identifyShapesOpen = !identifyShapesOpen">            
          <font-awesome-icon :icon="identifyShapesOpen ? 'caret-down' : 'caret-right'"/>
        </ToggleButton>                    
      </div>
      <template v-if="identifyShapesOpen">        
        <div class="d-flex flex-column selectionShapes">
          <template v-if="identifyShape !== 'rectangle'">
            <ToggleButton    
            v-b-popover.hover.right="multiSelectToolTip('rectangle')"     
            class="button-border-left button-border-right"
            :height="48"
            :width="48"        
            @clicked="identifyShapeChanged('rectangle')">          
              <font-awesome-layers>
                <font-awesome-icon icon="mouse-pointer" transform="right-10 grow-1"/>
                <font-awesome-icon :icon="['far', 'square']" transform="shrink-6 left-12 up-6"/>       
              </font-awesome-layers>  
            </ToggleButton>                
          </template>       
          <template v-if="identifyShape !== 'circle'">
            <ToggleButton        
            v-b-popover.hover.right="multiSelectToolTip('circle')"     
            class="button-border-left button-border-right"
            :height="48"
            :width="48"        
            @clicked="identifyShapeChanged('circle')">          
              <font-awesome-layers>
                <font-awesome-icon icon="mouse-pointer" transform="right-10 grow-1"/>
                <font-awesome-icon :icon="['far', 'circle']" transform="shrink-6 left-12 up-6"/>       
              </font-awesome-layers>  
            </ToggleButton>                
          </template>       
          <template v-if="identifyShape !== 'polygon'">
            <ToggleButton        
            v-b-popover.hover.right="multiSelectToolTip('polygon')"     
            class="button-border-left button-border-right"
            :height="48"
            :width="48"        
            @clicked="identifyShapeChanged('polygon')">          
              <font-awesome-layers>
                <font-awesome-icon icon="mouse-pointer" transform="right-10 grow-1"/>
                <font-awesome-icon :icon="['fal', 'draw-polygon']" transform="shrink-6 left-12 up-6"/>       
              </font-awesome-layers>  
            </ToggleButton>                
          </template>
          <template v-if="identifyShape !== 'polyline'">
            <ToggleButton        
            v-b-popover.hover.right="multiSelectToolTip('polyline')"     
            class="button-border-left button-border-right"
            :height="48"
            :width="48"        
            @clicked="identifyShapeChanged('polyline')">          
              <font-awesome-layers>
                <font-awesome-icon icon="mouse-pointer" transform="right-10 grow-1"/>
                <font-awesome-icon :icon="['fas', 'wave-sine']" transform="shrink-6 left-12 up-6"/>       
              </font-awesome-layers>  
            </ToggleButton>                
          </template>              
          <template v-if="identifyShape !== 'point'">
            <ToggleButton        
            v-b-popover.hover.right="multiSelectToolTip('point')"     
            class="button-border-left button-border-right"
            :height="48"
            :width="48"        
            @clicked="identifyShapeChanged('point')">          
              <font-awesome-layers>
                <font-awesome-icon icon="mouse-pointer" transform="right-10 grow-1"/>
                <font-awesome-icon :icon="['fas', 'circle']"  transform="shrink-6 left-12 up-6"/>       
              </font-awesome-layers>  
            </ToggleButton>                
          </template>              
        </div>        
      </template>      
    </div>  
    <div class="seperatorContainer button-border-top button-border-bottom d-flex align-items-center"><div class="seperator ml-2 mr-1"></div></div>  
    <div class="d-flex flex-column" @focusout="measureFocusOut">    
      <div class="d-flex">
        <ToggleButton        
       v-b-popover.hover.bottom="measureToolTip(measureShape)"
        class="button-border-top"
        :class="{ 'button-border-bottom': !measureShapesOpen}"
        :height="measureShapesOpen ? 46 : 48"
        :width="48"
        :selected="activePointerTool === 'measure'"    
        @clicked="$emit('activePointerToolChanged', 'measure'); measureShapesOpen = false">
          <font-awesome-layers>
            <font-awesome-icon :icon="['fas', 'ruler-vertical']" transform="right-10 grow-1"/>
            <template v-if="measureShape === 'rectangle'">
              <font-awesome-icon :icon="['far', 'square']" transform="shrink-6 left-12 up-6"/>
            </template>
            <template v-if="measureShape === 'polyline'">
              <font-awesome-icon :icon="['fas', 'wave-sine']" transform="shrink-6 left-12 up-6"/>
            </template>
            <template v-if="measureShape === 'circle'">
              <font-awesome-icon :icon="['far', 'circle']" transform="shrink-6 left-12 up-6"/>
            </template>
            <template v-if="measureShape === 'polygon'">
              <font-awesome-icon :icon="['fal', 'draw-polygon']" transform="shrink-6 left-12 up-6"/>
            </template>
            <template v-if="measureShape === 'point'">
              <font-awesome-icon :icon="['fas', 'circle']" transform="shrink-6 left-12 up-6"/>       
            </template>           
          </font-awesome-layers>  
        </ToggleButton>                    
        <ToggleButton               
        class="button-border-bottom button-border-top"
        :height="48"
        :width="12"            
        @clicked="measureShapesOpen = !measureShapesOpen">  
          <font-awesome-icon :icon="measureShapesOpen ? 'caret-down' : 'caret-right'"/>
        </ToggleButton>                    
      </div>
      <template v-if="measureShapesOpen"> 
        <div class="d-flex flex-column selectionShapes">
          <template v-if="measureShape !== 'polyline'">
            <ToggleButton   
            v-b-popover.hover.right="measureToolTip('polyline')" 
            class="button-border-left button-border-right"    
            :height="48"
            :width="48"        
            @clicked="measureShapeChanged('polyline')">          
              <font-awesome-layers>
                <font-awesome-icon :icon="['fas', 'ruler-vertical']" transform="right-10 grow-1"/>
                <font-awesome-icon :icon="['fas', 'wave-sine']" transform="shrink-6 left-12 up-6"/>       
              </font-awesome-layers>  
            </ToggleButton>                
          </template>       
          <template v-if="measureShape !== 'circle'">
            <ToggleButton        
            v-b-popover.hover.right="measureToolTip('circle')" 
            class="button-border-left button-border-right"
            :height="48"
            :width="48"        
            @clicked="measureShapeChanged('circle')">          
              <font-awesome-layers>
                <font-awesome-icon :icon="['fas', 'ruler-vertical']" transform="right-10 grow-1"/>
                <font-awesome-icon :icon="['far', 'circle']" transform="shrink-6 left-12 up-6"/>       
              </font-awesome-layers>  
            </ToggleButton>                
          </template>       
          <template v-if="measureShape !== 'polygon'">            
            <ToggleButton        
            v-b-popover.hover.right="measureToolTip('polygon')" 
            class="button-border-left button-border-right"
            :height="48"
            :width="48"        
            @clicked="measureShapeChanged('polygon')">          
              <font-awesome-layers>
                <font-awesome-icon :icon="['fas', 'ruler-vertical']" transform="right-10 grow-1"/>
                <font-awesome-icon :icon="['fal', 'draw-polygon']" transform="shrink-6 left-12 up-6"/>       
              </font-awesome-layers>  
            </ToggleButton>                
          </template>       
          <template v-if="measureShape !== 'rectangle'">
            <ToggleButton      
            v-b-popover.hover.right="measureToolTip('rectangle')" 
            class="button-border-left button-border-right"  
            :height="48"
            :width="48"        
            @clicked="measureShapeChanged('rectangle')">          
              <font-awesome-layers>
                <font-awesome-icon :icon="['fas', 'ruler-vertical']" transform="right-10 grow-1"/>
                <font-awesome-icon :icon="['far', 'square']" transform="shrink-6 left-12 up-6"/>       
              </font-awesome-layers>  
            </ToggleButton>                
          </template>                  
          <template v-if="measureShape !== 'point'">
            <ToggleButton        
            v-b-popover.hover.right="measureToolTip('point')" 
            class="button-border-left button-border-right"
            :height="48"
            :width="48"        
            @clicked="measureShapeChanged('point')">          
              <font-awesome-layers>
                <font-awesome-icon :icon="['fas', 'ruler-vertical']" transform="right-10 grow-1"/>
                <font-awesome-icon :icon="['fas', 'circle']" transform="shrink-6 left-12 up-6"/>       
              </font-awesome-layers>  
            </ToggleButton>                
          </template>              
        </div>
      </template>
    </div>  
    <div class="seperatorContainer button-border-top button-border-bottom d-flex align-items-center"><div class="seperator ml-2 mr-1"></div></div>  
    <ToggleButton        
   v-b-popover.hover.bottom="'Tag label'"
    class="button-border-top button-border-bottom"
    :height="48"
    :width="48"
    :selected="activePointerTool === 'lineAnnotate'"    
    @clicked="$emit('activePointerToolChanged', 'lineAnnotate')">      
      <font-awesome-layers>
        <font-awesome-icon :icon="['fas', 'wave-sine']" flip="horizontal" transform="shrink-4 up-4 left-13"/>
        <font-awesome-icon  :icon="['far', 'font']" transform="grow-2 right-2"/>            
      </font-awesome-layers>
    </ToggleButton>       
    <div class="seperatorContainer button-border-top button-border-bottom d-flex align-items-center"><div class="seperator"></div></div>  
    <ToggleButton      
   v-b-popover.hover.bottom="'Label'"  
    class="button-border-top button-border-bottom"
    :height="48"
    :width="48"
    :selected="activePointerTool === 'annotate'"    
    @clicked="$emit('activePointerToolChanged', 'annotate')">      
      <font-awesome-icon  :icon="['far', 'font']" transform="grow-2"/>            
    </ToggleButton>       
    <div class="seperatorContainer button-border-top button-border-bottom d-flex align-items-center"><div class="seperator"></div></div>  
    <div class="d-flex flex-column" @focusout="createFeatureFocusOut">    
      <div class="d-flex">
        <ToggleButton        
       v-b-popover.hover.bottom="createFeatureToolTip(createFeatureShape)"
        class="button-border-top"
        :class="{ 'button-border-bottom': !createShapesOpen}"
        :height="createShapesOpen ? 46 : 48"
        :width="48"
        :selected="activePointerTool === 'createFeature'"    
        @clicked="$emit('activePointerToolChanged', 'createFeature'); createShapesOpen = false">
          <font-awesome-layers>          
            <template v-if="createFeatureShape === 'rectangle'">
              <font-awesome-icon :icon="['far', 'square']" transform="grow-3"/>
            </template>
            <template v-if="createFeatureShape === 'polyline'">
              <font-awesome-icon :icon="['fas', 'wave-sine']" transform="grow-3"/>
            </template>
            <template v-if="createFeatureShape === 'circle'">
              <font-awesome-icon :icon="['far', 'circle']" transform="grow-3"/>
            </template>
            <template v-if="createFeatureShape === 'polygon'">
              <font-awesome-icon :icon="['fal', 'draw-polygon']" transform="right-2 grow-5"/>
            </template>
            <template v-if="createFeatureShape === 'point'">
              <font-awesome-icon :icon="['fas', 'circle']" transform="shrink-3"/>       
            </template>           
          </font-awesome-layers>  
        </ToggleButton>                    
        <ToggleButton        
        class="button-border-right button-border-top button-border-bottom pl-1 pr-3" 
        :height="48"
        :width="12"            
        @clicked="createShapesOpen = !createShapesOpen">
          <font-awesome-icon :icon="createShapesOpen ? 'caret-down' : 'caret-right'"/>
        </ToggleButton>                    
      </div>
      <template v-if="createShapesOpen"> 
        <div class="d-flex flex-column selectionShapes">
          <template v-if="createFeatureShape !== 'polyline'">
            <ToggleButton        
            class="button-border-left button-border-right"
            v-b-popover.hover.right="createFeatureToolTip('polyline')"
            :height="48"
            :width="48"        
            @clicked="createShapeChanged('polyline')">                      
              <font-awesome-icon :icon="['fas', 'wave-sine']" transform="grow-3"/>                  
            </ToggleButton>                
          </template>       
          <template v-if="createFeatureShape !== 'circle'">
            <ToggleButton        
            class="button-border-left button-border-right"
            v-b-popover.hover.right="createFeatureToolTip('circle')"
            :height="48"
            :width="48"        
            @clicked="createShapeChanged('circle')">                      
              <font-awesome-icon :icon="['far', 'circle']" transform="grow-3"/>            
            </ToggleButton>                
          </template>       
          <template v-if="createFeatureShape !== 'polygon'">
            <ToggleButton        
            class="button-border-left button-border-right"
            v-b-popover.hover.right="createFeatureToolTip('polygon')"
            :height="48"
            :width="48"        
            @clicked="createShapeChanged('polygon')">                      
              <font-awesome-icon :icon="['fal', 'draw-polygon']" transform="grow-5"/>            
            </ToggleButton>                
          </template>       
          <template v-if="createFeatureShape !== 'rectangle'">
            <ToggleButton        
            class="button-border-left button-border-right"
            v-b-popover.hover.right="createFeatureToolTip('rectangle')"
            :height="48"
            :width="48"        
            @clicked="createShapeChanged('rectangle')">                      
              <font-awesome-icon :icon="['far', 'square']" transform="grow-3"/>                  
            </ToggleButton>                
          </template>                  
          <template v-if="createFeatureShape !== 'point'">
            <ToggleButton        
            class="button-border-left button-border-right"
            v-b-popover.hover.right="createFeatureToolTip('point')"
            :height="48"
            :width="48"        
            @clicked="createShapeChanged('point')">                      
              <font-awesome-icon :icon="['fas', 'circle']" transform="shrink-3"/>                     
            </ToggleButton>                
          </template>              
        </div>
      </template>    
    </div>  
    <div class="d-flex flex-column ml-1" @focusout="deleteAllFocusOut">    
      <div class="d-flex">
        <ToggleButton                
        v-b-popover.hover.bottom="deleteAllToolTip(activeDeleteAll)"
        class="button-border-top"
        :class="{ 'button-border-bottom': !deleteAllOpen}"
        :height="deleteAllOpen ? 46 : 48"
        :width="48"        
        @clicked="$emit('deleteAll', activeDeleteAll); deleteAllOpen = false">
        <template v-if="activeDeleteAll === 'all'">
          <font-awesome-icon icon="trash-alt"/>                    
        </template>
        <template v-if="activeDeleteAll === 'inView'">
          <font-awesome-layers>                   
            <font-awesome-icon icon="trash-alt" transform="left-4"/> 
            <font-awesome-icon :icon="['far', 'eye']" transform="right-8 up-6 shrink-3"/>          
          </font-awesome-layers>    
        </template>
        </ToggleButton>                            
        <ToggleButton        
        class="button-border-top button-border-bottom" 
        :height="48"
        :width="12"            
        @clicked="deleteAllOpen = !deleteAllOpen">
          <font-awesome-icon :icon="deleteAllOpen ? 'caret-down' : 'caret-right'"/>
        </ToggleButton>            
      </div>     
      <template v-if="deleteAllOpen"> 
        <div class="d-flex flex-column selectionShapes">
          <template v-if="activeDeleteAll === 'inView'">
            <ToggleButton                    
            class="button-border-left button-border-right"
            v-b-popover.hover.right="deleteAllToolTip('all')"
            :height="48"
            :width="48"        
            @clicked="activeDeleteAllChanged('all')">                      
              <font-awesome-icon icon="trash-alt"/>          
            </ToggleButton>                
          </template>                 
          <template v-if="activeDeleteAll === 'all'">
            <ToggleButton                    
            class="button-border-left button-border-right"
            v-b-popover.hover.right="deleteAllToolTip('inView')"
            :height="48"
            :width="48"        
            @clicked="activeDeleteAllChanged('inView')">       
            <font-awesome-layers>                   
              <font-awesome-icon icon="trash-alt" transform="left-4"/> 
              <font-awesome-icon :icon="['far', 'eye']" transform="right-8 up-6 shrink-3"/>          
            </font-awesome-layers>    
            </ToggleButton>                
          </template>                 
        </div>
      </template>      
    </div>
    <div class="seperatorContainer button-border-top button-border-bottom d-flex align-items-center"><div class="seperator ml-2 mr-1"></div></div>  
     <div class="d-flex flex-column">    
      <div class="d-flex">
        <ToggleButton        
        :enabled="canUndoDelete"
        v-b-popover.hover.bottom="'Undo last delete'"
        class="button-border-top button-border-bottom button-border-right radius-bottom-right"        
        :height="48"
        :width="48"        
        @clicked="$emit('undoDelete')">
          <font-awesome-icon :icon="['fas', 'arrow-rotate-left']" transform="grow-6"/>          
        </ToggleButton>                         
      </div>      
    </div>
  </div>
</template>

<script>
import createdFeatureSelection from './CreatedFeatureSelection.vue';
import ToggleButton from "./menus/ToggleButton.vue"

export default {           
  components: { ToggleButton },  
  props: {
    canUndoDelete: {
      type: Boolean  
    },    
    activePointerTool: {
      type: String,      
    },
    activeDeleteAll: {
      type: String,      
    },
    identifyShape: {
      type: String,
    },
    measureShape: {
      type: String,
    },
    createFeatureShape: {
      type: String
    },    
  },
  data() {
    return {      
      identifyShapesOpen: false,
      measureShapesOpen: false,
      createShapesOpen: false,
      deleteAllOpen: false,      
    }    
  },    
  computed: {       
  },  
  methods: {
    
    activeDeleteAllChanged(type) {
      this.$emit("activeDeleteAllChanged", type);
      this.$emit("deleteAll", type); 
      this.deleteAllOpen = false
    },
    
    deleteAllToolTip(selectType) {
      return selectType === 'inView' ? 'Delete all drawn items (in view only)' : 
                                        'Delete all drawn items (entire map)';
    },         

    multiSelectToolTip(shape) {
      let toolTip = "Indentify features ";   
      
      if (shape === "rectangle") {
        toolTip += "in rectangle";
      }
      
      if (shape === "circle") {
        toolTip += "in circle";
      }

      if (shape === "polygon") {
        toolTip += "in polygon";
      }

      if (shape === "polyline") {
        toolTip += "on line";
      }

      if (shape === "point") {
        toolTip += "at point";
      }

      return toolTip;
    },

    measureToolTip(shape) {
      let tooltip = "Create ";

      if (shape == "point") {
        tooltip += "point"
      } 

      if (shape == "polyline") {
        tooltip += "line"
      } 

      if (shape === "rectangle") {
        tooltip += "rectangular"
      }

      if (shape === "circle") {
        tooltip += "circular"
      }

      if (shape === "polygon") {
        tooltip += "polygon"
      }

      return tooltip += " measurement"
    },

    createFeatureToolTip(shape) {
      let tooltip = "Create ";

      if (shape == "point") {
        tooltip += "point"
      } 

      if (shape == "polyline") {
        tooltip += "line"
      } 

      if (shape === "rectangle") {
        tooltip += "rectangular"
      }

      if (shape === "circle") {
        tooltip += "circular"
      }

      if (shape === "polygon") {
        tooltip += "polygon"
      }

      return tooltip += " feature"
    },    

    identifyFocusOut(focusEvent) {      
      if (!focusEvent.currentTarget.contains(focusEvent.relatedTarget)) {
        this.identifyShapesOpen = false;
      }      
    },
    identifyShapeChanged(shape) {
      this.$emit('identifyShapeChanged', shape);
      this.$emit('activePointerToolChanged', "identify");
      this.identifyShapesOpen = false;
    },    
    measureFocusOut(focusEvent) {      
      if (!focusEvent.currentTarget.contains(focusEvent.relatedTarget)) {
        this.measureShapesOpen = false;
      }      
    },
    measureShapeChanged(shape) {
      this.$emit('measureShapeChanged', shape);
      this.$emit('activePointerToolChanged', "measure");
      this.measureShapesOpen = false;
    },
    createFeatureFocusOut(focusEvent) {      
      if (!focusEvent.currentTarget.contains(focusEvent.relatedTarget)) {
        this.createShapesOpen = false;
      }      
    },
    createShapeChanged(shape) {
      this.$emit('createFeatureShapeChanged', shape);
      this.$emit('activePointerToolChanged', "createFeature");
      this.createShapesOpen = false;
    },

    deleteFocusOut(focusEvent) {      
      if (!focusEvent.currentTarget.contains(focusEvent.relatedTarget)) {
        this.deleteOpen = false;
      }      
    },    

    deleteAllFocusOut(focusEvent) {      
      if (!focusEvent.currentTarget.contains(focusEvent.relatedTarget)) {
        this.deleteAllOpen = false;
      }      
    },    
  }
}

</script>

<style lang="scss" scoped>
@import "../variables";

.topRightToolbar {
  color: $textColour;        
  height: 48px;

  button {    
    &.radius-top-left {      
      border-radius: 10px 0px 0px 0px;
    }

    &.radius-top-right {      
      border-radius: 0px 10px 0px 0px;
    }

    &.radius-bottom-right {      
      border-radius: 0px 0px 10px 0px;
    }

    &.radius-bottom-left {      
      border-radius: 0px 0px 0px 10px;
    }

    &.radius-bottom-left-and-right {      
      border-radius: 0px 0px 10px 10px;
    }    

    &.radius-top-left-and-right {      
      border-radius: 10px 10px 0px 0px;
    }
  }

  .selectionShapes {
    position: relative;
    top: -2px;

    > *:last-child {
      border-bottom-style: solid;      
    }
  }

  .button-border-left {
    border-left-style: solid;
  }

  .button-border-top {
    border-top-style: solid;
  }

  .button-border-right {
    border-right-style: solid;
  }

  .button-border-bottom {
    border-bottom-style: solid;
  }
  
  .seperatorContainer, button {    
    border-width: 2px;
    border-color: rgba(10, 10, 10, .2);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  } 

  .seperatorContainer {            
    height: 48px;
    background-color: $panelColour;

    .seperator {          
      height: 28px;
      width: 1px;
      background-color: $controlSeperator
    };
  };
}

</style>