<template>      
  <div 
  class="d-flex flex-column"
  :style="scaleBarContainerStyle">    
    <div class="d-flex align-items-center">
      <div class="bar"
      :style="metricBarStyle">
      </div>
      <span class="label ml-2">{{metricLabel}}</span>
    </div>      
    <div class="d-flex align-items-center">
      <div class="bar"
      :style="nonMetricBarStyle">
      </div>
      <span class="label ml-2">{{nonMetricLabel}}</span>
    </div>      
  </div>  
</template>

<script>
import { loadModules } from "esri-loader";

export default {  
  data() {
    return {
      esriLibrariesLoaded: false,
      scaleBarViewModel : null,      
      scaleMultiplier: 2,  
      barHeight: 4,
      left: 18,
      bottom: 64,
      nonMetricLength: 0,
      nonMetricUnit: 0,
      nonMetricLabel: "",
      metricLength: 0,
      metricUnit: 0,      
      metricLabel: "",      
      createScreenPoint: () => {}
    }
  },
  created() {
    loadModules([      
      "esri/widgets/ScaleBar/ScaleBarViewModel",      
      "esri/core/screenUtils"
    ]).then(([ScaleBarViewModel, 
              createScreenPoint]) => {

      this.scaleBarViewModel = new ScaleBarViewModel({ view:this.mapView });          
      this.createScreenPoint = createScreenPoint;          
      this.esriLibrariesLoaded = true;      
    });

    this.mapView.watch(["scale", "center"], (newValue, oldValue, propertyName) => {            
      this.calcScaleBar();
    })
  },
  mounted() {        
      this.calcScaleBar();    
  },
  computed: {  

    mapScale() {
      return this.mapView.scale;
    },   

    mapCenter() {
     return this.mapView.center;
    },   
    
    scaleBarContainerStyle() {
      return {        
        left: this.left + "px",
        bottom: this.bottom + "px",
      }
    },

    metricBarStyle() {      
      
      return  {        
        height: this.barHeight + "px",
        width: this.metricLength + "px",        
      }
    },

    nonMetricBarStyle() {      
      
      return  {        
        height: this.barHeight + "px",
        width: this.nonMetricLength + "px",        
      }
    }
  },
  watch: {
    esriLibrariesLoaded(value) {      
      this.calcScaleBar();
    },
  },
  methods: {

    getEsriViewRoot()
    {
      let parent = this.$el.parentElement;    
      while (!parent.classList.contains("esri-view-root")) {        
        parent = parent.parentElement;
      }
            
      return parent;
    },

    getBottomRelativeToMapViewContainer(bottom)
    {
      let mapViewBottom = bottom;
      let parent = this.$el.offsetParent;    
      while (!parent.classList.contains("esri-view-root")) {        
        mapViewBottom += parent.offsetBottom;
        parent = parent.offsetParent;
      }
            
      return mapViewBottom;
    },    

    getLeftRelativeToMapViewContainer(left)
    {
      let mapViewLeft = left;
      let parent = this.$el.offsetParent;    
      while (!parent.classList.contains("esri-view-root")) {        
        mapViewLeft += parent.offsetLeft;
        parent = parent.offsetParent;
      }
            
      return mapViewLeft;
    }, 
        
    bottomToY(bottom) {
      return this.getEsriViewRoot().clientHeight - bottom;    
    },

    rightToX(right) {
      return this.getEsriViewRoot().clientWidth - right;    
    },

    calcScaleBar() {

      // Check if visible (i.e. every parent is not set to display-hidden)
      // https://stackoverflow.com/questions/19669786/check-if-element-is-visible-in-dom
      if (this.esriLibrariesLoaded && this.$el.offsetParent) {
       
        const mapPoint = {
          x: this.getLeftRelativeToMapViewContainer(this.x),
          y: this.bottomToY(this.getBottomRelativeToMapViewContainer(this.y)),
          spatialReference: {
            wkid: this.mapView.spatialReference.wkid
          }
        };

        const screenPoint = this.mapView.toScreen(mapPoint);        
        this.scaleBarViewModel.scaleComputedFrom = screenPoint;        

        const baseLengthInPixels = 50;

        const nonMetricScale = this.scaleBarViewModel.getScaleBarProperties(
                              baseLengthInPixels,
                              "non-metric");      

        if (nonMetricScale) {                              
          this.nonMetricLength = (Math.round(nonMetricScale.length)) * this.scaleMultiplier;
          this.nonMetricUnit = nonMetricScale.unit;
          this.nonMetricLabel = nonMetricScale.value * this.scaleMultiplier + " " + this.nonMetricUnit;
        }

        const metricScale = this.scaleBarViewModel.getScaleBarProperties(
                              baseLengthInPixels,
                              "metric");                      

        if (metricScale) {
          this.metricLength = (Math.round(metricScale.length)) * this.scaleMultiplier;
          this.metricUnit = metricScale.unit;
          this.metricLabel = metricScale.value * this.scaleMultiplier + " " + this.metricUnit
        }
      }
    }
  }
}

</script>

<style lang="scss" scoped>

@import "./../variables";

.bar {
  background: repeating-linear-gradient(
  90deg,
  $pColour,
  $pColourDark 10px,
  $pColour 20px,
  $pColourDark 20px
);
//background:$pColour;
border:1px solid #000;
}

.label {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   font-size: $fontSizeSm;
   color:#ddd;
   white-space: nowrap; 
   text-shadow:
		-1px -1px 0 #000,
		1px -1px 0 #000,
		-1px 1px 0 #000,
		1px 1px 0 #000;
  //  -webkit-text-stroke-width: 1px;
  //  -webkit-text-stroke-color: #000;
}

</style>