<template>    
  <div class="EditableText d-flex" :class="fullWidth ? 'w-100' : ''">            
    <template v-if="canEdit && editing">
      <input             
      :class="fullWidth ? 'flex-grow-1' : ''"
      ref="editableInput"
      v-focusOnInsert v-selectOnInsert v-sizeToPlaceholder
      v-model="editedText"       
      @focusout="onFocusOut"
      @keydown.esc="escape"
      @paste="onPaste"
      :placeholder="editingPlaceHolder"
      @keydown.enter="enter"/>    
    </template>
    <template v-else-if="canEdit && !value && placeHolder">      
      <span :class="fullWidth ? 'flex-grow-1' : ''" class="min-width-0 text-ellipsis overflow-hidden font-italic" v-on:click="onClick">{{placeHolder}}</span>    
    </template>
    <template v-else>
      <template v-if="isUrl(value)">
        <a :class="fullWidth ? 'flex-grow-1' : ''" :href="value" target="_blank" class="min-width-0 text-ellipsis overflow-hidden" v-on:click="onClick">
          {{value}}
        </a>
      </template>
      <template v-else>        
        <span :class="fullWidth ? 'flex-grow-1' : ''" class="min-width-0 text-ellipsis overflow-hidden" v-on:click="onClick">{{value}}</span>    
      </template>
    </template>
  </div>
</template>

<script>

import { isValidCoordString } from '../SpatialUtils';

export default {
  name: 'EditableText',    
  props: {
    value: "",
    commitOnClickAway: {
      type: Boolean,         
    },
    canEdit: {
      type: Boolean,      
      default: true
    },
    placeHolder: {
      type: String
    },
    editingPlaceHolder: {
      type: String,      
    },
    fullWidth: {      
      type: Boolean,      
      default: false
    },
    fixValueFunc: {
      type: Function
    },
    validateValue: {
      type: Function
    },
    edit: null          
  },  
  watch: {
    edit() {      
      this.editing = this.edit;
    },
    editing() {
      if (this.editing) {
        this.editedText = this.value;        
      } 
    }
  },
  data() {
    return {  
      editing: false,
      editedText: "",
      entered: false,
      escaped: false,
      pasted: "",
    }
  },      
  methods: {       

    getFixedValue() {
      console.log("Fixing time")
       if(this.fixValueFunc){
        console.log("Giving fixing a go");
          return this.fixValueFunc(this.editedText)     
       }
       console.log("Fixed it")
       return this.editedText;
    },

    getValidatedValue(){
      let validValue;
      if(this.validateValue){
        console.log("In getValidatedValue: " + this.editedText)
        validValue = this.validateValue(this.editedText)
      }
      return validValue;
    },

    onFocusOut() {
  
      if (this.escaped) {
        this.escaped = false;
        return;
      } else if (this.entered) {
        this.entered = false;
        this.$emit("input", this.getFixedValue());
      } else if (this.commitOnClickAway)  {
        this.$emit("input", this.getFixedValue());
        this.setEditing(false);
      } else if(this.editedText!==undefined){
        if(this.getValidatedValue()){
          this.editedText = this.editedText + ', ';
          this.editedText = this.editedText.replace(/[ ,]+/g,', ');
        } else {
          this.setEditing(false)
        }
      } else {
        this.setEditing(false);

      }
    },

    onPaste(event){
      let pasted = event.clipboardData.getData('text').trim();
      if(pasted!==undefined&&(this.editedText==undefined||this.editedText==="")){
        console.log("It's defined")
        this.editedText = pasted;
        event.preventDefault();
        if(this.getValidatedValue()){
          console.log("It's valid")
          this.editedText = this.editedText + ', ';
          console.log(this.editedText)
          this.editedText = this.editedText.replace(/[ ,]+/g,', ');
        } else {
          console.log("Not validX: " + this.editedText)
          this.$emit("input", this.getFixedValue());
          this.setEditing(false);
        }
      } else if (pasted!==undefined&&(this.editedText!==undefined||this.editedText!=="")){
        //event.preventDefault();
        let selectedText = window.getSelection().toString();
        if(selectedText.length==0){
          console.log("Nothing to replace")
          this.editedText = this.editedText + pasted;
          this.$emit("input",this.getFixedValue());
          this.setEditing(false);
        } else if (this.editedText.includes(",")){
          let currentCoords = this.editedText.split(",");
          let currentX = currentCoords[0].trim();
          let currentY = currentCoords[1].trim();
          console.log("Selected text: " +  selectedText);
          console.log("Current Y: " + currentY);
          if(selectedText===currentX){
            console.log("Replacing X");
            this.editedText = pasted + ", " + currentY;
            this.$emit("input", this.getFixedValue());
            this.setEditing(false);
          } else if (selectedText===currentY){
            console.log("Replacing Y")
            this.editedText = currentX + ", " + pasted;
            this.$emit("input", this.getFixedValue());
            this.setEditing(false);
          } else {
            console.log("Replacing all")
            this.editedText = pasted;
            event.preventDefault();
            if(this.getValidatedValue()){
              console.log("It's valid")
              this.editedText = this.editedText + ', ';
              console.log(this.editedText)
              this.editedText = this.editedText.replace(/[ ,]+/g,', ');
            } else {
              console.log("Not validX: " + this.editedText)
              this.$emit("input", this.getFixedValue());
              this.setEditing(false);
            }
          }
        } else {
          console.log("Am I in use?")
        }
        console.log("Hey I'm pasting here")
      } else {
        console.log("Something's wrong")
        this.setEditing(false);
      }
    },

    escape() {   
      this.escaped = true;
      this.setEditing(false);
    },

    enter() {   
      this.entered = true;      
      this.setEditing(false);
    },

    onClick() {      
      if (!this.canEdit) {
        return;
      }

      this.setEditing(true);
    },

    setEditing(edit) {            
      if (this.edit !== undefined) {
        this.$emit("edit", edit);                                    
      } 
      else 
      {        
        this.editing = edit;
      }              
    }    
  },   
}    
</script>

<style lang="scss" scoped>
@import "../../variables";

</style>