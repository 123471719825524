<template>
  <div>
    <div id="container">
      <div id="draggable">
        <div id="rotationRing"></div>
        <div id="centre" @click="$emit('setViewRotation',0)"/>        
      </div>
    </div>
  </div>
</template>

<script>

import Propeller from "./shared/Propeller.js";

export default {
  data() {
    return {  
      compassDial:null,
      compassDragActive:false
    }
  },
  created(){    
    this.mapView.watch("extent", () => {
        if (!this.compassDragActive)
          this.compassDial.angle = this.mapView.rotation;
    })
  },
  mounted(){

    this.compassDial = new Propeller('#rotationRing', {
      inertia: 0,
      onDragStart: ()=> {        
        this.compassDragActive=true;
      },
      onDragStop: ()=> {        
        this.compassDragActive=false;
      },
      onRotate: ()=> {        
        if(this.compassDragActive)
          this.$emit('setViewRotation', Math.round(this.compassDial.lastAppliedAngle));
      } 
    });        
  },  
}
</script>

<style lang="scss">

@import "././../variables";

#container {
  /*position: absolute;*/
  // right: 10px;
  // top:30px;
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
}
#draggable {
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 100px;
  box-shadow: 0 0 0 3px rgba(10, 10, 10, 0.2);
}
#centre {
  width: 45%;
  height: 45%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto auto;
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(circle, $panelColour, lighten($panelColour,8%));
  border: 2px solid rgba(200, 200, 200, 0.1);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.8);
  //background: #f00;
}
#rotationRing {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  color:$textColour;
  background: linear-gradient(0deg, $panelColour, lighten($panelColour,8%), $panelColour);
  //background:#f0f;
}
#rotationRing:hover{
  color:$textColourHover;
}
#rotationRing::before {
  content: 'N';//url(/assets/location-arrow.svg);
  font-size:0.7em;
  position: absolute;
  left: -0.5rem;
  right: 0;
  margin: 3% auto;
  height: 20%;
  width: 1.4%;
  //border-radius: 25%;
 
  // background: $pColour;
  //box-shadow: 0 0 2px 0 $pColour;
}



.mapCompassContainer{
    position: absolute;
    top: 40px;
    right: 30px;
    height:50px;
    /*width:50px; */
    opacity: 0.8;
}
.mapCompassContainer:hover{
  opacity: 1;
}
.mapCompassNeedle{
  position:absolute;
  top: 0;
  right: 0;
}

.compassButton{
  width:20px; 
  height: 20px; 
  border-radius: 50%;
  background: $panelColour; 
  position: absolute;
  cursor: pointer;
  opacity: 0;
  color: $textColour;
  /*font-size:70%;*/
}


.mapCompassContainer:hover > .northButton {
  transform: translateY(-18px);
  opacity: 0.9;
}
.mapCompassContainer:hover > .eastButton {
  transform: translateX(18px);
  opacity: 0.9;
}
.mapCompassContainer:hover > .westButton {
  transform: translateX(-18px);
  opacity: 0.9;
}

.northButton{
  left: 15px;
  top:0px;
  transform: translateY(0px);
  transition: all 0.3s;
}
.eastButton{
  right: 0px;
  top:15px;
  transform: translateX(0px);
  transition: all 0.3s;
}
.westButton{
  left: 0px;
  top:15px;
  transform: translateX(0px);
  transition: all 0.3s;
}
</style>
