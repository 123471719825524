<template>        
  <div class="layerProperties h-100">         
    <template v-if="layer">
      <portal to="propertiesMenuBar">
        <div class="d-flex align-items-center ml-2 mr-1">                  
          <button          
          v-b-popover.hover.top="layer.favourite ? 'Remove from favourite layers' : 'Add to favourite layers'"          
          class="d-flex align-items-center mr-1"                        
          @click="layer.favourite = !layer.favourite"
          >
          <font-awesome-icon :icon="layer.favourite ? 'star' : ['far', 'star']"/>
          </button>     
          <button          
          v-b-popover.hover.top="layer.visible ? 'Hide layer' : 'Show layer on map'"          
          class="d-flex align-items-center mr-1"                        
          @click="$emit('layerVisibilityToggle', layer)"
          >
          <font-awesome-icon transform='grow-3' :icon="layer.visible ? 'eye' : ['far', 'eye']"/>
          </button>          
        </div>                  
      </portal>      
      <div class="h-100 d-flex flex-column mt-1">                                   
        <div class="propertyGrid best-fit mr-2">          
          <div class="px-2 label">Path</div>                                      
          <div class="d-flex min-width-0 ml-2">
            <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
            :value="getLayerPath(layer)"            
            >
            </PasteableBoxes>              
          </div>
          <div class="px-2 label">Source</div>                                      
          <div class="d-flex min-width-0 ml-2">
            <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
            :value="layer.url"            
            >
            </PasteableBoxes>              
          </div>
        </div>                     
        <template v-if="layer.meta">
          <span class="heading align-self-stretch d-flex justify-content-center my-2 p-1">Metadata</span>
          <div class="metadata-content">
            <div class="propertyGrid centered mr-2">          
              <div class="px-2 label">Last published</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="getDate(layer.meta.lastPublished)"            
                >
                </PasteableBoxes>              
              </div>            
            </div>
            <span class="heading align-self-stretch d-flex justify-content-center my-2 p-1">Description</span>
            <div class="propertyGrid best-fit mr-2">          
              <div class="px-2 label">Title</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="layer.meta.title"            
                >
                </PasteableBoxes>              
              </div>            
              <div class="px-2 label">Alternate title</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="layer.meta.alternateTitle"            
                >
                </PasteableBoxes>              
              </div>            
              <div class="px-2 label">Abstract</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="layer.meta.abstract"            
                >
                </PasteableBoxes>              
              </div>                        
              <div class="px-2 label">Responsible organisation</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="layer.meta.responsibleOrganisation"            
                >
                </PasteableBoxes>              
              </div>            
              <div class="px-2 label">Topic category</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="layer.meta.topicCategory"            
                >
                </PasteableBoxes>              
              </div>            
              <div class="px-2 label">Lineage</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="layer.meta.lineage"            
                >
                </PasteableBoxes>              
              </div>                        
              <div class="px-2 label">Key words</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="layer.meta.keyWords"            
                >
                </PasteableBoxes>              
              </div>            
              <div class="px-2 label">Dataset language</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="layer.meta.datasetLanguage"            
                >
                </PasteableBoxes>              
              </div>            
              <div class="px-2 label">Additional information source</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="layer.meta.additionalInformationSource"            
                >
                </PasteableBoxes>              
              </div>            
              <div class="px-2 label">Feature class</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="layer.meta.featureClass"            
                >
                </PasteableBoxes>              
              </div>                                    
            </div>
            <span class="heading align-self-stretch d-flex justify-content-center my-2 p-1">Currency</span>
            <div class="propertyGrid best-fit mr-2">                   
              <div class="px-2 label">Publish frequency</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="layer.meta.updateFrequency"            
                >
                </PasteableBoxes>              
              </div>                        
              <div class="px-2 label">Temporal extent begin</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="getDate(layer.meta.temporalExtentBegin)"            
                >
                </PasteableBoxes>              
              </div>                        
              <div class="px-2 label">Temporal extent end</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="getDate(layer.meta.temporalExtentEnd)"            
                >
                </PasteableBoxes>              
              </div>                        
            </div>            
            <span class="heading align-self-stretch d-flex justify-content-center my-2 p-1">Governance - Owner</span>
            <div class="propertyGrid best-fit mr-2">     
              <div class="px-2 label">Position name</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="layer.meta.ownerContact.positionName"            
                >
                </PasteableBoxes>              
              </div>                                    
              <div class="px-2 label">Email</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <a class="min-width-0 flex-grow-1" :href="'mailto:' + layer.meta.ownerContact.email">{{layer.meta.ownerContact.email}}</a>                                                          
              </div>                       
              <div class="px-2 label">Telephone</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <a class="min-width-0 flex-grow-1" :href="'tel:' + layer.meta.ownerContact.telephone">{{layer.meta.ownerContact.telephone}}</a>                                                      
              </div>                           
              <div class="px-2 label">Address</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="getAddressString(layer.meta.ownerContact)"            
                >
                </PasteableBoxes>                            
              </div>                                    
            </div>
            <span class="heading align-self-stretch d-flex justify-content-center my-2 p-1">Governance - Point of contact</span>
            <div class="propertyGrid best-fit mr-2">     
              <div class="px-2 label">Position name</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="layer.meta.contact.positionName"            
                >
                </PasteableBoxes>              
              </div>                                    
              <div class="px-2 label">Email</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <a class="min-width-0 flex-grow-1" :href="'mailto:' + layer.meta.contact.email">{{layer.meta.contact.email}}</a>                                                          
              </div>                       
              <div class="px-2 label">Telephone</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <a class="min-width-0 flex-grow-1" :href="'tel:' + layer.meta.contact.telephone">{{layer.meta.contact.telephone}}</a>                                                      
              </div>                           
              <div class="px-2 label">Address</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="getAddressString(layer.meta.contact)"            
                >
                </PasteableBoxes>                            
              </div>                                    
            </div>            
            <span class="heading align-self-stretch d-flex justify-content-center my-2 p-1">Constraints</span>
            <div class="propertyGrid best-fit mr-2">     
              <div class="px-2 label">Usage constraints</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="layer.meta.usageConstraints ?? 'None'"            
                >
                </PasteableBoxes>              
              </div>                                    
              <div class="px-2 label">Limitations on public access</div>                                      
              <div class="d-flex min-width-0 ml-2">
                <PasteableBoxes fullWidth class="min-width-0 flex-grow-1" :canEdit="false"                                         
                :value="layer.meta.lmitationsOnPublicAccess ?? 'None'"            
                >
                </PasteableBoxes>              
              </div>                              
            </div>      
          </div>
        </template>
      </div>                    
    </template>
  </div>  
</template>           

<script>

import PasteableBoxes from './shared/PasteableBoxes.vue';
import Moment from 'moment';

export default {      
  components: { PasteableBoxes },   
  props: {                
    isLoadingMeta: {
      type: Boolean
    },
    layer: {
      type: Object,      
    },         
    menuItems: {
			type: Object,
		},   		
  },   
  data() {
    return {                                                    
    }      
  }, 
  methods:
  {
    getAddressString(contact) {
      return [contact.addressLine1, contact.addressLine2, contact.city, contact.county, contact.country, contact.postcode]
             .filter(x => x)
             .join(", ");
    },

    getDate(date) {
      if (date) return Moment(date).format("DD/MM/YYYY");
    }
  }      
}    

</script>

<style lang="scss" scoped>


@import "../variables"; 


.layerProperties {
  background-color: $panelContent;
  font-size: $fontSize;      

  .heading {      
    background-color: $panelColourLight;
    border-radius: 1px;
  }

  .metadata-content {
    overflow-x: auto;
  }

  .propertyGrid {
    display: grid;    
    grid-row-gap: 8px;      

    &.best-fit {
      grid-template-columns: min-content auto;
    }

    &.centered {
      grid-template-columns: auto auto;
    }

    .label {      
      display: flex;
      align-items: center;
      justify-self: end;
      white-space: nowrap;
      border-right: 1px solid $controlSeperator;
      border-right: 1px solid $controlSeperator;      
    }         
  }
}

button.inactive {
  svg {
    color: $pColourDarkest;
  }
}

</style>