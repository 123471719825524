<template>
  <table class="tableWithResizableColumns" ref="table">
    <slot></slot>  
  </table>
</template>

<script>
export default {    
  data() {
    return {    
      mutationObserver: null,
      table: null,
      thead: null,
      ths: null,
      clickedTh: null,
      startX: null,
      startWidth: null,
      columnResized: false
    }
  }, 
  mounted() {            	    
    this.table = this.$refs["table"];
    this.thead = this.table.querySelector('thead');
    this.ths = Array.from(this.thead.querySelectorAll('tr:first-of-type th' ));

    this.ths.forEach(th => {
      th.addEventListener('mousedown', this.mouseDown, true);      
    })

    document.documentElement.addEventListener('mousemove', this.mouseMove, true);
    document.documentElement.addEventListener('mouseup', this.mouseUp, true);          

    this.mutationObserver = new MutationObserver(this.mutated);
    this.mutationObserver.observe(this.table, { childList: true,  subtree: true });
    
  },
  beforeDestroy() {          
     this.ths.forEach(th => {
      th.removeEventListener('mousedown', this.mouseDown, true);
    })

    document.documentElement.removeEventListener('mousemove', this.mouseMove, true);
    document.documentElement.removeEventListener('mouseup', this.mouseUp, true);    

    this.mutationObserver.disconnect();
  },      

  methods: {
    mutated(mutations) {
      mutations.forEach(mutation => {

        mutation.removedNodes.forEach(removedNode => {
          if (removedNode.nodeName === "TH") {
            let index = this.ths.indexOf(removedNode);
            if (index !== 1)
            {
              removedNode.removeEventListener('mousedown', this.mouseDown, true);
                this.ths.splice(index, 1);
            }
          }
        })

        mutation.addedNodes.forEach(addedNode => {
          if (addedNode.nodeName === "TH") {            
              addedNode.addEventListener('mousedown', this.mouseDown, true);
              this.ths.push(addedNode);
            }
          })
        })      
    },
    mouseDown(e) {            
      let clickedTh = e.currentTarget;      
      if (!clickedTh.classList.contains("noResize")) {
        this.clickedTh = clickedTh;
        this.startX = e.pageX,
        this.startWidth = this.clickedTh.clientWidth;
        this.table.classList.add("resizingColumns");
      }      
    },

    mouseMove(e) {
      
      if(this.clickedTh)
      {
        let newWidth = (this.startWidth + (e.pageX - this.startX));        

        if (newWidth !== this.clickedTh.clientWidth) {                            
          this.clickedTh.style.width = newWidth + "px";             
          this.columnResized = true;
          
          if (newWidth > this.clickedTh.clientWidth) {
            let extraTableWidth = newWidth - this.clickedTh.clientWidth;
            this.table.style.width = this.table.clientWidth + extraTableWidth + "px";            
          } 
        } 
      }
    },
    
    mouseUp(e) {            
      if(this.clickedTh)
      {
        this.table.classList.remove("resizingColumns");        

        if (this.columnResized) {
          this.columnResized = false;   
        }        
        else  
        {
          this.$emit("columnHeaderClicked", this.clickedTh);
        }

        this.clickedTh = null;             
      }      
    }
  }
}
</script>

<style lang="scss" scoped>

.tableWithResizableColumns {  

	&.resizingColumns {
		cursor: col-resize;
		user-select: none;
	}
	
	th {
		
		// Show resize curson when hovering over column borders
		&:not(.noResize)::before {
			cursor: col-resize;
		  user-select: none;
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			width: 1em;
		}
	}

	// Add `th` to the selector below to allow shrinking a column all the way
	td {
		max-width: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

</style>