
import axios from 'axios';
//import { toTitleCase } from "./../../shared/StringUtils.js";

class LocatorHub{
    constructor(endpoint, locatorId, target){
			this.endpoint = endpoint;                
			this.matchCache =[];
			this.items=[];			
    }   
    titleCaseAddress(s) {
			var address = s.split(',');
			for (var i = 0; i < address.length - 1; i++) {
				address[i] = address[i].replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
			}
			return address.join();
    }
    CacheLength(){
        return this.matchCache.length
    }
    Back(){ //not working properly yet.
			return new Promise((resolve, reject) =>{
				if (this.matchCache.length <= 1) {
						this.matchCache = [];
				}
				else {
					this.matchCache.splice(this.matchCache.length - 1, 1);
					var data = this.matchCache[this.matchCache.length - 1];
					let items = data.PickListItems.map(item=>({
						description: this.titleCaseAddress(item.Description),
						hasChildren: item.HasChildren,
						id: item.RecordId,
						cacheid:data.CacheIdentifier
					}));
					return ({
							items:items
					});
				}
			});
    } 
    async GetColumns(searchText) {			
			let response = await this.Match( "","", searchText);
			if (response.items.length > 0) {
					let item = response.items[0];
					let itemDetails = await this.Match(item.id, item.cacheid, searchText);
					return(itemDetails.record.columns);                			
			} else {
				return [];
			}
    }
    async Match(id, cacheid, searchText){ 
			let result = await axios.get(`${this.endpoint}?Query=${searchText}&CacheID=${cacheid}&PickedItem=${id}&Fuzzy=false&format=json`);

   	 	if(result && result.data) {
				let data = result.data;
				
        switch (data.TypeOfResult) {
            
					case 0: //PickList
						this.matchCache[this.matchCache.length] = data;
						let items = data.PickListItems.map(item=>({
							id: item.RecordId,
							description: this.titleCaseAddress(item.Description),
							hasChildren: item.HasChildren,
							cacheid:data.CacheIdentifier
						}));
						return ({
							resultCode:data.TypeOfResult,
							items:items
						});
						break;
					case 1:
						//NoMatchTooVague						
						return({
								userMessage: "No results, too vague.",
								resultCode:data.TypeOfResult,                  
								items:[]
						});
						break;
					case 2:
						//NoMatchNoResult
						return({
								userMessage:"No results.",
								resultCode:data.TypeOfResult,
								items:[]
						});
						break;            
					case 4: //SingleMatch
						if(data.MatchedRecord)
						{
							const xy = data.MatchedRecord.R[1].split(",");                                
							data.MatchedRecord.R[2] = data.MatchedRecord.R[2].replace(/\|LOCATOR_SEPARATOR\|/g,", ");
							data.MatchedRecord.R[2] = this.titleCaseAddress(data.MatchedRecord.R[2]);
							return({
								id: id,
								userMessage:"",
								resultCode:data.TypeOfResult,
								items:[{
										description: data.MatchedRecord.R[2]
								}],
								record: {
										locatorPointX:xy[0],
										locatorPointY:xy[1],
										columns: data.MatchedRecord.Columns,
										description: data.MatchedRecord.R[2],                                            
										fullshizzle: data.MatchedRecord.R
								} 
							});
						}
						break;
					}
        }   
    }
}
export { LocatorHub };