<template>
 	<portal to="menus/top">
		<div class="topBar position-absolute h-100 w-100">
			<b-navbar class="h-100 header p-0" type="light">
				<b-button class="headerIconButton" @click="$emit('projectButtonClicked')">
          <img height="24" width="24" v-if="$appGlobals.environment === 'development'" src="@/assets/icon24x24_dev.png"/>  
          <img height="24" width="24" v-if="$appGlobals.environment === 'staging'" src="@/assets/icon24x24_staging.png"/>                              
          <img height="24" width="24" v-if="$appGlobals.environment === 'production'" src="@/assets/icon24x24.png"/>        
        </b-button>  
          <img class="mt-1" v-if="$appGlobals.environment === 'development'" src="@/assets/mb_env_development.png"/>    
          <img class="mt-1" v-if="$appGlobals.environment === 'staging'" src="@/assets/mb_env_staging.png"/>   				
					<b-navbar-nav class="ml-auto d-flex align-items-center">	            	            
            <template v-if="$appGlobals.environment === 'development' || $appGlobals.environment === 'staging'">
              <b-form-checkbox                                        
              class="ml-3"          
              :checked="saveState"
              @change="$emit('saveStateEnabled', $event)"
              />              
              Save state                            
              <b-form-checkbox                                      
              class="ml-2"        
              :checked="restoreState"
              @change="$emit('restoreStateEnabled', $event)"
              />                                    
              Restore state          
              <template v-if="restoreState">
                <b-form-checkbox                                      
                class="ml-2"        
                :checked="fromLocal"
                @change="$emit('restoreFromLocalOnLoad', $event)"
                />                                    
                From local
              </template>
            </template>                                           
            <ToggleButton  v-b-popover.hover.bottom="'Features in identify area'" :menuItems="menuItems" :height="menuSize" :width="menuSize" selectionSide="top" subMenuName="results" :icon="['fal', 'table']" @openMenuItem="$emit('openMenuItem', $event)" @closeMenuItem="$emit('closeMenuItem', $event)"/>            
            <ToggleButton v-b-popover.hover.bottom="'Legend'" :menuItems="menuItems" :height="menuSize" :width="menuSize" selectionSide="top" subMenuName="legend" :icon="['far', 'list']" @openMenuItem="$emit('openMenuItem', $event)" @closeMenuItem="$emit('closeMenuItem', $event)"/>                        
            <template v-if="$appGlobals.isLargeScreen">
              <ToggleButton 
              :menuItems="menuItems" 
              :height="menuSize" 
              :width="menuSize"
              selectionSide="top" 
              subMenuName="properties" 
              icon="info-circle" 
              iconTransform="grow-2"
              v-b-popover.hover.bottom="'Properties'"
              @openMenuItem="$emit('openMenuItem', $event)" 
              @closeMenuItem="$emit('closeMenuItem', $event)"/>       
            </template>
            <ToggleButton v-b-popover.hover.bottom="'Settings'" :menuItems="menuItems" :height="menuSize" :width="menuSize" selectionSide="top" subMenuName="settings" icon="cog" @openMenuItem="$emit('openMenuItem', $event)" @closeMenuItem="$emit('closeMenuItem', $event)"/>
            <ToggleButton v-b-popover.hover.bottom="'Help'" :menuItems="menuItems" :height="menuSize" :width="menuSize" selectionSide="top" subMenuName="help" icon="question-circle" @openMenuItem="$emit('openMenuItem', $event)" @closeMenuItem="$emit('closeMenuItem', $event)"/>
            <ToggleButton v-b-popover.hover.bottom="'User profile'" :menuItems="menuItems" :height="menuSize" :width="menuSize" selectionSide="top" subMenuName="userProfile" @openMenuItem="$emit('openMenuItem', $event)" @closeMenuItem="$emit('closeMenuItem', $event)">
              <template v-if="user.photoURL">
                <img height="24" width="24" class="rounded-circle" :src="user.photoURL" alt="Image 1"/>
              </template>
              <template v-else>                 
                <font-awesome-icon height="24" width="24" class="rounded-circle" :icon="['far', 'user']"/>
              </template>               
            </ToggleButton>            
					</b-navbar-nav>                   
			</b-navbar>
		</div>
 	</portal>
</template>

<script>
import ToggleButton from "./ToggleButton.vue"

export default {       
  components: { ToggleButton },  
  props:
  {
    menuItems: {
      type: Object,
    },
    restoreState: {
      type: Boolean,       
    },    
    fromLocal: {
      type: Boolean,
    },
    saveState: {
      type: Boolean,       
    },    
  },
  data() {
    return {
      menuSize: 48,           
    }    
  },    
}

</script>

<style lang="scss">
@import "../../variables";

$topBarHeight: 48px;

.header {
  font-size: $fontSize;
  color:$textColour;
  background: $panelColour;
  height:$topBarHeight;
  
  .seperator {
    width: 0px;        
    height: 26px;
    border-width: 1px;    
    border-color: $controlSeperator;
    border-style: none none none solid;
  };
}

.header button{
  height:$topBarHeight;  
  background:transparent;
  border:none;  
  color: inherit;

  &.selected {
   background: $pColour;
  } 
}
.headerIconButton{
  width:$topBarHeight;
  color: $pColour !important;
  font-size:1.2rem !important;
  margin-top:0.2rem;
}
.projectIconButton{
  margin-left: 0px;
}

</style>
