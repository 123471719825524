<template>
  <div role="application" aria-label="Compact color picker" class="vc-compact">

    <ul class="vc-compact-colors" role="listbox">
      <li
        v-for="c in paletteUpperCase(palette)"
        role="option"
        :aria-label="'color:' + c"
        :aria-selected="c === pick"
        class="vc-compact-color-item"
        :key="c"
        :class="{'vc-compact-color-item--white': c === '#333' }"
        :style="{background: c}"
        @click="handlerClick(c)"
      >
        <div class="vc-compact-dot" v-show="c === pick"></div>
      </li>
    </ul>
  </div>
</template>

<script>
import colorMixin from './Common/Color'
import editableInput from './Common/EditableInput.vue'

const defaultColors = [
  '#000000','#373737','#494949','#707070','#8d8d8d','#aeaeae','#bcbcbc','#c2c2c2','#ffffff',
  '#263238','#37474f','#455a64','#546e7a','#607d8b','#78909c','#90a4ae','#b0bec5','#cfd8dc',
  '#0d47a1','#1565c0','#1976d2','#1e88e5','#2196f3','#42a5f5','#64b5f6','#90caf9','#bbdefb',
  '#4a148c','#6a1b9a','#7b1fa2','#8e24aa','#9c27b0','#ab47bc','#ba68c8','#ce93d8','#e1bee7',
  '#880e4f','#ad1457','#c2185b','#d81b60','#e91e63','#ec407a','#f06292','#f48fb1','#f8bbd0',
  '#b71c1c','#c62828','#d32f2f','#e53935','#f44336','#ef5350','#e57373','#ef9a9a','#ffcdd2',
  '#e65100','#ef6c00','#f57c00','#fb8c00','#ff9800','#ffa726','#ffb74d','#ffcc80','#ffe0b2',
  '#f57f17','#f9a825','#fbc02d','#fdd835','#ffeb3b','#ffee58','#fff176','#fff59d','#fff9c4',
  '#1b5e20','#2e7d32','#388e3c','#43a047','#4caf50','#66bb6a','#81c784','#a5d6a7','#c8e6c9',
  //'#','#','#','#','#','#','#','#','#','#',

]

export function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export default {
  name: 'Compact',
  mixins: [colorMixin],
  props: {
    palette: {
      type: Array,
      default () {
        return defaultColors
      }
    }
  },
  components: {
    'ed-in': editableInput
  },
  computed: {
    pick () {
      return this.colors.hex.toUpperCase()
    }
  },
  methods: {
    handlerClick (c) {
      this.colorChange({
        hex: c,
        source: 'hex'
      })
    }
  }
}

</script>

<style>
.vc-compact {
  padding:2px 0px 1px 2px;
  width: 192px;
  background-color: #222;
}
.vc-compact-colors {
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.vc-compact-color-item {
  list-style: none;
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 1px;
  margin-bottom: 1px;
  position: relative;
  cursor: pointer;
  border-radius: 5%; /*maybe ditch this*/
}
.vc-compact-color-item--white {
  box-shadow: inset 0 0 0 1px #ddd;
}
.vc-compact-color-item--white .vc-compact-dot {
  background: #000;
}

.vc-compact-dot {
  position: absolute;
  width: 20px;
  height: 20px;
  border:1px solid white;
  /* top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border-radius: 50%;
  opacity: 1;
  background: #fff; */
}
</style>