<template>
  <portal :to="'menus/' + menuName">
    <div class="legend h-100 d-flex flex-column">
      <SliderHeader 
      text="LEGEND" 
      :menuName="menuName"
      @showHelp="$emit('showHelp','legend')"
      @openMenuItem="$emit('openMenuItem', $event)" 
      @closeMenuItem="$emit('closeMenuItem', $event)">
      </SliderHeader>                    
      <div class="mainContent d-flex flex-column flex-grow-1 min-height-0 overflow-auto" style="padding-top: 8px; padding-bottom: 4px; padding-left: 6px">          
        <template v-if="layerLegends.length > 0">
          <template v-for="(layer, i) in layerLegends">
            <div class="layer d-flex flex-column" :key="i + '/' + layer.name">
              <span class="layerTitle">{{layer.name}}</span>
              <div class="legendList">
                <template v-for="(legendItem, index) in layer.legendItems">
                  <div class="legendItem d-flex" :key="index">
                    <img class="image" :src="legendItem.imageDataUrl">            
                    <span class="label">{{legendItem.label}}</span>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </template>
        <template v-else> 
          <span>
            No visible layers.
          </span>
        </template>
      </div>
    </div>
  </portal>
</template>

<script>
import SliderHeader from './shared/SliderHeader.vue';

export default {
	components: { SliderHeader }, 
  props: {
    subLayers: {
      type: Array,      
    },
    searchSubLayer: {
      tyepe: Object
    }    
  }, 
  data() {
    return {
      menuName: "legend"
    }
  },
  computed: {
    layerLegends() {

      return this.subLayers.filter(subLayer => subLayer.legend).reverse().map(subLayer => {
        
         return {
            name: (subLayer === this.searchSubLayer ? "(Search) " : "") + subLayer.title,
            legendItems: subLayer.legend                       
         }
      })     
    },
  },  
}
</script>

<style lang="scss">
@import "../variables";

.legend {
  color: $textColourLight;
  background-color: $panelColour;

  font-size: $fontSize;  

  .mainContent {
    background:  $panelContent;
  }

  .layer{

    border-bottom: solid;
    border-width: 1px;
    border-color: $panelColourSeperator;      
    padding-bottom: 16px;
    margin-bottom: 8px;

    .layerTitle {
      margin-bottom: 4px;
    }
    
    .legendList {      
      .legendItem {
        .image {
          margin-right: 4px;
        }        
      }      
    }
  }
}

</style>