// Interceptors for app and tracks all REST requests made using axios and the ArcGIS API.

// tracking allows a given function to execute, after all pending responses (or errors) have been processed.

// All responses are considered processed, if all responses have been received, and no new requests have
// been made within given time period.

import axios from 'axios';
import { loadModules } from "esri-loader";
import url from 'url';

export default class NetworkInterceptors {

  constructor(msalService) {
    this.track = false;
    this.timeoutMs = null;
    this.fn = () => {};
    this._arcGISTokens = null;
    this._layersConfig = null;
    this.trackedAxiosRequests = {};
    this.trackedArcGISRequests = {};  
    this.timeout = null;   
    this.msalService = msalService;
  }

  set arcGISTokens(tokens) {
    this._arcGISTokens = tokens;
  }        

  set layersConfig(config) {
    this._layersConfig = config;
  }   

  getServerToken(url) {

    //find layer
    if (this._layersConfig) {
     /* const layer = this._layersConfig.find(l => url.includes(l.arcGISPath));
      if(layer && layer.role!==""){
        const token = this._arcGISTokens.find(t => t.role==layer.role);
        if(token)
        {
          const expiryMs=(token.expires - new Date().getTime());        
          return token.token;
        }
      }*/ 

      if (this.locatorUrls) {
        let locators = this.locatorUrls.filter(locatorUrl => url.startsWith(locatorUrl));
        if (locators.length > 0) {      
          return this.locatorHubToken;      
        }    
      }
    }

    return null;
  }         

  timeoutFn = () => {
    this.track = false;    
    this.fn();
  }

  executeAfterLastResponse(fn, timeoutMs) { 
    this.timeoutMs = timeoutMs;
    this.fn = fn;
    this.track = true;    
    this.timeout = setTimeout(this.timeoutFn, this.timeoutMs);
  }       

  processArcGISResponse(url) {        
  
    if (this.track) {

      if (this.trackedArcGISRequests[url]) {
  
        if (this.trackedArcGISRequests[url] === 1) {
          delete this.trackedArcGISRequests[url];
        } else {
          this.trackedArcGISRequests[url] = this.trackedArcGISRequests[url] - 1;
        }

        //console.log(this.trackedAxiosRequests);
        //console.log(this.trackedArcGISRequests);
      
        // if no outstanding requests, set timeout.
        if ((Object.keys(this.trackedAxiosRequests).length === 0) && 
            (Object.keys(this.trackedArcGISRequests).length === 0)) {
          this.timeout = setTimeout(this.timeoutFn, this.timeoutMs);      
        }    
      }
    }
  }

  processAxiosResponse(url) {

    if (this.track)        
    {        
      if (this.trackedAxiosRequests[url]) {

        if (this.trackedAxiosRequests[url] === 1) {
          delete this.trackedAxiosRequests[url];
        } else {
          this.trackedAxiosRequests[url] = this.trackedAxiosRequests[url] - 1;
        }

        //console.log(this.trackedAxiosRequests);
        //console.log(this.trackedArcGISRequests);
          
        // if no outstanding requests, set timeout.
        if ((Object.keys(this.trackedAxiosRequests).length === 0) && 
            (Object.keys(this.trackedArcGISRequests).length === 0)) {
          this.timeout = setTimeout(this.timeoutFn, this.timeoutMs);      
        }    
      }
    }    
  }

  processAxiosRequest(config) {

    let url = config.url;
    let token = this.getServerToken(url);
  
    if (token) {
      config.params = {
        token: token
      }        
    } 

    //console.log(url);

    if (this.track) {  

      if (this.trackedAxiosRequests[url] === undefined) {
        this.trackedAxiosRequests[url] = 1;
      } else {
        this.trackedAxiosRequests[url] = this.trackedAxiosRequests[url] + 1;
      }

      //console.log(this.trackedAxiosRequests);
      //console.log(this.trackedArcGISRequests);
        
      clearTimeout(this.timeout);    
    }
  }

  async start() {
    let axiosRequestInterceptor = axios.interceptors.request.use(config => {      
  
      this.processAxiosRequest(config);  
      return config;
  
    }, function (error) {  
      console.error(error);
      return Promise.reject(error);
    });  
    
    let axiosResponseInterceptor = axios.interceptors.response.use(response => { 
  
      this.processAxiosResponse(response.config.url);  
      return response;
  
    }, error => {    

      // https://github.com/axios/axios#handling-errors            

      let details = "";

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);

        if (error.response.data.error) {
          details = error.response.data.error;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error.message);
      }
      
      console.error(error.config);      

      this.processAxiosResponse(error.config.url);  

      let message = error + ".  " + error.config.url + ". " + details;         
      let e = new Error(message);
      console.error(e);
      return Promise.reject(e);
    });  
  
    let arcGISConfig = (await loadModules(["esri/config"]))[0];          
  
    // Track all requests.
    arcGISConfig.request.interceptors.push({
      before: async (params) => {      

        if (params.url.startsWith(import.meta.env.VITE_ARCGIS_URL)) {
          var accessToken = await this.msalService.getToken({scopes: [import.meta.env.VITE_MBAPI_AppIDURI]})          
          if (!params.requestOptions.headers) {
            params.requestOptions.headers = {};
          }
          
          params.requestOptions.headers.Authorization = "Bearer " + accessToken;      
        }                          
  
        // Prevent blank tile errors.
        params.url = params.url.replace("blankTile=false", "blankTile=true");

        let url = params.url;    
        
        if (url.indexOf("/GPServer") !== -1) { 
          let webMapAsJSON = params.requestOptions.query.Web_Map_as_JSON;                    
          if (webMapAsJSON) {
            let webMap = JSON.parse(webMapAsJSON);                          

            let bookmarksLayer = webMap.operationalLayers.find(layer => layer.id === "bookmarksGfxLayer");
            let bookmarksIndex = webMap.operationalLayers.indexOf(bookmarksLayer);

            if (bookmarksLayer) {
              webMap.operationalLayers.splice(bookmarksIndex, 1);
            }

            /*
            webMap.operationalLayers.forEach(layer => {
              let layerUrl = layer.url;              

              if (layerUrl) {
                let token = this.getServerToken(layerUrl);                
                if (token) {
                  layer.url = layerUrl + "?token=" + token;
                  console.log(layer.url);
                  layer.url = layer.url.replace("localhost:5001", "webgis.norfolk.gov.uk");
                  console.log(layer.url);
                }
              }
            })*/
            
            let json = JSON.stringify(webMap);
            params.requestOptions.query.Web_Map_as_JSON = json;
          }
        }
  
        /*const token = this.getServerToken(url);
        if(token)
        {          
          params.requestOptions.query.token = token;
        } */       
  
        if (this.track) {
  
          if (this.trackedArcGISRequests[url] === undefined) {
            this.trackedArcGISRequests[url] = 1;
          } else {
            this.trackedArcGISRequests[url] = this.trackedArcGISRequests[url] + 1;
          }

          //console.log(this.trackedAxiosRequests);
          //console.log(this.trackedArcGISRequests);
                
          clearTimeout(this.timeout);    
        }
      },
      after: (params) => {     
  
        let url = params.url;
        this.processArcGISResponse(url);
      },     
      error: (params) => {   

        let url = params.details.url;

        let logIt = true;

        // Stuff like quickly scrolling the map, cancels previos requests.
        if (((params.message === "Request canceled") &&
             (params.name === "AbortError")))
        {
          logIt = false;          
        }                
     
        if (logIt) {          
          console.error(params);   
        }        

        this.processArcGISResponse(url);    
      }
    });
  }
}
