<template>    
    <portal :to="'menus/' + menuName">       
        <div class="help h-100 d-flex flex-column">
            <SliderHeader 
            text="HELP" 
            :menuName="menuName" 
            :showHelp=false
            @openMenuItem="$emit('openMenuItem', $event)" 
            @closeMenuItem="$emit('closeMenuItem', $event)">
            </SliderHeader>                    
            <iframe id="helpIframe" frameborder="0" width="100%" height="100%" :src="iframeSource"></iframe> 
            <b-navbar class="customFooter" type="dark" >
              <b-navbar-nav  class="ml-auto">
                <b-nav-item :href="iframeSource" target="_blank">
                  <span class="float-right">
                  <font-awesome-icon v-b-popover.hover.top="'Open help in new window'" :icon="['far', 'external-link-square']"></font-awesome-icon> 
                  </span>
                </b-nav-item>
              </b-navbar-nav>
            </b-navbar> 
        </div>        
    </portal>
</template>

<script>

import SliderHeader from './shared/SliderHeader.vue';

export default {
  components: { SliderHeader },
   props: {
      helpUrl: {
        default: "",
        type: String,
        required: true
      },
      urlBase: {
        default: "",
        type: String,
        required: true
      }, 
  },
  data() {
    return {      
      menuName: "help"
    }
  },
  computed:{
    iframeSource(){
      return this.urlBase + (this.helpUrl===null ? "": this.helpUrl);
    }
  },
  watch:{
    helpUrl(newUrl,oldUrl){                  
    }
  }
}    
</script>

<style lang="scss" scoped>

@import "../variables";

.help {
  color: $textColour;
  background-color: $panelColour;

  .mainContent{
    background:  $panelContent;
  }
  .customFooter{
    background-color: $panelContent;
  }
}

</style>