<template>
	<div>
		<template v-if="leftMenu">
			<Menu
				:showResizeGrips="showResizeGrips"
				v-show="leftMenuShown"
        :slideTimeMs="slideTimeMs"
				ref="left"
				side="left"      
				:menu="leftMenu"      
				:leftOffset="leftOffset"
				:topOffset="(topMenuShown ? (topMenuSize - 1) : 0) + topOffset"
				:bottomOffset="(bottomMenuShown ? (bottomMenuSize - 1) : 0) + bottomOffset"
				:menuBarSize="leftMenuBarSize"				
				@resized="menuResized"
				@resizing="menuResizing"
				>        
			</Menu>          
		</template>
    <template v-if="rightMenu">
			<Menu
				:showResizeGrips="showResizeGrips"
				v-show="rightMenuShown"				
        :slideTimeMs="slideTimeMs"
				ref="right"
				side="right"      
				:menu="rightMenu"      
	      :topOffset="(topMenuShown ? (topMenuSize - 1) : 0) + topOffset"
				:bottomOffset="(bottomMenuShown ? (bottomMenuSize - 1) : 0) + bottomOffset"
				:rightOffset="rightOffset"				
				:menuBarSize="rightMenuBarSize"
				@resized="menuResized"
				@resizing="menuResizing"
				>       
			</Menu>          
		</template>
    <template v-if="topMenu">
			<Menu
				:showResizeGrips="showResizeGrips"
				v-show="topMenuShown"				
        :slideTimeMs="slideTimeMs"
				ref="top"
				side="top"      
				:menu="topMenu"      
				:leftOffset="leftOffset"
				:topOffset="topOffset"
				:rightOffset="rightOffset"
				:menuBarSize="topMenuBarSize"
				@resized="menuResized"
				@resizing="menuResizing"
				>        
			</Menu>          
		</template>
	  <template v-if="bottomMenu">
			<Menu
				:showResizeGrips="showResizeGrips"
				v-show="bottomMenuShown"	
        :slideTimeMs="slideTimeMs"			
				ref="bottom"
				side="bottom"      
				:menu="bottomMenu"      
				:leftOffset="leftOffset"
				:rightOffset="rightOffset"
				:bottomOffset="bottomOffset"
				:menuBarSize="bottomMenuBarSize"
				@resized="menuResized"
				@resizing="menuResizing"
				>        
			</Menu>          
    </template>
	</div>
</template>

<script>

import Menu from "./Menu.vue"

export default {  
  name: "MenuContainer",
  components: { Menu },
  props: {    
		showResizeGrips: {
			type: Boolean,			
		},
    leftMenu: {
      type: Object,      
      required: true
		},    
		rightMenu: {
      type: Object,      
      required: true
		},
		topMenu: {
      type: Object,      
      required: true
		},
		bottomMenu: {
      type: Object,      
      required: true
		},
		leftMenuShown: {
			type: Boolean,
			default: true
		},
		topMenuShown: {
			type: Boolean,
			default: true
		},
		rightMenuShown: {
			type: Boolean,
			default: true
		},
		bottomMenuShown: {
			type: Boolean,
			default: true
		},
		leftMenuBarSize : {
      type: Number,
      Required: true
		},
		topMenuBarSize : {
      type: Number,
      Required: true
		},   
		rightMenuBarSize : {
      type: Number,
      Required: true
		},   
    bottomMenuBarSize : {
      type: Number,
      Required: true
		},    
		leftOffset: {
			type: Number,     
			default: 0 
    },  
    topOffset: {
			type: Number,
			default: 0  
    },
    rightOffset: {
			type: Number,            
			default: 0
    },    
    bottomOffset: {
			type: Number,      
			default: 0
		},				
		slideTimeMs: {
			type: Number,
			default: 275
		}
	}, 
	data() {
		return {
			leftMenuSize: 0,
			topMenuSize: 0,
			rightMenuSize: 0,
			bottomMenuSize: 0,
		}
	},
	mounted()
	{
		if (this.$refs.left) {
			this.leftMenuSize = this.$refs.left.$el.clientWidth;
		}   
		if (this.$refs.top) {
			this.topMenuSize = this.$refs.top.$el.clientHeight;
		}   
		if (this.$refs.right) {
			this.rightMenuSize = this.$refs.right.$el.clientWidth;
		}   
		if (this.$refs.bottom) {
			this.bottomMenuSize = this.$refs.bottom.$el.clientHeight;
    }        
	},
	methods:
  { 
		menuResizing(menu) {
			if (menu.name === "left") {
				this.leftMenuSize = menu.visibleSize;        
			}      
			
			if (menu.name === "top") {
				this.topMenuSize = menu.visibleSize;
			}

			if (menu.name === "right") {
				this.rightMenuSize = menu.visibleSize;        
			}      
			
			if (menu.name === "bottom") {
				this.bottomMenuSize = menu.visibleSize;
			}
			
			this.$emit("menuResizing", menu);
		},			      

		menuResized(menu) {
			if (menu.name === "left") {
        this.leftMenuSize = menu.visibleSize;        
			}      
			
			if (menu.name === "top") {
				this.topMenuSize = menu.visibleSize;
			}

      if (menu.name === "right") {
        this.rightMenuSize = menu.visibleSize;        
			}      
			
			if (menu.name === "bottom") {
				this.bottomMenuSize = menu.visibleSize;
      }
      
      this.$emit("menuResized", menu);
		},		
	},	
};
</script>
