<template>
  <div class="h-100 w-100">
    <portal :to="'featureGridHeader' + id">
      <div class="d-flex align-items-center">
        <button @click="zoomToFitSelected" v-b-popover.hover.top="'Zoom to fit selected results'"><font-awesome-icon :icon="['far', 'search-plus']" class="mr-1"/></button>                 
        <div class="seperator"></div>
        <button @click="unSelectAll" v-b-popover.hover.top="'Unselect All'"><font-awesome-icon :icon="['far', 'object-ungroup']" class="mx-1"/></button>                 
        <button @click="selectAll" v-b-popover.hover.top="'Select All'"><font-awesome-icon :icon="['fas', 'object-union']" class="mr-1"/></button>                 
        <div class="seperator mr-1"></div>            
        <button
        id="deleteButton"
        class="d-flex align-items-center mr-1"   
        :class="{inactive: !canDelete}"         
        v-b-popover.hover.top="'Delete selected'"
        @click="deleteSelected"
        > 
          <font-awesome-icon icon="trash-alt"/>
        </button>                  
        <div class="seperator"></div>                                          
        <DropdownSelect             
        v-b-popover.hover.top="'Export Results'"
        v-model="exportOption" 
        :items="exportItems"
        noItemsSelectedText="Export"
        icon="file-export"
        >
        </DropdownSelect> 
        <div class="seperator mr-2"></div>
        {{summary}}
      </div>  
    </portal>
    <Grid 
    ref="grid"
    :columns="columns"                            
    :rows="rows"              
    :sortedColumnField="sortedColumnField"
    :sortedAscending="sortedAscending"
    @selectedRowChangeRequest="selectedRowChangeRequest"
    @filteredAndSortedRowsChanged="filteredAndSortedRowsChanged"
    @columnFilterChanged="columnFilterChanged"
    @sortedColumnChanged="sortedColumnChanged"
    >        
      <template slot="prependCustomHeadersFirstRow">
        <th class="noResize "></th>
        <th class="noResize "></th>
        <template v-if="canZoomToFitFeature">
          <th class="noResize"></th>
        </template>      
      </template>      
      <template slot="prependCustomHeadersSecondRow">
        <th class="noResize"/>
        <th class="noResize"/>
        <template v-if="canZoomToFitFeature">
          <th class="noResize"/>
        </template>      
      </template>      
      <template slot="prependCell" slot-scope="scopeData">
        <td style="min-width: 26px; width: 26px" class="noResize">
          <button
          class="d-flex align-items-center"  
          v-b-popover.hover.top="'Pin Bookmark'"                                         
          @click="addBookmark(scopeData.row)"
          >
              <font-awesome-icon :icon="['fas', 'bookmark']"/>
          </button>      
        </td>        
        <td 
          :style="{'min-width': '26px', 'width': canZoomToFitFeature ? '26px' : '32px'}"
          class="noResize">
          <button
          class="d-flex align-items-center"  
          v-b-popover.hover.top="'Goto'"                                         
          @click="goto(scopeData.row)"
          >
            <font-awesome-icon :icon="['far', 'dot-circle']"/>        
          </button>                          
        </td>
        <template v-if="canZoomToFitFeature">
          <td style="min-width: 32px; width: 32px" class="noResize">                    
            <button
            class="d-flex align-items-center"  
            v-b-popover.hover.top="'Goto and zoom to fit'"                    
            @click="gotoAndZoomToFit(scopeData.row)"
            >
              <font-awesome-icon :icon="['far', 'search-location']"/>       
            </button>   
          </td>          
        </template>
      </template>
    </Grid>
  </div>                              
</template>

<script>

import Vue from 'vue';
import DropdownSelect from "./shared/DropdownSelect.vue";
import Grid from "./shared/Grid.vue";
import { default as FeatureMixin } from "./FeatureMixin.vue";

export default {
  components: { Grid, DropdownSelect},  
  mixins: [FeatureMixin],
  name: "FeatureGrid", 
  props: {
    id: {
      type: String,
      required: true
    },
    layer: {
      type: Object,
      required: true
    },
    features: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    sortedColumnField: {
      type: String,
      required: false
    },
    sortedAscending: {
      type: Boolean,
      required: true
    }
  },           
  data() {
    return {                         
      shownFeatures: [],
      exportOption: null,      
      exportItems: [
        {
          text: "All results",
          value: "All results"
        },
        {
          text: "Selected results",
          value: "Selected results"
        },
      ]
    }                    
  },        
 
  computed:
  {     
    canDelete() {
      return this.selectedFeatures.length > 0 && this.selectedFeatures.every(feature => this.canDeleteFeature(feature))
    },
    selectedFeatures() {
      return this.shownFeatures.filter(feature => feature.isSelected);
    },   
    canZoomToFitFeature() {
      return (this.features.length > 0) && (this.canZoomToFit(this.features[0]));
    },

    columns() {    
      let columns = [];        
          
      this.fields.forEach(field => {       
        let fieldType = null;

        switch (field.type) {              
          case "esriFieldTypeDate":
            fieldType = "date";
            break;
          case "esriFieldTypeOID":
          case "esriFieldTypeDouble":
          case "esriFieldTypeGlobalID":
          case "esriFieldTypeInteger":                                           	
          case "esriFieldTypeSingle":                     
          case "esriFieldTypeSmallInteger":
            fieldType = "number";
            break;
          case "esriFieldTypeString":
          case "esriFieldTypeGUID":            	            	
          case "esriFieldTypeXML":
            fieldType = "string";
            break;
          case "esriFieldTypeBlob":                
          case "esriFieldTypeGeometry": 
          case "esriFieldTypeRaster":
            break;  
        }        

        if (fieldType) {
          columns.push({ 
            field: field.name,
            label: field.alias,
            type: fieldType,
            filter: field.filterText
          })
        }    
      })        
              
      return columns;
    },

    rows() {
      
      let rows = this.features.map(feature => {          
        let keys = Object.keys(feature.attributes);
        let row = {};                                   

        keys.forEach(key => {         
          if (key === "MB_NAME" && !feature.attributes[key]) {
            let geometryType = this.getGeometryType(feature);
            if (geometryType === "polyline") {
              geometryType = "line";
            }
            
            row[key] = geometryType.charAt(0).toUpperCase() + geometryType.slice(1);            
          } else {            
            let field = feature.layer.fields.find(field => field.name === key);      
            if (field) {
              let val = feature.attributes[key];
              val = this.getFeatureCodedValue(feature, field, val);                  
              row[key] = val;
            }
          }                    
          row.feature = feature;
          row.isSelected = feature.isSelected;          
          row.id = this.getGlobalFeatureId(feature)
        })

        return row;
      })            
      
      return rows;
    },
    summary() { 

      let summary = "";
       
      let totalCount = this.features.length;                            
      let displayedCount =  this.shownFeatures.length;

      if (displayedCount < totalCount) {          
        summary = `Showing ${displayedCount} of ${totalCount} results`;                    
      } 
      else
      {
        if (totalCount === 1) {
          summary = `${totalCount} result`;                                  
        } else {
          summary = `${totalCount} results`;
        }        
      }                   

      summary += ".";          
      
      let totalSelectedCount = this.selectedFeatures.length;

      if (totalSelectedCount > 0) {
        summary += ` ${totalSelectedCount} selected.`
      }

      return summary;
    }
  }, 
  watch: {
    exportOption(value) {
      let option = value;
      this.exportOption = null;
      
      let gridComponent = this.$refs.grid;

      let csv;
      if (option === "All results") {
        csv = gridComponent.getAsCSV(false);
        
      } else if (option === "Selected results") 
      {
        csv = gridComponent.getAsCSV(true);        
      }

      this.download(csv, this.layer.title + ".csv", "text/csv;charset=utf-8;")
    },
  },
  methods:  
  {    
    deleteSelected() {
      if (this.canDelete) {
        let selected = this.selectedFeatures.slice();
        this.$emit("delete", selected);      
      }
    },    

    zoomToFitSelected() {
      if (this.selectedFeatures.length > 0) {
        this.$emit("zoomToFitFeatures", this.selectedFeatures);
      }      
    },
    sortedColumnChanged(args) {
      let field = this.fields.find(field => field.name === args.columnField);
      this.$emit('sortedFieldChanged', {
        field: field,
        ascending: args.ascending
      });
    },

    columnFilterChanged(args) {
      let field = this.fields.find(field => field.name === args.columnField);
      this.$emit('fieldFilterChanged', {
        field: field,
        value: args.value
      }); 
    },    

    unSelectAll() {                  

      // retain selected features in non-shown features only.
      let hiddenFeatures = this.features.diff(this.shownFeatures)
      let selectedHiddenFeatures = hiddenFeatures.filter(feature => feature.isSelected);
                  
      this.$emit("selectedFeatureChangeRequest", selectedHiddenFeatures);
    },
    
    selectAll() {

      // retain selected features in non-shown features only.
      let hiddenFeatures = this.features.diff(this.shownFeatures)
      let selectedHiddenFeatures = hiddenFeatures.filter(feature => feature.isSelected);

      this.$emit("selectedFeatureChangeRequest", this.shownFeatures.concat(selectedHiddenFeatures));   
    },

    goto(row) {
      this.$emit("goto", row.feature);
    },

    gotoAndZoomToFit(row) {
      this.$emit("gotoAndZoomToFit", row.feature);
    },

    addBookmark(row) {
      this.$emit("addBookmark", row.feature);
    },    

    selectedRowChangeRequest(args)
    {       
      let newSelectedFeatures = args.newSelectedRows.map(row => row.feature);
      this.$emit("selectedFeatureChangeRequest", newSelectedFeatures);      
    },

    filteredAndSortedRowsChanged(args)
    {             
      let oldShownFeatures = args.oldShownRows.map(row => row.feature);
      this.shownFeatures = args.newShownRows.map(row => row.feature);
      
      this.$emit("filteredAndSortedFeaturesChanged", {
        oldShownFeatures : oldShownFeatures,
        newShownFeatures: this.shownFeatures
      });        
    },
  },    
};

</script>

<style lang="scss" scoped>
@import "./../variables";

.results {

  th {
    background-color: $panelContent;  
  }  

  .topBar {        
    height: 36px;

    button.coloured {
      height: 100%;
      background-color: $pColourDarkest;
      color: $textColourLight;
    }

    .seperator {
      width: 0px;        
      height: 26px;
      border-width: 1px;    
      border-color: $controlSeperator;
      border-style: none none none solid;
    };
  }

  button {
    color: $textColour;
    background-color: inherit;
    border: none;

    &.selected {
      color: $textColourLight;
    }   

    &:hover{
      color:$textColourHover;
    }

    &.inactive {
      svg {
        color: $pColourDarkest;
      }
    }
  }

  color: $textColour;
  background-color: $panelColour;  
  font-size: $fontSize;  

  .mainContent {
    background:  $panelContent;
  }

  .bottomBorder
  {
    border-bottom: 1px solid $panelColourSeperator;    
  }
}


</style>

