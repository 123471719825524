<template>    
	<portal :to="'menus/' + menuName">
    <div class="properties h-100 d-flex flex-column"> 
      <SliderHeader 
      :text="title"
      :menuName="menuName"
      @showHelp="$emit('showHelp', 'properties')"
      @openMenuItem="$emit('openMenuItem', $event)" 
      @closeMenuItem="$emit('closeMenuItem', $event)" 
      >        
      </SliderHeader>
      <div class="mainContent d-flex flex-column flex-grow-1 min-height-0" style="padding-top: 8px; padding-bottom: 4px; padding-left: 8px; padding-right: 10px">
        <div class="topBar bottomBorder d-flex align-items-center flex-shrink-0 pb-2">             
          <span class="selectedText">{{selectedText}}</span>
          <div class="ml-auto seperator"></div>
          <div class="d-flex align-items-center ml-2 mr-1">   
            <button
            id="deleteButton"
            class="d-flex align-items-center mr-1"                        
            @click="$emit('deleteFeatures', selectedValues)"
            >
              <font-awesome-icon icon="trash-alt"/>
            </button>      
            <button
            class="d-flex align-items-center"  
            v-b-popover.hover.top="'Goto (retain scale)'"                                         
            @click="$emit('goto', selectedValues)"
            >
            <font-awesome-icon :icon="['far', 'dot-circle']"/>        
            </button>              
            <button
            class="d-flex align-items-center mr-1"  
            v-b-popover.hover.top="'Goto and zoom to fit'"
            @click="$emit('gotoAndZoomToFit', selectedValues)"
            >
              <font-awesome-icon :icon="['far', 'search-location']"/>
            </button>              
          </div>
        </div>    
        <div class="d-flex flex-column mt-2 ml-2 mr-1">   
          <template v-if="myFeatures.length > 0">
            <span class="my-1">{{featuresText}}</span>   
          </template>
          <template v-if="measurements.length > 0">
            <span class="my-1">{{measurementsText}}</span>   
          </template>
          <template v-if="labels.length > 0">
            <span class="my-1">{{labelsText}}</span>   
          </template>
        </div>          
      </div>
    </div>
	</portal>
</template>

<script>

import Moment from 'moment';
import SliderHeader from './shared/SliderHeader.vue';

export default {
  components: { SliderHeader }, 	
  props: {   
    title: {
      type: String,
      default: ""
    },    
    selectedValues: {
      type: Array
    },    
  },
  computed: {
    myFeatures() {
      return this.selectedValues.filter(feature => feature.type === "userFeature");
    },
    measurements() {
      return this.selectedValues.filter(feature => feature.type === "measurement");
    },
    labels() {
      return this.selectedValues.filter(feature => feature.type === "annotationEnd");
    },
    featuresText() {
      return `My features (${this.myFeatures.length})`
    },    
    measurementsText() {
      return `Measurements (${this.measurements.length})`
    },
    labelsText() {
      return `Labels (${this.labels.length})`
    },

    selectedText() {
      return `${this.selectedValues.length} selected items.`
    }    
  },
  data() {
    return {      
      menuName: "properties",        
    }
  },    
}    

</script>

<style lang="scss">

@import "../variables"; 

.properties .mainContent .dropdown button {
  color: $textColourLight
}

</style>

<style lang="scss">

@import "../variables"; 

.properties {

  background-color: $panelColour;
  color:$textColourLight;
  font-size: $fontSize;    

  .mainContent{   

    .selectedText {
      padding-left: 8px;
      padding-right: 8px;
    }

    .itemSelect {

      text-overflow: ellipsis;

      .dropdown {
        min-width: 0;

        button {
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 0;
        }
      } 
    }

    .scrollbarPanel {
      overflow-y: auto;
      overflow-x: hidden;    
    }

    button {
      color: $textColour;
      background-color: inherit;
      border: none;

      &.selected {
        color: $textColourLight;
      }   

      &:hover{
        color:$textColourHover;
      }
    }

    background:  $panelContent;    

    .seperator {
      width: 0px;        
      height: 26px;
      border-width: 1px;    
      border-color: $controlSeperator;
      border-style: none none none solid;
    };

    .topBar {        
      height: 36px;
    }

    .bottomBorder
    {
      border-bottom: 1px solid $panelColourSeperator;    
    }
  }

  .scrollbarPanel {
      overflow-y: auto;
      overflow-x: hidden;    
  }  
}

</style>