// A promise wrapper around axois, which ensures that responses are processed in the same order as their requests are made.

import axios from 'axios';

class OrderedAxios {  

  constructor() {
    this.promises = []
  }

  get(url) {
    var outsideResolve;
    var outsideReject;

    let promise = new Promise((resolve, reject) => {
      outsideResolve = resolve;
      outsideReject = reject;
    });

    promise.resolve = outsideResolve;
    promise.reject = outsideReject;

    this.promises.push(promise);
    
    axios.get(url)
      .then(response => {
        promise.response = response;                
        this.processPromises();
      })
      .catch(error => {
        promise.error = error;
        this.processPromises();
      })         
      
    return promise;
  }  

  processPromises() {            
    let continueProcessing = false;
    do { 
      continueProcessing = false;
      if (this.promises.length) {
        let promise = this.promises[0];
        if (promise.response || promise.error) {
          this.promises.shift();
          if (promise.response) {
            promise.resolve(promise.response);
          } else {
            promise.reject(promise.error);
          }
          continueProcessing = true;
        }
      }
    } while (continueProcessing);      
  }
}

export default OrderedAxios;

