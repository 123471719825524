<template>    
	<portal :to="'menus/' + menuName">
    <div class="layerManager h-100">              
      <div class="h-100 d-flex flex-column layersSlider">
        <SliderHeader 
        text="LAYERS" 
        :menuName="menuName"
        @showHelp="$emit('showHelp','layermanager')"
        @openMenuItem="$emit('openMenuItem', $event)" 
        @closeMenuItem="$emit('closeMenuItem', $event)">
        </SliderHeader>                    
        <div class="d-flex flex-column h-100 min-height-0">   
          <Tabs              
          v-model="activeTab"
          class="h-100 mainContent"
          :tabs="tabs">
            <template slot="allTab">                                            
              <div style="padding-top: 6px;" class="d-flex flex-column h-100">   
                <div class="d-flex options align-items-center flex-shrink-0 px-2">
                  <SearchInput 
                  class="w-100" 
                  ref="searchInput" 
                  :value="filterString" 
                  @input="filterStringChanged"
                  placeholder="layer..."></SearchInput>       
                </div>	
                <LayerTree
                ref="allTree"                  
                :layers="layers"
                :filterString="filterString"    
                :showLegendSymbology="false"
                orderAlphabetically
                @info="info"
                @layerVisibilityChange="layerVisibilityChange"
                @importIntoLayer="$emit('importIntoLayer', $event)"  
                @deleteLayer="$emit('deleteLayer', $event)"    
                @editLayer="$emit('editLayer', $event)"    
                >
                </LayerTree>
              </div>
            </template>                                  
            <template slot="favouritesTab">
              <div class="h-100 d-flex flex-column">   
                <LayerTree 
                ref="favouritesTree"              
                :layers="layers"                                                  
                showFavouritesOnly                                        
                :showLegendSymbology="true"
                @info="info"
                @layerVisibilityChange="layerVisibilityChange"
                @reorderLayerItem="$emit('reorderLayerItem', $event)"   
                @importIntoLayer="$emit('importIntoLayer', $event)"      
                @deleteLayer="$emit('deleteLayer', $event)"      
                @editLayer="$emit('editLayer', $event)"                              
                >
                </LayerTree>
            </div>
            </template>
            <template slot="visibleTab">
              <div class="h-100 d-flex flex-column">   
                <LayerTree 
                ref="visibleTree"             
                :layers="layers"                               
                showVisibleOnly                    
                enableReorder
                :showLegendSymbology="showLegendSymbology"
                @info="info"
                @layerVisibilityChange="layerVisibilityChange"
                @reorderLayerItem="$emit('reorderLayerItem', $event)"
                @importIntoLayer="$emit('importIntoLayer', $event)"      
                @deleteLayer="$emit('deleteLayer', $event)"    
                @editLayer="$emit('editLayer', $event)"                              
                >
                </LayerTree>
              </div>
            </template>
          </Tabs>
        </div>                                      
      </div>        
    </div>
	</portal>
</template>

<script>
import SliderHeader from './shared/SliderHeader.vue';
import SubSlider from './shared/SubSlider.vue';
import LayerTree from "./LayerTree.vue";  
import Tabs from "./shared/Tabs.vue";  
import SearchInput from "./shared/SearchInput.vue";  
import { default as LayerManagerPersistance } from "./LayerManagerPersistance.vue"

export default {
  components: { SliderHeader, SubSlider, LayerTree, Tabs, SearchInput }, 
  mixins: [ LayerManagerPersistance ],
	props: { 	
		layers: {
			type: Array,            
    },    
    showLegendSymbology: {
      type: Boolean,
      default: true
    },    
	},    
			
	data() {
		return {
			layerInfo: null,			
			slideTimeMs: 275,
      filterString: "",					
      menuName:	"layerManager",
      activeTab: "allTab",
      tabs: [
        {
          label: 'All', 
          slot: 'allTab',
          icon: 'bars'
        }, 
        { 
          label: 'Favourites', 
          slot: 'favouritesTab', 
          icon: 'star'
        }, 
        { 
          label: 'Visible', 
          slot: 'visibleTab', 
          icon: 'eye'
        }
      ]
		}
  },     
	methods: {    

    setLayersExpanded(layer, expanded) {

      layer.expandedInAllTree = false;

      if (layer.sublayers) {
        layer.sublayers.forEach(child => {
          this.setLayersExpanded(child, expanded);
        });      
      }
    },

    filterStringChanged(filterString){
      
      this.layers.forEach(layer => {
        this.setLayersExpanded(layer, false);
      });      

      this.filterString = filterString;
    },
		info(item) {			
      this.$emit("info", item);
		},
		layerVisibilityChange(payload) {
			this.$emit("layerVisibilityChange", payload);
    },    
	}
}    
</script>

<style lang="scss" scoped>
@import "./../variables";

.layerManager {
  color: $textColourLight;
  background-color: $panelColour;
  
  .mainContent{
    background:  $panelContent;
  }

  .options {

    margin: 0px 20px 2px 16px;
    padding-bottom: 4px;

    .optionsButton {
      margin-left: 8px;
    }    


  }

  button {
    border:none;
    padding: 0px;
    color:inherit;
    background: inherit;
    cursor: pointer;
    outline: 0 !important;
    position: relative;
    top: 4px;
  }
}

</style>