<template>
  <b-modal id="OKDialog"
  ref="OKDialog"
  @shown="shown" 
  @hidden="hidden" 
  @hide="hide"
  ok-only 
  centered>
    <template slot="modal-title">        
      <slot name="title"/>      
    </template>
    <div ref="details">
      <slot></slot>
    </div>      
  </b-modal>
</template>

<script>

export default {	
  
  methods: {
    show() {
      this.$refs.OKDialog.show();
    },    
    hide() {      
      this.$refs.OKDialog.$refs.modal.removeEventListener("keyup", this.enterKeyHandler);  
    },
    shown(e) {      
      this.$refs.OKDialog.$refs.modal.addEventListener("keyup", this.enterKeyHandler);
    },
    enterKeyHandler(e) {
      // Enter key
      if (e.keyCode === 13) {          
        this.$refs.OKDialog.hide();
      }        
    },
    hidden() {      
      this.$emit('hidden');
    },    
  }  
} 

</script>

<style lang="scss" scoped>

</style>

<style lang="scss">

#OKDialog .modal-dialog {
    max-width: 90% !important;    
    width: 90% !important;
    
}

</style>