<template>
  <portal to="menus/bottom/statusBar">
    <div class="d-flex mapStatusBar align-items-center">
      <div style="width: 20px" class="mapStatusBarElement elementMargin">
        <!--<b-spinner v-show="updating"  small  />-->
        <font-awesome-icon
          :icon="['fad', 'spinner-third']"
          :class="
            mapView.updating ? 'spinnerUpdating' : 'spinnerNotUpdating'
          "
          :spin="mapView.updating"
        ></font-awesome-icon>
      </div>
      <div class="mapStatusBarElement">Scale:</div>
      <div class="mapStatusBarElement elementMargin">
        <DropdownSelect
          class="flex-grow-1"
          @input="scaleChanged" 
          :noItemsSelectedText="Math.round(mapView.scale).toString()"
          :value="mapView.scale"          
          :items="availableScaleItems"
        ></DropdownSelect>
      </div>
      <div class="mapStatusBarElement mr-2">
        Rotation: &nbsp;{{ mapView.rotation.toFixed(2) }}
      </div>
      <div class="mapStatusBarElement">
        <DropdownSelect
          class="flex-grow-1 mr-2"
          v-model="coordinateModeData"
          :items="coordinateModeItems"
        ></DropdownSelect>
      </div>
      <div class="mapStatusBarSeperator mr-2"></div>
      <span ref="pointerCoords"></span>
      <div class="mapStatusBarSeperator mx-2"></div>
      <button
        class="d-flex align-items-center"
        v-b-popover.hover.top="locationPin ? 'Goto location marker' : ''"
        @click="gotoLocationMarker"
      >
        <font-awesome-icon class="mr-1" :icon="['fas', 'map-marker-alt']" />
      </button>
      <EditableText
        class="markerLocation"
        :commitOnClickAway="false"
        placeHolder="Goto..."
        :editingPlaceHolder="gotoEditingPlaceHolder"
        :value="pinCoordinates"
        @input="coordinatesChanged"
        :edit="editingCoords"
        :fixValueFunc="fixCoordinates"
        :validateValue="validateXCoordinate"
        @edit="$emit('editingMapCoords', $event)"
        :pointerCoordData="this.coords"
      >
    </EditableText>
      <div class="ml-auto d-flex align-items-center">
        <button
          v-b-popover.hover.top="'Selection outline colour'"
          :style="{ background: selectionOutlineColour }"
          id="selectionColourButton"
        ></button>
        <b-popover
          id="selectionColourPopover"
          :show.sync="selectionOutlineColourPickerShow"
          target="selectionColourButton"
          placement="top"
          triggers="click blur"
        >
          <template>
            <CompactColorPicker
              :value="selectionOutlineColour"
              @input="selectionOutlineColourChange"
            ></CompactColorPicker>
          </template>
        </b-popover>
        <div class="mapStatusBarSeperator ml-2"></div>
        <button
          v-b-popover.hover.top="'Toggle Scale Bars'"
          :class="scalebarVisible ? 'mapStatusBarIconToggled' : ''"
          class="mapStatusBarElement mapStatusBarIcon"
          @click="$emit('toggleUIElementVisibiliy', 'mapScaleBar')"
        >
          <font-awesome-icon :icon="['fas', 'grip-lines']"></font-awesome-icon>
        </button>
        <button
          v-b-popover.hover.top="'Toggle Compass'"
          :class="compassVisible ? 'mapStatusBarIconToggled' : ''"
          class="mapStatusBarElement mapStatusBarIcon"
          @click="$emit('toggleUIElementVisibiliy', 'mapCompass')"
        >
          <font-awesome-icon
            :icon="['fas', 'location-arrow']"
          ></font-awesome-icon>
        </button>
        <button
          v-b-popover.hover.top="'Toggle Navigation Buttons'"
          :class="navigationVisible ? 'mapStatusBarIconToggled' : ''"
          class="mapStatusBarElement mapStatusBarIcon"
          @click="$emit('toggleUIElementVisibiliy', 'mapNavigation')"
        >
          <font-awesome-icon
            :icon="['fas', 'sliders-v-square']"
          ></font-awesome-icon>
        </button>
        <!-- <button v-b-popover.hover.top="'Toggle 3D/2D'"  class="mapStatusBarElement mapStatusBarIcon" @click="$emit('set3D', !is3D)"
                :class="is3D ? 'mapStatusBarIconToggled' : ''">            
                <font-awesome-icon :icon="['fas', 'cube']"></font-awesome-icon>
            </button> </!-->
        <button
          v-b-popover.hover.top="'Toggle Overview'"
          :class="overviewVisible ? 'mapStatusBarIconToggled' : ''"
          class="mapStatusBarElement mapStatusBarIcon"
          @click="$emit('toggleUIElementVisibiliy', 'mapOverview')"
        >
          <font-awesome-icon icon="expand" />
        </button>
        <div class="mapStatusBarSeperator ml-2"></div>
        <button
          v-b-popover.hover.top="'Find Me'"
          class="mapStatusBarElement mapStatusBarIcon"
          @click="$emit('gotoCurrentPosition')"
        >
          <font-awesome-icon icon="home" />
        </button>
        <!--             <button v-b-popover.hover.top="'Share'" 
                    class="mapStatusBarElement mapStatusBarIcon" >
                <font-awesome-icon icon="share-alt"/>
            </button>
            <button v-b-popover.hover.top="'Feedback'" 
                    class="mapStatusBarElement mapStatusBarIcon" >
                <font-awesome-icon icon="comment"/>
            </button> -->
        <button
          @click="$emit('setFullScreen', true)"
          v-b-popover.hover.top="'Fullscreen'"
          class="mapStatusBarElement mapStatusBarIcon"
        >
          <font-awesome-icon icon="expand-arrows-alt" />
        </button>
      </div>
    </div>
  </portal>
</template>

<script>
import DropdownSelect from "./shared/DropdownSelect.vue";
import CompactColorPicker from "./shared/ColorPicker/CompactColorPicker.vue";
import { rgbToHex, hexToRgb } from "./shared/ColorPicker/CompactColorPicker.vue";
import OsGridRef, { LatLon, Dms } from "./util/geodesy/osgridref.js";
import EditableText from "./shared/EditableText.vue";
import {
  agsPointToCoordString,
  coordStringToAgsPoint,
  getGeoLocationCurrentPosition,
  isValidCoordString,
  latLonToAgsPoint
} from "./SpatialUtils.js";
import { loadModules } from "esri-loader";
import { default as FeatureMixin } from "./FeatureMixin.vue";

export default {
  components: { DropdownSelect, CompactColorPicker, EditableText },
  mixins: [FeatureMixin],
  props: {
    editingCoords: false,
    selectionOutlineColourProp: {
      type: Array,
      required: true,
    },
    availableScales: [],    
    scalebarVisible: {
      type: Boolean,
      required: true,
    },
    compassVisible: {
      type: Boolean,
      required: true,
    },
    navigationVisible: {
      type: Boolean,
      required: true,
    },
    overviewVisible: {
      type: Boolean,
      required: true,
    },
    coordinateMode: {
      type: String,
      required: true,
    },
    is3D: {
      type: Boolean,
      required: true,
    },
    locationPin: {
      type: Object,
    },
    searchExtent: {
      type: Object,
    }
  },
  data() {
    return {      
      pointerCoords: "",
      selectionOutlineColourPickerShow: false,
      selectionOutlineColour: "#ffffff",
      coordinateModeData: null,      
      AgsPoint: null,
      coords: "",      
    };
  },
  created() {
    loadModules(["esri/geometry/Point"]).then(([Point]) => {
      this.AgsPoint = Point;
    });

    if (this.mapView) {  
      // Dont use reactivity so mouse move doesnt reset save timeout (globaMixin.vue).
      this.mapView.on("pointer-move", this.pointerMove);

      this.mapView.watch(["scale", "rotation", "updating"], (newValue, oldValue, propertyName) => {            
            
        if (this.mapView.animation && this.mapView.animation.state ==="running") {      
          return;        
        }
        
        this.$forceUpdate();        
      })
    }

    this.coordinateModeData = this.coordinateMode;
  }, 
  methods: {
    scaleChanged(e) {      
      this.$emit("goto", {scale: e});          
    },

    gotoLocationMarker() {
      if (this.locationPin) {
        this.$emit("goto", this.locationPin.geometry);
      }
    },

    coordinatesChanged(coordString) {
      if (coordString === "") {
        this.$emit("pinLocation", null);
      } else if (isValidCoordString(coordString, this.coordinateMode)) {
        let mapCoords = coordStringToAgsPoint(
          this.AgsPoint,
          coordString,
          this.mapView.spatialReference,
          this.coordinateMode === "xyNonPrecise" ? "xy" : this.coordinateMode
        );
        this.$emit("goto", mapCoords);
        this.$emit("pinLocation", mapCoords);
      }
    },
    
    fixCoordinates(coordString){
      if(coordString!=="" && this.coordinateMode!=="gridref"){
        coordString = coordString.split(/[\t ,]+/).join(', ').replace(/[\n\r]+/g, '').trim();
      }
      return coordString
    },
    validateXCoordinate(xToCheck){
        let pointerCoordinates = this.coords.split(",");
        let validX = false;
        let usePointerY = pointerCoordinates[1];
        xToCheck = xToCheck.replace(/[ ,]+/g,'').trim();
        let contrivedCoordinates = xToCheck + ", " + usePointerY;
        if(isValidCoordString(contrivedCoordinates,this.coordinateModeData) && this.withinBoundaryExtent(contrivedCoordinates)){
          validX = true;
        } else {
          validX = false;
        }
      return validX;
    },

    withinBoundaryExtent(contrivedCoordinates){
      let checkAgsPoint = coordStringToAgsPoint(
          this.AgsPoint,
          contrivedCoordinates,
          this.mapView.spatialReference,
          this.coordinateMode === "xyNonPrecise" ? "xy" : this.coordinateMode
      );

      return this.searchExtent.contains(checkAgsPoint);
    },
    selectionOutlineColourChange(e) {
      this.selectionOutlineColourPickerShow = false;
      let colour = [e.rgba.r, e.rgba.g, e.rgba.b, 1];

      this.$emit("selectionOutlineColourChange", colour);
    },
    pointerMove(e) {			
			// Dont use reactivity so mouse move doesnt reset save timeout (globaMixin.vue).      
      this.lastPointerPos = this.mapView.toMap(e);
      this.renderPoint();
    },
    renderPoint(){
      if (this.lastPointerPos !== null) {
        try {
				  this.coords = agsPointToCoordString(this.lastPointerPos, this.coordinateModeData);
        } catch (e) {
          return;
        }
        let el = this.$refs.pointerCoords;
        el.innerHTML = this.coords;
      }
    }
  },
  computed: {
    availableScaleItems() {
      return this.availableScales.map((scale) => {
        return { text: scale.toString(), value: scale };
      });
    },
    coordinateModeItems() {
      return [        
        { text: "Easting, Northing", value: "xyNonPrecise" },
        { text: "Easting, Northing (precise)", value: "xy", selectedText: "Easting, Northing" },
        { text: "Lat, Lon", value: "latlon" },
        { text: "Grid Ref", value: "gridref" },
      ];
    },    

    pinCoordinates() {
      if (this.locationPin) {
        return this.getLocationString(
          this.locationPin,
          this.coordinateModeData
        );
      }
    },

    gotoPlaceHolder() {
      return "Goto...";
    },

    gotoEditingPlaceHolder() {
      if (this.coordinateModeData === "xy") {
        return "e.g. 624017.000, 307071.000";
      }

      if (this.coordinateModeData === "xyNonPrecise") {
        return "e.g. 624017, 307071";
      }

      if (this.coordinateModeData === "latlon") {
        return "e.g. 52.6899° N, 001.4732° E";
      }

      if (this.coordinateModeData === "gridref") {
        return "e.g. TG 3482 1590";
      }
    },
  },
  watch: {
    selectionOutlineColourProp(value) {
      let hexColour = rgbToHex(value[0], value[1], value[2]);
      this.selectionOutlineColour = hexColour;
    },
    coordinateMode(value) {
      this.coordinateModeData = value;
      this.renderPoint();
    },
    coordinateModeData(value) {
      this.$emit("coordinateModeChanged", value);
    },
  },
};
</script>

<style lang="scss">
@import "./../variables";

.mapStatusBar {
  background: darken($panelColour, 3%);
  /*opacity: 0.8;*/
  color: $textColour;
  font-size: $fontSizeSm;
  height: 28px;
  padding: 0 8px;
  /*padding:2px;*/

  #selectionColourButton {
    border-radius: 0.2rem;
    border-color: black;
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }

  /*  .markerLocation {
        width: 160px;
    } */
}
.elementMargin {
  margin: 0 6px;
}

.mapStatusBar button {
  font-size: inherit;
  background-color: inherit;
  border: 0;
  padding: 4px 4px;
  color: $textColour;

  &:hover {
    color: $textColourLight;
  }
}

.mapStatusBarSeperator {
  width: 0px;
  height: 18px;
  border-width: 1px;
  border-color: $controlSeperator;
  border-style: none none none solid;
}

button.mapStatusBarIcon {
  padding: 4px 10px;
  margin-left: 5px;
}
button.mapStatusBarIconToggled {
  color: #4fc3f7 !important;
}
.dropDownIconButton {
  margin-left: 5px;
}
.dropDownIcon {
  padding: 4px 8px;
}
.spinnerUpdating {
  --fa-primary-color: #4fc3f7;
  --fa-secondary-color: dimgray;
}
.spinnerNotUpdating {
  // visibility: hidden;
  --fa-primary-color: #444;
  --fa-secondary-color: #666;
}

.spinnerHidden {
  visibility: hidden;
}

#selectionColourPopover > .popover-body {
  padding: 0.1rem 0.1rem 0.2rem 0.2rem;
  background: $panelContent;
  color: $panelContent;
}
#selectionColourPopover > .arrow::after {
  border-top-color: $panelContent;
}
</style>
