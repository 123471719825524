import axios from 'axios';

class OSPlaces {		

	constructor(endpoint, msal, scope) {	
		this.msal = msal;
    this.config = {
     endpoint: endpoint,     
      requestObject:{
        scopes: [scope]
      }     
    }
	}

	async find(text, datasets, extraNCCFields, formattedValues, maxresults, minmatch) {				

		let accessToken;
		
		if (this.msal)
			accessToken = await this.msal.getToken(this.config.requestObject);    

		let headers;

		if (accessToken) {
			headers = {
				Authorization: accessToken ? "Bearer " + accessToken : undefined
			}
		}		

		let params = {
			query: text,
			ncc: extraNCCFields ? extraNCCFields.join() : undefined,
			maxresults: maxresults ? maxresults : undefined,
			minmatch: minmatch ? minmatch : undefined,
			dataset: datasets ? datasets.join() : undefined,
			formattedValues : formattedValues ? true : undefined
		}

		text = text.trim();		
		let response = await axios.get(this.config.endpoint + '/find', {
			headers : headers ? headers : undefined,
			params: params
		})
		
		return response.data.results ? response.data.results : [];		
	}
}   

export { OSPlaces };