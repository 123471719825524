<script>

import semver from "semver";

var appPersistance = {
  methods: {         
    
    getState(type) {  
      let state;

      if (type === "device") {
        state = {
          whenStateCreated: new Date(),
          version: this.$appGlobals.appVersion,      
          menus: this.menus,
          leftMenuShown: this.leftMenuShown,
          topMenuShown: this.topMenuShown,
          rightMenuShown: this.rightMenuShown,
          bottomMenuShown: this.bottomMenuShown,        
          isFullScreen: this.isFullScreen,          
          mappingBrowser: this.$refs.mappingBrowser.getState(type),              
        }      
      } else if (type === "project") {
        state = {          
          basemaps: this.basemaps.map(basemap => {
            return {
              id: basemap.id,          
              opacity: basemap.opacity,
              visible: basemap.visible
            }
          }),
          mappingBrowser: this.$refs.mappingBrowser.getState(type),                    
          showWhatsNew4: this.showWhatsNew
        }      
      }
      
      state.version = this.$appGlobals.appVersion      
      
      return state;
    },        

    async restoreState(type, fromVersion, options, state) {                 

      if (type === "project") {
        state.basemaps.forEach(basemapState => {
          let basemap = this.basemaps.find(b => b.id === basemapState.id);
          if (basemap) {
            basemap.opacity = basemapState.opacity;
            basemap.visible = basemapState.visible;           
          }
        })

        if (state.exportTemplate) this.exportTemplate = state.exportTemplate;
        if (state.exportLayout) this.exportLayout = state.exportLayout;
        if (state.exportFormat) this.exportFormat = state.exportFormat;
        if (state.exportTitle) this.exportTitle = state.exportTitle;      
              
        await this.$refs.mappingBrowser.restoreState(type, fromVersion, options, state.mappingBrowser);         

        if (state.showWhatsNew4 !== undefined) {
          this.showWhatsNew = state.showWhatsNew4;
        }            
        
      } else if (type === "device") {
        if (state.menus) {
          this.menus = state.menus;           
        }

        this.$nextTick(() => { 
          this.leftMenuShown = state.leftMenuShown;
          this.topMenuShown = state.topMenuShown;
          this.rightMenuShown = state.rightMenuShown;
          this.bottomMenuShown = state.bottomMenuShown;
          this.isFullScreen = state.isFullScreen;

          this.updateMenuItems();
        });                

        await this.$refs.mappingBrowser.restoreState(type, fromVersion, options, state.mappingBrowser);         
      }         
    },        
  }
};

export default appPersistance;

</script>