<template>  
  <div 
  id="projectMenu" 
  tabIndex="0"
  class="h-100 position-fixed"
  @focusout="onfocusout"  
  >    
    <div class="h-100 d-flex flex-column">        
      <div class="header d-flex">        
        <b-button 
        id="headerIconButton" 
        ref="projectButton"
        @click="$emit('close')">
          <img height="24" width="24" class="rounded-circle" src="@/assets/icon24x24.png" alt="Image 1"/>
        </b-button>      
        <b-button    
        class="ml-auto"       
        @click="$emit('close')">
          <font-awesome-icon :icon="['fal', 'times']" transform="grow-4"/>
        </b-button>              
      </div>    
      <div class="d-flex flex-grow-1">
        <div class="d-flex flex-column menuOptions">
           <b-button class="d-flex" @click="$emit('exportPresented')">           
            <span class="ml-2">Export presented map (.pdf, .png, ...etc)</span>
          </b-button>      
          <b-button class="d-flex" @click="$emit('exportScreenshot')">           
            <span class="ml-2">Export map screenshot (.png)</span>
          </b-button>      
          <b-button class="d-flex" @click="$emit('exportProjectFile')">           
            <span class="ml-2">Export Mapping Browser Project (.mbp) file</span>
          </b-button>             
        </div>                    
      </div>
    </div>  
  </div>
</template>

<script>

export default {         
  mounted() {
    this.$refs.projectButton.focus();
  },
  methods: {
    focusInCurrentTarget(relatedTarget, currentTarget) {
        if (relatedTarget === null) return false;

        var node = relatedTarget;

        while (node !== null) {
            if (node === currentTarget) return true;
            node = node.parentNode;
        }

        return false;
    },

    onfocusout(event) {

      if (!this.focusInCurrentTarget(event.relatedTarget, event.currentTarget)) {
        this.$emit('close');       
      }
    }
  }
}

</script>

<style lang="scss" scoped>
@import "./../variables";

$topBarSize: 48px;

#projectMenu {  
  width: 400px;
  font-family: $fontFamily;

  button {
    margin: 0px;
    border: none;
    background-color: inherit;
    color: $projectMenuButtonTextColour;

    &:hover {
       color: $projectMenuButtonTextHoverColour;
       background-color: $projectMenuButtonHoverBackgroundColour;
    }
  }  

  #headerIconButton
  {    
    font-size:1.2rem !important;    
  }

  .closeButton, #headerIconButton{    

    width:$topBarSize;
    height:$topBarSize;        
  }

  .header, .menuOptions {
    background-color: $projectMenuBackgroundColour;
    width: 100%;
  }

  .headerRight, .content {
    background-color: $projectMenuContentBackgroundColour;
  }
}

</style>