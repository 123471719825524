<template> 
  <div class="d-flex flex-column">
    <div 
    class="tabs noSelect d-flex py-1 px-2 flex-shrink-0"
    :style="{ height: height + 'px'}">
      <template v-for="(tab, index) in tabs"> 
        <button  class="tabButton p-2 flex-fill text-center"
        :key="index"
        @click="click(tab)" 
        :class="{active: activeTabSlot === tab.slot}">
        <template v-if="tab.icon">
          <font-awesome-icon class="mr-1" :icon="tab.icon"/>
        </template>
        <span class="p-2">{{tab.label}}</span>   
        </button>
      </template>
    </div>    
    <template v-for="tab in tabs">                   
      <div :key="tab.slot" class="h-100 min-height-0 mt-1" v-show="activeTabSlot === tab.slot">
        <slot :name="tab.slot"></slot>
      </div>      
    </template>            
  </div>  
</template>

<script>

export default {
  props: { 
    height: {
      type: Number,
      default: 48
    },
		tabs: {
      type: Array,      
    },        
    value: {
      type: String,
      required: true
    }
  },   
  mounted() {
    this.activeTabSlot = this.value;
  },
  watch: {
    value(value) {
      this.activeTabSlot = value;
    }
  },
  methods:{
    click(tab){
      this.$emit('input', tab.slot);
    }
  },   
  data() {
    return {
      activeTabSlot : ""
    }
  },  
}
  
</script>

<style lang="scss" scoped>
@import "../../variables";

.tabs{
  background-color: $panelColour;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */  
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome and Opera */  
}

.tabButton {
  text-transform: uppercase;
  font-size: $fontSize;
  border:none;
  border-bottom:2px solid transparent;
  background-color: transparent; 
  color:$textColour;
  &.active {
    color: #fff;
    border-bottom:2px solid $pColour;
  }
}
.tabButton:hover{
  background-color: transparent; 
  color:$textColourHover;
}

</style>