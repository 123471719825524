<template>  
  <div class="symbolProperties">  
    <template v-if="symbol.type === 'simple-marker'">               
      <div class="d-flex mt-2 groupPanel">
        <div class="d-flex flex-column options labels">
          <span>Fill colour</span>    
          <span>Border colour</span>    
          <span>Size</span>              
          <template v-if="!noOpacity">
            <span>Opacity</span>
          </template>
        </div>  
        <div class="d-flex flex-column flex-grow-1 options">
          <span>
            <b-button class="colorPickerButton" :style="{background: fillColor}" size="sm" :id="_uid + 'simple-marker-fillColor'"></b-button>
            <b-popover
            :show.sync="fillColorPickerShow" 
            :target="_uid + 'simple-marker-fillColor'"
            placement="left"
            triggers="click blur">                  
              <CompactColorPicker :value="fillColor" @input="fillColorChanged"></CompactColorPicker>                  
            </b-popover>
          </span>       
          <span>
            <b-button class="colorPickerButton" :style="{background: outlineColor}" size="sm" :id="_uid + 'simple-marker-outlineColor'"></b-button>
            <b-popover
            :show.sync="outlineColorPickerShow" 
            :target="_uid + 'simple-marker-outlineColor'"
            placement="left"
            triggers="click blur">                  
              <CompactColorPicker :value="outlineColor" @input="outlineColorChanged"></CompactColorPicker>                  
            </b-popover>
          </span>      
          <span><vue-slider class="vueSlider" v-model="symbol.size" :duration="0.125" :min="1" :max="24" :hideLabel="true"/></span>          
          <template v-if="!noOpacity">
            <span><vue-slider class="vueSlider" :duration="0.125" :value="symbol.color[3] * 100" @change="$set(symbol.color, 3, $event / 100)" :tooltip-formatter="sliderPercentageFormatter"  /></span>
          </template>
        </div>  
      </div>                         
    </template>                  
    <template v-if="symbol.type === 'simple-fill'">
      <div class="d-flex align-items-center">      
        <template v-if="!noFillOnOff">
          <b-form-checkbox                    
          v-model="symbol.showFill"  
          :disabled="mustHaveFillOrBorder && symbol.showFill && (symbol.showOutline !== undefined) && (!symbol.showOutline)"              
          >            
          </b-form-checkbox>          
        </template>
        <span>Fill</span>        
      </div>              
      <template v-if="symbol.showFill">
        <div class="d-flex mt-2 groupPanel">
          <div class="d-flex flex-column options labels">
            <span>Colour</span>
            <template v-if="!noStyle">
              <span>Style</span>
            </template>  
            <template v-if="!noOpacity">
              <span>Opacity</span>
            </template>
          </div>  
          <div class="d-flex flex-column flex-grow-1 options">
            <span>
              <b-button class="colorPickerButton" :style="{background: fillColor}" size="sm" :id="_uid + 'simple-fill-fillColor'"></b-button>
              <b-popover
              :show.sync="fillColorPickerShow" 
              :target="_uid + 'simple-fill-fillColor'"
              placement="left"
              triggers="click blur">                  
                <CompactColorPicker :value="fillColor" @input="fillColorChanged"></CompactColorPicker>                  
              </b-popover>
            </span>
            <template v-if="!noStyle">
              <span class="d-inline-flex">
                <DropdownSelect 
                class="flex-grow-1" 
                v-model="symbol.style"                              
                :items="symbol.outline ? fillStyleItems : outlineStyleItems">
                </DropdownSelect>
              </span>
            </template>
            <template v-if="!noOpacity">
              <span><vue-slider class="vueSlider" :duration="0.125" :value="symbol.color[3] * 100" @change="$set(symbol.color, 3, $event / 100)" :tooltip-formatter="sliderPercentageFormatter"  /></span>
            </template>
          </div>  
        </div>            
      </template>
      <div class="mt-2 d-flex align-items-center">
        <template v-if="!noOutline">
          <template v-if="symbol.outline">
            <template v-if="!noOutlineOnOff">
              <b-form-checkbox          
              v-model="symbol.showOutline"  
              :disabled="mustHaveFillOrBorder && symbol.showOutline && (symbol.showFill !== undefined) && (!symbol.showFill)"            
              >                          
              </b-form-checkbox>    
            </template>
            <span>Border</span>
          </template>
        </template>
      </div>       
      <template v-if="!noOutline">
        <template v-if="symbol.showOutline">
          <div class="d-flex mt-2 groupPanel">
            <div class="d-flex flex-column options labels">
              <span>Colour</span>
              <template v-if="!noOutlineStyle">
                <span>Style</span>
              </template>
              <span>Size</span>            
              <template v-if="!noOutlineOpacity">
                <span>Opacity</span>              
              </template>
            </div>  
            <div class="d-flex flex-column flex-grow-1 options">
              <span>
                <b-button class="colorPickerButton" :style="{background: outlineColor}" size="sm" :id="_uid + 'simple-fill-outlineColor'"></b-button>
                <b-popover
                :show.sync="outlineColorPickerShow" 
                :target="_uid + 'simple-fill-outlineColor'"
                placement="left"
                triggers="click blur">                  
                  <CompactColorPicker :value="outlineColor" @input="outlineColorChanged"></CompactColorPicker>                  
                </b-popover>
              </span>
              <template v-if="!noOutlineStyle">
                <span class="d-inline-flex"><DropdownSelect class="flex-grow-1" v-model="symbol.outline.style" :items="outlineStyleItems"></DropdownSelect></span>
              </template>
              <span><vue-slider class="vueSlider" v-model="symbol.outline.width" :duration="0.125" :min="1" :max="10" :hideLabel="true"  :marks="true" /></span>
              <template v-if="!noOutlineOpacity">
                <span><vue-slider class="vueSlider" :duration="0.125" :value="symbol.outline.color[3] * 100" @change="$set(symbol.outline.color, 3, $event / 100)" :tooltip-formatter="sliderPercentageFormatter"  /></span>
              </template>
            </div>  
          </div>            
        </template>
      </template>        
    </template>    
    <template v-if="symbol.type === 'simple-line'">
      <div class="d-flex mt-2 groupPanel">
        <div class="d-flex flex-column options labels">
          <span>Colour</span>
          <template v-if="!noStyle">
            <span>Style</span>
          </template>
          <span>Size</span>
          <template v-if="!noOpacity">
            <span>Opacity</span>
          </template>
        </div>  
        <div class="d-flex flex-column flex-grow-1 options">
          <span>
            <b-button class="colorPickerButton" :style="{background: fillColor}" size="sm" :id="_uid + 'simple-line-fillColor'"></b-button>
            <b-popover
            :show.sync="fillColorPickerShow" 
            :target="_uid + 'simple-line-fillColor'"
            placement="left"
            triggers="click blur">                  
              <CompactColorPicker :value="fillColor" @input="fillColorChanged"></CompactColorPicker>                  
            </b-popover>
          </span>
          <template v-if="!noStyle">
            <span class="d-inline-flex">
              <DropdownSelect 
              class="flex-grow-1" 
              v-model="symbol.style"                              
              :items="outlineStyleItems">
              </DropdownSelect>
            </span>
          </template>
          <span><vue-slider class="vueSlider" v-model="symbol.width" :duration="0.125" :min="1" :max="10" :hideLabel="true"  :marks="true" /></span>
          <template v-if="!noOpacity">
            <span><vue-slider class="vueSlider" :duration="0.125" :value="symbol.color[3] * 100" @change="$set(symbol.color, 3, $event / 100)" :tooltip-formatter="sliderPercentageFormatter"  /></span>            
          </template>
        </div>  
      </div>            
    </template>      
    <template v-if="symbol.type === 'text'">
      <template v-if="!noText">
      <div class="d-flex mt-2">        
        <div class="d-flex flex-column w-100">              
          <span>Text</span>                
          <template v-if="textRowCount === 1">
            <span ref="text" class="d-inline-flex"><b-input :value="symbol.text" @input="textChanged"></b-input></span>   
          </template>           
          <template v-else>
            <b-form-textarea
            ref="multiText"       
            :value="symbol.text"
            class="mt-2"                
            id="textarea"
            no-resize            
            @input="textChanged"
            :rows="textRowCount"            
            ></b-form-textarea>                
          </template>      
        </div>                      
      </div>     
      </template>
      <div class="d-flex mt-2 groupPanel">
        <div class="d-flex flex-column options labels">                             
          <template v-if="!noSize">
            <span>Size</span>        
          </template>
          <span>Weight</span>
          <span>Colour</span>
          <span>Halo Colour</span>
          <span>Halo Size</span>
          <template v-if="!noOpacity">
            <span>Opacity</span>
          </template>
          <template v-if="!noAngle">
            <span>Angle</span>     
          </template>
        </div>  
        <div class="d-flex flex-column flex-grow-1 options">                            
          <template v-if="!noSize">
            <span><vue-slider class="vueSlider" v-model="symbol.font.size" :min="10" :max="maxFontSize" :hideLabel="true" :marks="true" /></span>          
          </template>
          <span class="d-inline-flex"><DropdownSelect class="flex-grow-1" v-model="symbol.font.weight" :items="textWeightItems"></DropdownSelect></span>          
            <span>
            <b-button class="colorPickerButton" :style="{background: fillColor}" size="sm" :id="_uid + 'text-fillColor'"></b-button>
            <b-popover
            :show.sync="fillColorPickerShow" 
            :target="_uid + 'text-fillColor'"
            placement="left"
            triggers="click blur">                  
              <CompactColorPicker :value="fillColor" @input="fillColorChanged"></CompactColorPicker>                  
            </b-popover>
          </span>
          <span>
            <b-button class="colorPickerButton" :style="{background: haloColor}" size="sm" :id="_uid + 'text-haloColor'"></b-button>
            <b-popover
            :show.sync="haloColorPickerShow" 
            :target="_uid + 'text-haloColor'"
            placement="left"
            triggers="click blur">                  
              <CompactColorPicker :value="haloColor" @input="haloColorChanged"></CompactColorPicker>                  
            </b-popover>
          </span>
          <span><vue-slider class="vueSlider" :min="0" :max="8" :duration="0.125" v-model="symbol.haloSize"/></span>   
          <template v-if="!noOpacity">
            <span><vue-slider class="vueSlider" :duration="0.125" :value="symbol.color[3] * 100" @change="opacityChanged" :tooltip-formatter="sliderPercentageFormatter"/></span>   
          </template>
          <template v-if="!noAngle">
            <span class="mb-3"><vue-slider class="vueSlider" :marks="angleMarks" :duration="0.125" v-model="symbol.angle" :min="-180" :max="180" :tooltip-formatter="degreesFormatter"/></span>   
          </template>
        </div>                            
      </div>
    </template>
  </div>         
</template>

<script>
import CompactColorPicker from "./shared/ColorPicker/CompactColorPicker.vue";
import {rgbToHex, hexToRgb}  from "./shared/ColorPicker/CompactColorPicker.vue";
import DropdownSelect from "./shared/DropdownSelect.vue";
import VueSlider from 'vue-slider-component'

export default {
  components: { CompactColorPicker, DropdownSelect, VueSlider },   
  props: {      
    maxFontSize: {
      type: Number,
      default: 100
    },
    noFillOnOff: {
      type: Boolean,
      default: false
    },
    noOutlineOnOff: {
      type: Boolean,
      default: false
    },
    textRowCount: {
      default: 1,
      type: Number
    },
    mustHaveFillOrBorder: {
      type: Boolean,
      default: false
    },
    symbol: {
      type: Object
    },       
    noAngle: {
      type: Boolean,
      default: false
    },
    noOutlineOpacity: {
      type: Boolean,
      default: false
    },
    noOutlineStyle: {
      type: Boolean,
      default: false
    },
    noOpacity: {
      type: Boolean,
      default: false
    },
    noOutline: {
      type: Boolean,
      default: false
    },
    noStyle: {
      type: Boolean,
      default: false
    },
    noText: {
      type: Boolean,
      default: false
    },
    noSize: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {                                  
      fillColorPickerShow: false,          
      outlineColorPickerShow: false,      
      textColorPickerShow: false,
      haloColorPickerShow: false,
      
      textStyleItems: [
          {text: "Normal", value:"normal"},
          {text: "Italic", value:"italic"},
          {text: "Oblique", value:"oblique"},
      ],
                  
      outlineStyleItems:[
          {text: "Dash", value:"dash"},
          {text: "Dash-Dot", value:"dash-dot"},
          {text: "Dot", value:"dot"},
          {text: "Solid", value:"solid"},                
      ],

      fillStyleItems:[                            
          {text: "Diagonal", value:"backward-diagonal"},
          {text: "Horizontal", value:"horizontal"},                
          {text: "Solid", value:"solid"},
          {text: "Vertical", value:"vertical"},
      ],

      textWeightItems:[          
          {text: "Normal", value:"normal"},
          {text: "Bold", value:"bold"},          
      ],       

      sliderPercentageFormatter: '{value}%',
      degreesFormatter: '{value}°',    
    }
  },
  computed: {
    angleMarks() {
      return {
        '-90' : '-90°',
        '0' : '0°',
        '90' : '90°',
      }
    },
    fillColor() {      
      return rgbToHex(this.symbol.color[0], 
                      this.symbol.color[1], 
                      this.symbol.color[2], 
                      this.symbol.color[3]);      
    },

    haloColor() {      
      return rgbToHex(this.symbol.haloColor[0], 
                      this.symbol.haloColor[1], 
                      this.symbol.haloColor[2], 
                      this.symbol.haloColor[3]);      
    },

    outlineColor() {      
      return rgbToHex(this.symbol.outline.color[0], 
                      this.symbol.outline.color[1], 
                      this.symbol.outline.color[2], 
                      this.symbol.outline.color[3]);      
    }
  },

  methods: {    

    textChanged(text) {
      let newText = text.replace(/^\s+|\s+$/g, '');      
      this.symbol.text = newText.length > 0 ? newText : "text"
    },

    autoFocusText() {      
      let ref = this.textRowCount === 1 ? "text" : "multiText";  
      this.whenRefReady(ref, (el) => {        
        el.focus();
        el.select();
      });      
    },

    opacityChanged(opacity) {
      this.$set(this.symbol.color, 3, opacity / 100);
      this.$set(this.symbol.haloColor, 3, opacity / 100);
    },
  
    fillColorChanged(e) {      
      this.fillColorPickerShow = false;      
      let colour = [e.rgba.r, e.rgba.g, e.rgba.b, this.symbol.color[3]];
      this.symbol.color = colour;
    },  

    haloColorChanged(e) {      
      this.haloColorPickerShow = false;      
      let colour = [e.rgba.r, e.rgba.g, e.rgba.b, this.symbol.haloColor[3]];
      this.symbol.haloColor = colour;
    },  
    
    outlineColorChanged(e) {
      this.outlineColorPickerShow = false;      
      let colour = [e.rgba.r, e.rgba.g, e.rgba.b, this.symbol.outline.color[3]];
      this.symbol.outline.color = colour;
    },      
  }
}

</script>

<style lang="scss" scoped>
@import "./../variables";

.symbolProperties {

  color: $textColourLight;  

  .groupPanel{
    background-color:$panelColourLight;
    padding: 1rem;
  }  

  .options > span{
    display: grid;
    align-items: center;
    height:1.8rem;
  }
  
  .options > span:not(:first-child){
    margin-top:1.5rem;
  }

  .labels{
    font-size: $fontSize;
    flex-basis: 6rem;
    color: $textColour;
  }

  .vueSlider{
    margin-right:0.2rem;
    margin-left:0.2rem;
  }

  .colorPickerButton{      
    width:1.5rem;
    height:1.5rem;
    border-radius: 0.5rem;
    margin-right:6px;
    
    border:2px solid $panelColourSeperator
  } 
}

</style>