<template> 
  <div>        
    <b-modal id="layerImport"
    ref="LayerImport"
    :ok-disabled="!isValid"    
    @shown="layerImportShown" 
    @ok="okButtonClicked" 
    @hidden="layerImportHidden"         
    centered>     
      <template slot="modal-title">      
        <div class="d-flex justify-content-center align-items-center">          
          <font-awesome-icon :icon="['fas', 'plus']" class="mr-2"/>Import into new layer from .xls file
        </div> 
      </template>          
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column">
          <div class="d-flex my-2">        
            <b-popover           
            :id="_uid + 'nameLabelPopover'"
            triggers="manual"       
            placement="top"            
            :target="_uid + 'nameLabel'"          
            > 
            {{invalidMessage}}   
            </b-popover>
            <span :id="_uid + 'nameLabel'" class="d-flex align-items-center" :style="{'width': '130px'}">Layer name:</span>            
            <span id = "layerNameValue"><b-form-input ref="nameInput" v-model="name" @keyup.enter="enterButton"/></span>            
          </div>         
          <div class="d-flex mt-1">        
            <span class="d-flex align-items-center" :style="{'width': '140px'}">File:</span>        
            <template v-if="file">
              <span id="fileValue">{{file.name}}</span>  
            </template>
          </div>              
          <template v-if="workBook && workBook.SheetNames.length > 1">
            <div class="d-flex mt-2">    
              <span class="d-flex align-items-center" :style="{'width': '140px'}">Work sheet:</span>            
              <span class="d-flex"><DropdownSelect id="worksheetDrop" class="flex-grow-1 value" noItemsSelectedText="Select..." v-model="workSheet" :items="workSheetItems"></DropdownSelect></span>
            </div>
          </template>
          <template v-if="workSheet">
            <div class="d-flex my-2">     
              <span class="d-flex align-items-center" :style="{'width': '140px'}">Input coord type:</span>            
              <span class="d-flex"><DropdownSelect id="coordTypeDrop" class="flex-grow-1 value"  v-model="coordType" :items="coordTypeItems"></DropdownSelect></span>          
            </div>
            <div class="d-flex mb-2">     
              <span class="d-flex align-items-center" :style="{'width': '140px'}">{{col1Label}}</span>            
              <span class="d-flex"><DropdownSelect id="coordCol1" class="flex-grow-1 value" noItemsSelectedText="Select..." v-model="coordCol1" :items="col1Items"></DropdownSelect></span>          
            </div>
            <template v-if="coordType !== 'gridref'">          
              <div class="d-flex ">     
                <span class="d-flex align-items-center" :style="{'width': '140px'}">{{col2Label}}</span>            
                <span class="d-flex"><DropdownSelect id="coordCol2" class="flex-grow-1 value" noItemsSelectedText="Select..." v-model="coordCol2" :items="col2Items"></DropdownSelect></span>          
              </div>
            </template>          
          </template>
        </div>              
      </div>
    </b-modal>   
  </div>     
</template>

<script>

import ErrorDialog from './shared/ErrorDialog.vue';
import DropdownSelect from "./shared/DropdownSelect.vue";
import { coordStringToAgsPoint, isValidCoordString } from "./SpatialUtils.js";
import * as XLSX from 'xlsx';
import Vue from "vue";

export default {	
  components: { ErrorDialog, DropdownSelect },   
  props: {       
    agsPoint: {
      type: Function
    },
    invalidMessageFn: {
      type: Function
    },
    file: {
      type: File
    },
    defaults: {
      type: Object
    },
    defaultCoordType: {
      type: String
    }
  },    
  data() {
    return {            
      name: "",   
      coordType: "xy",
      columns: [],
      workBook: null,
      workSheet: null,
      coordCol1: "",
      coordCol2: "",
    }
  },        
  watch: {
    file(newVal, oldVal) {                
      if (this.file) {
        this.init();
      }
    },    
    workSheet() {
      if (this.workSheet) {
        let keys = Object.keys(this.workSheet)

        let regEx = new RegExp("[A-Z]+1$");      
              
        this.columns = keys.filter(key => regEx.test(key));
      
        let coordCols = this.getCoordColumns(this.columns);            

        if (coordCols) {
          this.coordType = coordCols.type;
          this.coordCol1 = this.workSheet[coordCols.col1].w

          if (coordCols.col2) {
            this.coordCol2 = this.workSheet[coordCols.col2].w
          }          
        }
      }
    },
    invalidMessage() {
      if (this.invalidMessage) {
        this.$root.$emit('bv::show::popover', this._uid + 'nameLabelPopover')
      } else {
        this.$root.$emit('bv::hide::popover', this._uid + 'nameLabelPopover')
      }
    }
  },
  computed: { 
    isValid() {      

      if (this.invalidMessage !== null) {
        return;
      }      

      if (!this.workSheet || !this.coordCol1)
        return false;


      if ((this.coordType === "latlon" || this.coordType === "xy") && !this.coordCol2) {
        return false;
      }

      return true;

    },
    col1Label() {
      if (this.coordType === "xy") {
        return "Easting column:"
      }
      if (this.coordType === "latlon") {
        return "Latitude column:"
      }
      if (this.coordType === "gridref") {
        return "Grid Ref column:"
      }
    },

    col2Label() {
      if (this.coordType === "xy") {
        return "Northing column:"
      }
      if (this.coordType === "latlon") {
        return "Longitude column:"
      }      
    },

    invalidMessage() {            
      return (this.invalidMessageFn(
        null, this.name        
      ))
    },    

    workSheetItems() {
      if (this.workBook) {        
        return Object.values(this.workBook.Sheets).map((sheet, i) => {
          return {
            text: this.workBook.SheetNames[i], 
            value: sheet
          }
        })
      }

      return [];
    },    

    coordTypeItems() {
      return [        
        { text: "Easting, Northing", value: "xy" },   
        { text: "Lat, Lon", value: "latlon" },
        { text: "Grid Ref", value: "gridref" },
      ];
    },    

    col1Items() {
      if (this.columns) {
        let columns;

        if (this.coordType !== "gridref") {
          columns = this.columns.filter(col => (this.coordCol2 !== this.workSheet[col].w));
        }
        else
        {
          columns = this.columns;
        }
        
        return columns.map(column => {
          return {
            text: this.workSheet[column].w, value: this.workSheet[column].w
          }
        })
      }
    },

    col2Items() {
      if (this.columns) {

        let columns;

        if (this.coordType !== "gridref") {
          columns = this.columns.filter(col => (this.coordCol1 !== this.workSheet[col].w));
        }
        else
        {
          columns = this.columns;
        }

         return columns.map(column => {
          return {
            text: this.workSheet[column].w, value: this.workSheet[column].w
          }
        })
      }      
    },
  },
  methods: { 
    importLayer(workSheet, layerName, coordType, col1, col2) {            

      let json = XLSX.utils.sheet_to_json(workSheet);                  

      if (json.length === 0) {
        throw new Error("No rows found");
      }

      let features = [];            

      let maxFeatures = 2000;
      if (json.length > maxFeatures) {
        throw new Error("Too many rows.  Import is limited to 2000 features.");
      }      

      let fields = {};

      for (let rowIndex = 0; rowIndex < json.length; rowIndex++) {
        let fileRowIndex = rowIndex + 2;
        let row = json[rowIndex];
        let columnKeys = Object.keys(row);        
        
        let defaults = this.defaults.systemFeature;
        let feature = JSON.parse(JSON.stringify(defaults));          

        this.$set(feature, "symbolJSON", "");        
        this.$set(feature, "geometryJSON", "");

        let coord1, coord2;        

        columnKeys.forEach(columnKey => {                              
        
          // Location field?
          let val = row[columnKey];          
          
          if (columnKey === col1) {
            coord1 = val;            
          } else if (col2 && (columnKey === col2)) {
            coord2 = val;
          } 

          let valType = typeof val;

          if (!fields[columnKey]) {
            // Create field.            
        
            let field = {
              name: columnKey,
              alias: columnKey,
              identifyResultsFilterText: "",
              identifyResultsSearchFilterText: "",
              fieldType: "",          
              visibleOnMap: false,
              domain: null
            }

            if (valType === "string") {
              field.type = "esriFieldTypeString";
            } else if (valType === "boolean" || valType === "number" || (Number.isInteger(val) && (isNaN(parseFloat(val))))) {
              field.type = "esriFieldTypeInteger";
            } else if ((val instanceof Date)) {
              field.type = "esriFieldTypeDate";
            }
            else {
              throw new Error(`Unknown type for column: ${columnKey}, row: ${fileRowIndex}`);
            }
          
            fields[columnKey] = field;
          }

          let field = fields[columnKey];          
      
          if (field.type === "esriFieldTypeString") {            
            if (valType !== 'string') {
              throw new Error(`Unexpected type for column: ${columnKey}, row: ${fileRowIndex}. Expected: string, got: ${valType}`);
            }
          } else if (field.type === "esriFieldTypeInteger") {              

            if (valType === 'boolean') {
              val = val ? 1 : 0;
            } else if (!Number.isInteger(val) && (isNaN(parseFloat(val)))) {
              throw new Error(`Unexpected type for column: ${columnKey}, row: ${fileRowIndex}. Expected: integer, got: ${valType}`);
            }
          } else if (field.type === "esriFieldTypeDate") {                                 
            if ((val instanceof Date) == false) {
              throw new Error(`Unexpected type for column: ${columnKey}, row: ${fileRowIndex}. Expected: date, got: ${valType}`);
            }
          }                 

          this.$set(feature.attributes, columnKey, val);                    
        })
                
        let geometry;        
        let coordString = coord2 ? [coord1, coord2].join(',') : coord1;
        
        try {
          if (!isValidCoordString(coordString, coordType)) {
            throw new Error(`Invalid location: ${coordString}`);
          }
          geometry = coordStringToAgsPoint(this.agsPoint, coordString, this.mapView.spatialReference, coordType);        
        }
        catch (e) {
          if (coordType === "xy") {
            throw new Error(`Invalid Easting / Northing, Column: ${columnKey}, Row index: ${fileRowIndex}.  Must be present and in a valid format e.g. 622707`);       
          }
          if (coordType === "latlon") {
            throw new Error(`Invalid Latitude / Longitude, rowIndex: ${fileRowIndex}`);       
          }
          if (coordType === "gridref") {
            throw new Error(`Invalid Grid Reference, rowIndex: ${fileRowIndex}`);       
          }
        }           
        
        feature.geometry = geometry;            
        features.push(feature);
      }  

      fields = Object.values(fields);

      if (!fields.find(field => field.name === "MBID")) {
        
        let idField = {
          name: "MBID",
          alias: "MBID",
          identifyResultsFilterText: "",
          identifyResultsSearchFilterText: "",
          visibleOnMap: false,
          domain: null
        }

        fields = [idField, ...fields];

        features.forEach((feature, i) => {
          feature.attributes["MBID"] = i;                   
        })
      }
      
      let idField = fields.find(field => field.name === "MBID");
     
      let layer = {
        id: this.uuid(),
        title: layerName,
        fields: fields,        
        features: features,
        expandedInAllTree: false,
        expandedInFavsTree: false,
        expandedInVisibleTree: false,                           
        legend: [],          
        objectIdPropertyName: idField.name,  
        namePropertyName: "",          
        identifyResultsSortedField: idField,
        identifyResultsSortedAscending: true,
        identifyResultsSearchSortedField: idField,
        identifyResultsSearchSortedAscending: true,                
        includeInLayerManager: true,        
        visible: false,
        agsLayer: null,    
        symbol: null,
        symbolJSON: "",    
        loadPromise: null
      }

      return layer;
    },               

    async validate(file) {
      const data = await file.arrayBuffer();
        
      const workBook = XLSX.read(data, {type:"binary", cellDates: true});

      if (workBook.Sheets.length === 0) {
        throw new Error("No sheets found.");          
      }

      return null;
    },

    async init() {
      
      this.coordType = this.defaultCoordType;

      const data = await this.file.arrayBuffer();
        
      this.workBook = XLSX.read(data, {type:"binary", cellDates: true});

      if (this.workBook.SheetNames.length === 1) {
        this.workSheet = this.workBook.Sheets[this.workBook.SheetNames[0]];      
      }                 
    },

    getCoordColumns(columns) {      
      
      let x = columns.find(column => this.workSheet[column].w.toUpperCase() === "EASTING");
      let y = columns.find(column => this.workSheet[column].w.toUpperCase() === "NORTHING");

      if (x && y) {
        return {
          type: "xy",
          col1: x,
          col2: y,
        }
      }
      
      let lat = columns.find(column => this.workSheet[column].w.toUpperCase() === "LAT");      
      if (!lat)
        lat = columns.find(column => this.workSheet[column].w.toUpperCase() === "LATITUDE");      

      let lon = columns.find(column => this.workSheet[column].w.toUpperCase() === "LON");      
      if (!lon)
        lon = columns.find(column => this.workSheet[column].w.toUpperCase() === "LONGITUDE");      

      if (lat && lon) {
        return {
          type: "latlon",
          col1: lat,
          col2: lon,
        }
      }

      let gridRef = columns.find(column => this.workSheet[column].w.toUpperCase() === "GRID REF");      

      if (!gridRef)
        gridRef = columns.find(column => this.workSheet[column].w.toUpperCase() === "GRIDREF");      

      if (!gridRef)
        gridRef = columns.find(column => this.workSheet[column].w.toUpperCase() === "GRID REFERENCE");      

      if (gridRef) {
        return {
          type: "gridref",
          col1: gridRef,          
        }
      }

      return null;
    },
    show()
    {
      this.$refs.LayerImport.show();
    },
    hide()
    {           
      this.$refs.LayerImport.hide();
    },    

    layerImportShown(e) {                              
      this.name = ""          
      this.$refs.nameInput.focus();      
    },

    handleOK() {    

      let layer;

      try {
        layer = this.importLayer(this.workSheet,                                
                                this.name,                                 
                                this.coordType, 
                                this.coordCol1, 
                                this.coordCol2);        
      } catch(e) 
      {
        this.$emit("ok", {   
          error: e
        });
        
        return;
      }

      this.$emit("ok", {   
        layer: layer
      });        
    },   

    layerImportHidden() {    
      this.name = "",   
      this.coordType = "xy",
      this.columns = [],
      this.workBook = null;
      this.workSheet = null,
      this.coordCol1 = "",
      this.coordCol2 = ""
    },

    enterButton()
    {
      if (!this.isValid) {
        return;
      }
      
      this.handleOK();      
      this.hide();      
    }, 

    okButtonClicked(bvModalEvt){
      this.handleOK();     
    }    
  }  
} 

</script>

<style lang="scss" scoped>

</style>

<style lang="scss">

@import "././../variables";

#layerImport .value .btn {        
  font-size: 16px !important;
  position: relative;
  left: -10px;
}

#layerImport #layerNameValue {        
  font-size: 16px !important;
  position: relative;
  left: 8px;
}

#layerImport #fileValue {        
  font-size: 16px !important;
  position: relative;
  left: -2px;
}
 
#CreateBookmarkDialog .modal-dialog {
    max-width: 500px !important;
    margin: 1.75rem auto;
}

</style>