<template>
  <div>        
    <portal :to="'menus/' + menuName"> 
      <div 
      class="results h-100 d-flex flex-column">
        <SliderHeader 
        text="FEATURES IN IDENTIFY AREA"
        :menuName="menuName"
        @showHelp="$emit('showHelp','identify')"
        @openMenuItem="$emit('openMenuItem', $event)" 
        @closeMenuItem="$emit('closeMenuItem', $event)" 
        >          
        </SliderHeader>                                  
        <div class="mainContent p-2 flex-grow-1 min-height-0 d-flex flex-column">        
          <div class="topBar bottomBorder d-flex align-items-center flex-shrink-0 pb-2">       
            <template v-if="selectedLayerItem">            
              <template v-if="hasLayerProperties(selectedLayerItem.layer)">
                <button                                                   
                class="bg-transparent border-0 p-0 d-flex mx-1"                       
                v-b-popover.hover.top="'Layer properties (metadata)'"          
                @click="$emit('layerInfo', layer)">
                  <font-awesome-icon                                                     
                  transform="grow-3"
                  icon="info-circle">
                  </font-awesome-icon>
                </button>                    
              </template> 
            </template>
            <span class="ml-1">Layer:</span>
            <DropdownSelect             
            :value="selectedLayerItemId" 
            @input="selectedLayerItemIdChanged"
            class="mr-1"
            :items="layerSelectionDropdownItems" 
            noItemsSelectedText="Select Layer"
            noItemsText="No visible Layers">
            </DropdownSelect>               
            <div class="seperator"></div>
            <span class="ml-2">Buffer:</span>
            <b-input                       
            v-model="bufferSizeValue"            
            :min="0"            
            no-wheel
            number
            type="number"
            @input="bufferSizeChanged"
            class="bufferSize ml-2">
            </b-input>               
            <DropdownSelect             
            :value="bufferUnit" 
            @input="$emit('bufferUnitChanged', $event)"            
            :items="unitItems">
            </DropdownSelect>      
            <div class="seperator"></div>  
            <template v-if="identifyFeature">          
              <button @click="zoomToFitAll" v-b-popover.hover.top="'Zoom to fit identify area'"><font-awesome-icon :icon="['far', 'search-location']" class="mx-1"/></button>                             
            </template>
            <template v-if="selectedLayerItem && (selectedLayerItem.features) && (selectedLayerItem.features.length > 0)">                            
              <portal-target 
              :name="'featureGridHeader' + selectedLayerItemId">
              </portal-target>    	          
            </template>        
          </div>                  
          <div class="d-flex flex-column flex-grow-1 min-height-0 justify-content-center align-items-center">
            <template v-if="isIdentifying">                  
              <div class="d-flex align-items-center">      
                <font-awesome-icon :icon="['fad', 'spinner-third']" size="3x" class="mr-2 spinner" spin/>     
                <span class="message">Identifying...</span>                
              </div>          
            </template>  
            <template v-else-if="selectedLayerItem">    
              <template v-if="(!selectedLayerItem.features || selectedLayerItem.features.length === 0)">    
                <span class="message">{{"No features in identification area for " + selectedLayerItem.title}}</span>                  
              </template>
            </template>
            <template v-else>
              <span class="message">No layer selected.</span>                  
            </template>
            <template v-for="layerItem in layerItems">
              <FeatureGrid 
              v-show="!isIdentifying && (layerItem.id === selectedLayerItemId) && (layerItem.features) && (layerItem.features.length > 0)"
              :layer="layerItem"
              :id="layerItem.id"
              :key="layerItem.id" 
              :fields="layerItem.fields"
              :features="layerItem.features" 
              :sortedColumnField="layerItem.sortedField ? layerItem.sortedField.name : null"
              :sortedAscending="layerItem.sortedAscending"
              @selectedFeatureChangeRequest="$emit('selectedFeatureChangeRequest', $event)"
              @filteredAndSortedFeaturesChanged="$emit('filteredAndSortedFeaturesChanged', $event)"
              @sortedFieldChanged="$emit('sortedFieldChanged', layerItem, $event)"
              @fieldFilterChanged="$emit('fieldFilterChanged', layerItem, $event)"
              @gotoAndZoomToFit="$emit('gotoAndZoomToFit', $event)"
              @goto="$emit('goto', $event)"
              @addBookmark="$emit('addBookmark', $event)"
              @zoomToFitIdentifyRange="$emit('zoomToFitIdentifyRange')"
              @zoomToFitFeatures="$emit('zoomToFitFeatures', $event)"
              @delete="$emit('delete', $event)"
              >
              </FeatureGrid>                              
            </template>               
          </div>    
        </div>             
      </div>
    </portal> 
  </div>
</template>

<script>

import Vue from 'vue';
import FeatureGrid from './FeatureGrid.vue';
import SliderHeader from './shared/SliderHeader.vue';
import DropdownSelect from "./shared/DropdownSelect.vue";
import { default as FeatureMixin } from "./FeatureMixin.vue";

export default {
  components: { SliderHeader, DropdownSelect, FeatureGrid },   
  mixins: [FeatureMixin],
  props: {
    bufferSize: {
      type: Number
    },
    bufferUnit: {
      type: String
    },   
    layerItems: {
      type: Array,
      required: true
    },
    selectedLayerItemId: {
      type: String
    },   
    isIdentifying: {
      type: Boolean,
      required: true      
    },    
    identifyFeature: {
      type: Object
    }
  },           
  data() {
    return {        
      debounceTimer: null,
      debounceMs: 700,
      bufferSizeValue: 0,
      unitItems:[
        {text: "Feet", value: "feet" },
        {text: "Yards", value: "yards" },
        {text: "Metres", value: "meters" },
        {text: "Kilometres", value: "kilometers" },
        {text: "Miles", value: "miles" }
      ],                       
      menuName: "results",                              
    }                    
  },        
  computed:
  { 
    layer() {
      return this.selectedLayerItem?.layer;
    },

    layerSelectionDropdownItems() {            
             
      let layerItems = ([...this.layerItems]).map(layerItem => {
        return { text: layerItem.title, value: layerItem.id };
      });         
          
      return layerItems;
    },           
    selectedLayerItem() {      
      return this.selectedLayerItemId ? this.layerItems.find(item => item.id === this.selectedLayerItemId) : null;
    }   
  },    
  watch: {
    bufferSize(value) {
      this.bufferSizeValue = value;
    }
  },
  methods: {
    bufferSizeChanged(bufferSize) {      
      
      if (this.debounceTimer !== null) {
        clearTimeout(this.debounceTimer);
      } 
 
      this.debounceTimer = setTimeout(() => {
        this.debounceTimer = null;
        let size = bufferSize ? parseInt(bufferSize, 0) : 0;
        this.$emit("bufferSizeChanged", size);
      }, this.debounceMs);
    },
    selectedLayerItemIdChanged(value) {
      this.$emit("selectedLayerItemIdChanged", value);
    },      

    zoomToFitAll() {
      this.$emit("zoomToFitIdentifyRange");
    },
  }
};

</script>

<style lang="scss" scoped>
@import "./../variables";

.results {

  .bufferSize {
    width: 72px;
    height: 28px;
  }

  th {
    background-color: $panelContent;  
  }  

  .topBar {        
    height: 36px;

    button.coloured {
      height: 100%;
      background-color: $pColourDarkest;
      color: $textColourLight;
    }

    .seperator {
      width: 0px;        
      height: 26px;
      border-width: 1px;    
      border-color: $controlSeperator;
      border-style: none none none solid;
    };
  }

  button {
    color: $textColour;
    background-color: inherit;
    border: none;

    &.selected {
      color: $textColourLight;
    }   

    &:hover{
      color:$textColourHover;
    }

    &.notActive {
      color: $textColourDark;
    }
  }

  color: $textColour;
  background-color: $panelColour;  
  font-size: $fontSize;  

  .mainContent {
    background:  $panelContent;

    .message {
      font-size: $fontSizeLg;
    }
  }

  .bottomBorder
  {
    border-bottom: 1px solid $panelColourSeperator;    
  }
}


</style>

