<script>

var globalMixin = {
  data() {
    return  {      
      timeoutMs: 1,
      urlRegEx: new RegExp("^(http|https)://", "i"),
      agsGeometryEngine: null,
    }
  },
  beforeCreate() {
   /*  if (this.$options.watch) {      
      Object.entries(this.$options.watch).forEach(([watcherKey, func]) => {
        this.$options.watch[watcherKey] = new Proxy(func, {
          apply(target, thisArg) {
            console.log(`Called watcher ${target.name} on component ${thisArg.$options._componentTag}`);
          },
        });
      });
    } */ 
  },
  created() {   
  },
  beforeMount() {   
  },  
  mounted() {       
  },
  beforeUpdate() {    
  },
  updated() {            
    
    clearTimeout(this.$root.$updatedTimeout);               
    
    this.$root.$updatedTimeout = setTimeout(async () => {      

      if (this.$root.syncEnabled && !document.hidden) {
        await this.queueSyncProject();
      }
    }, 1000);            
  }, 

  errorCaptured(err, vm, info) {
    console.error(err);
  },
  destroyed() {  
  },  
  computed: {
    map() {
      return this.$root.mbMap;
    },
    user() {
      return this.$root.mbUser;
    },
    mapView() {      
      return this.$root.mbMapView;
    },
    mbApi() {      
      return this.$root.mbApiService;
    },
    hasMouse() {
      return (matchMedia('(pointer:fine)').matches)      
    },

    isTouchDevice() {
       return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));       
    },
  },
  methods: {

     async queueSyncProject() {

      let queue = this.$root.syncQueue;

      await queue.enqueue(() => {                                      
        let appComponent = this.$root.$children[0];
        console.log("syncing");
        return appComponent.SyncProject(true);
      })            

      while (queue.shouldRun) {
        await queue.dequeue();
      }
    },   

    end() {
      this.$root.doEnd();
    },

    getGeometryType(feature) {
      return feature.geometry.typeEx ?feature.geometry.typeEx : feature.geometry.type;
    },

    isVisibleActive(layer) {
      if (!layer.visible) {
        return false;
      }

      if (layer.parent) {
        return this.isVisibleActive(layer.parent);
      }

      return true;      
    },

    respondToVisibility(element, callback) {
        var options = {
        root: document.documentElement
      }

      var observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          callback(entry.intersectionRatio > 0);
        });
      }, options);

      observer.observe(element);
    },

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    whenRefReady(refName, fn) {
      if (!this.$refs[refName]) {
        this.$nextTick(() => {
          this.whenRefReady(refName, fn)
        });
      } else
      {
        fn(this.$refs[refName]);        
      }
    },

    isUrl(value) {  
      let isUrl = this.urlRegEx.test(value);
      return isUrl;
    },

    download(data, filename, type) {
      var file = new Blob([data], {type: type});
      if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
      else { // Others
        var a = document.createElement("a"),
                url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);  
        }, 0); 
      }
    },
    
    srvTime(){

      // https://stackoverflow.com/questions/20269657/right-way-to-get-web-server-time-and-display-it-on-web-pages

      var xmlHttp;

      try {
          //FF, Opera, Safari, Chrome
          xmlHttp = new XMLHttpRequest();
      }
      catch (err1) {
          //IE
          try {
              xmlHttp = new ActiveXObject('Msxml2.XMLHTTP');
          }
          catch (err2) {
              try {
                  xmlHttp = new ActiveXObject('Microsoft.XMLHTTP');
              }
              catch (eerr3) {
                  //AJAX not supported, use CPU time.
                  alert("AJAX not supported");
              }
          }
      }
      xmlHttp.open('HEAD',window.location.href.toString(),false);
      xmlHttp.setRequestHeader("Content-Type", "text/html");
      xmlHttp.send('');
      let date = xmlHttp.getResponseHeader("Date");
      return new Date(date);
    },        

    getLayerPath(layer, fowardSlash = true, includeRoot = true) {       
      let path = "";
      
      if (layer.parent) {        
        path = this.getLayerPath(layer.parent, fowardSlash, includeRoot);                          
      }       

      if (layer.parent || includeRoot) {

        if (path.length > 0) {
          path += fowardSlash ? "/" : "\\"
        }

        path += layer.title;
      }

      return path;
    },    

    uuid() { // https://stackoverflow.com/questions/105034/how-to-create-guid-uuid
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },     

    getLengthSuffix(unit) {
      let suffix;      
      
      switch (unit) {
        case "feet":
          suffix = "ft";
          break;
        case "yards":
          suffix = "yd";
          break;
        case "meters":
          suffix = "m";
          break;
        case "kilometers":
          suffix = "km"
          break;
        case "miles":
          suffix = "mi";
          break;
      }                 
      
      return suffix;
    },
    
    getAreaSuffix(unit) {

      let suffix;

      switch (unit) {
        case "square-feet":
          suffix = "ft²"
          break;
        case "square-yards":
          suffix = "yd²";
          break;
        case "square-meters":
          suffix = "m²"
          break;
        case "square-kilometers":
          suffix = "km²"
          break;
        case "square-miles":
          suffix = "mi²"
          break;
        case "acres":
          suffix = "acre";
          break;
        case "hectares":
          suffix = "ha";
          break;
      }  

      return suffix;
    },    
  }
};

export default globalMixin;

</script>