<template>    
	<portal :to="'menus/' + menuName">
    <div class="properties h-100 d-flex flex-column"> 
      <SliderHeader 
      :text="title"
      :menuName="menuName"
      @showHelp="$emit('showHelp', 'properties')"
      @openMenuItem="$emit('openMenuItem', $event)" 
      @closeMenuItem="$emit('closeMenuItem', $event)" 
      >        
      </SliderHeader>
      <div class="mainContent d-flex flex-column flex-grow-1 min-height-0" style="padding-top: 8px; padding-bottom: 4px; padding-left: 8px; padding-right: 10px">                         
        <div class="topBar bottomBorder d-flex align-items-center flex-shrink-0 pb-2">             
          <portal-target name="propertiesMenuBarLeft"></portal-target>              
          <template v-if="selectedValue || itemsForSelection.length > 0">          
            <DropdownSelect
            class="itemSelect min-width-0"
            noItemsSelectedText="Select item"
            :value="selectedValue"
            @input="selectedValueChanged"
            :items="itemDropdownItems">
            </DropdownSelect>
            <template v-if="selectedValue">
              <div class="ml-auto"></div>
              <portal-target name="propertiesMenuBar"></portal-target>              
            </template>                                    
          </template>               
          <template v-else>
            <span class="selectedText">No single item selected.</span>
          </template>               
        </div>
        <template v-for="propertiesType in propertiesTypes">            
          <div :key="propertiesType" class="flex-grow-1 min-height-0" v-show="selectedItem && selectedItem.propertiesType === propertiesType">
            <slot :name="propertiesType"></slot>                            
          </div>
        </template>
        </div>  
      </div>            
    </div>
	</portal>
</template>

<script>

import Moment from 'moment';
import SliderHeader from './shared/SliderHeader.vue';
import DropdownSelect from "./shared/DropdownSelect.vue";

export default {
  components: { SliderHeader, DropdownSelect }, 	
  props: {      
    itemsForSelection: {
      type: Array,
      required: true
    },        
    selectedValueProp: {
      type: Object
    },
    propertiesTypes: {
      type: Array
    }
  },
  data() {
    return {      
      menuName: "properties",     
      selectedValue: null,           
    }
  },  
  created() {
    this.selectedValue = this.selectedValueProp;
  },
  watch: {
    selectedValueProp(value) {      
      if (this.selectedValue !== value) {                
        this.selectedValue = value;                
      }      
    },    
  },
  methods: {    
    selectedValueChanged(value) {            
      this.selectedValue = value;
      this.$emit("selectedItemChanged", this.selectedItem);           
    }, 
  },   
  computed: {    
    title() {

      let title = "PROPERTIES";
      
      if (this.selectedValue) {
        return this.selectedItem.type.slice().toUpperCase() + ' ' + title;
      }

      return title;
    },
    itemDropdownItems() {
      return this.itemsForSelection.map(item => {       
        return { text: item.text, value: item.value }; 
      })
    },            
    selectedItem() {
      return this.itemsForSelection.find(item => item.value === this.selectedValue);
    }
  }  
}    

</script>

<style lang="scss">

@import "../variables"; 

.properties .mainContent .dropdown button {
  color: $textColourLight
}

</style>

<style lang="scss">

@import "../variables"; 

.properties {

  background-color: $panelColour;
  color:$textColourLight;
  font-size: $fontSize;    

  .mainContent{   

    .selectedText {
      padding-left: 8px;
      padding-right: 8px;
    }

    .itemSelect {

      text-overflow: ellipsis;

      .dropdown-toggle {
        white-space: normal !important;
      }

      .dropdown {
        min-width: 0;

        button {
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 0;
        }
      } 
    }

    .scrollbarPanel {
      overflow-y: auto;
      overflow-x: hidden;    
    }

    button {
      color: $textColour;
      background-color: inherit;
      border: none;

      &.selected {
        color: $textColourLight;
      }   

      &:hover{
        color:$textColourHover;
      }
    }

    background:  $panelContent;    

    .seperator {
      width: 0px;        
      height: 26px;
      border-width: 1px;    
      border-color: $controlSeperator;
      border-style: none none none solid;
    };

    .topBar {        
      height: 36px;
    }

    .bottomBorder
    {
      border-bottom: 1px solid $panelColourSeperator;    
    }
  }

  .scrollbarPanel {
      overflow-y: auto;
      overflow-x: hidden;    
  }  
}

</style>