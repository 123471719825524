import axios from 'axios';

export default class MSGraphService{

  constructor(msalService){

    this.msalService = msalService;
    this.config = {
      endpoint: "https://graph.microsoft.com/v1.0/me",
      requestObject:{
        scopes: ["user.read"]        
      }
    }

  }

  // add some error handling here! 
  async getUser(){    
    const accessToken = await this.msalService.getToken(this.config.requestObject);
    if(accessToken){

      
      let userInfo = await axios.get(this.config.endpoint,{
        headers: {
          Authorization: 'Bearer ' + accessToken 
        }
      });

      let userPhoto = null;

      // try {
      //   userPhoto = await axios.get(`${this.config.endpoint}/photos/96x96/$value`,{
      //     headers: {
      //       Authorization: 'Bearer ' + accessToken,            
      //     },
      //     responseType: 'blob', 
      //   });
      // } catch (e) {
      //   console.log("Could not retrieve users photo");
      //   console.error(e);
      // }      

      let groups;
      try {
        groups = await axios.get(`${this.config.endpoint}/transitiveMemberOf/microsoft.graph.group`,{
          headers: {
            Authorization: 'Bearer ' + accessToken,            
          },                    
        });
      } catch (e) {
        console.log("Could not retrieve users groups");
        console.error(e);
      }      

      return {
        groups: groups.data.value,
        displayName: userInfo.data.displayName,
        userPrincipalName: userInfo.data.userPrincipalName,
        jobTitle: userInfo.data.jobTitle,
        photo: userPhoto ? userPhoto.data : null
      };

    }
    return null;
  }
}