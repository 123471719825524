<template>    
	<portal :to="'menus/' + menuName">
    <div class="bookmarkManager h-100 d-flex flex-column"> 
      <SliderHeader 
      text="BOOKMARKS" 
      :menuName="menuName" 
      @showHelp="$emit('showHelp','bookmarks')"
      @openMenuItem="$emit('openMenuItem', $event)" 
      @closeMenuItem="$emit('closeMenuItem', $event)" 
      >
        <template slot="appendAlignedToRight">
          <button class="d-flex align-items-center" @click="addBookmark">
            <font-awesome-icon transform="grow-4" class="addIcon" :icon="['far', 'plus']"/>
          </button>
        </template>
      </SliderHeader>
      <div class="mainContent p-1 flex-grow-1 d-flex flex-column scrollbarPanel">                                    
        <template v-for="(bookmark, index) in orderedBookmarks">
          <BookmarkItem           
          :coordinateMode="coordinateMode" 
          :key="index"       
          :bookmark="bookmark" 
          @deleteClicked="deleteBookmark(bookmark)"
          @goto="$emit('goto', $event)"
          >
          </BookmarkItem>
        </template>        
      </div>
      <SliderFooter>
        <template slot="append">
          <button               
          class="d-flex align-items-center"       
          v-b-popover.hover.top="'hide all on map'" 
          @click="hideAll">        
            <font-awesome-icon transform="grow-2" class="eyeIcon" :icon="['far', 'eye']"/>        
          </button>               
          <button               
          class="d-flex align-items-center"       
          v-b-popover.hover.top="'Show all on map'" 
          @click="showAll">        
            <font-awesome-icon transform="grow-2" class="eyeIcon" icon="eye"/>        
          </button>               
        </template>
      </SliderFooter>
    </div>    
    <ConfirmationDialog
    id="deleteBookmarkConfirmationDialog"
    title="Delete bookmark"
    icon="thumbtack"
    :data="confirmationDialogData"
    :message="confirmationDialogMessage"
    @yes="deleteConfirmed"
    /> 	
	</portal>
</template>

<script>

import SliderHeader from './shared/SliderHeader.vue';
import SliderFooter from './shared/SliderFooter.vue';
import BookmarkItem from './BookmarkItem.vue';
import ConfirmationDialog from "./shared/ConfirmationDialog.vue"

export default {
  components: { SliderHeader, SliderFooter, BookmarkItem, ConfirmationDialog }, 
	props: {    
    coordinateMode:{
      type:String,
      required:true
    },
    bookmarks: {
      type: Array,
      required: true
    },        
  },  
  data() {
    return {
      confirmationDialogMessage: "",
      confirmationDialogIcon: null,
      confirmationDialogData: null,
      menuName: "bookmarkManager",    
    }
  },  
  computed: {
    orderedBookmarks() {      
      return this.bookmarks.slice().reverse();
    }
  },
  methods: {
    addBookmark() 
    {
      this.$emit("pinBookmarkCenter");      
    },
    deleteBookmark(bookmark) {

      this.confirmationDialogMessage = "Are you sure you want to delete " + '"' + bookmark.name + '"' + " ?";
      this.confirmationDialogData = bookmark;
      this.$bvModal.show("deleteBookmarkConfirmationDialog");
    },

    deleteConfirmed(bookmark) {
      this.bookmarks.splice(this.bookmarks.indexOf(bookmark), 1);
    },
    hideAll() {
      this.bookmarks.forEach(bookmark => {bookmark.visible = false});
    },
    showAll() {
      this.bookmarks.forEach(bookmark => {bookmark.visible = true});
    }
  }
}    

</script>

<style lang="scss" scoped>

</style>

<style lang="scss">

@import "../variables";

.bookmarkManager {

  background-color: $panelColour;

  .mainContent{
    background:  $panelContent;
  }

  .scrollbarPanel {
      overflow-y: auto;
      overflow-x: hidden;    
  }

  button {
    color: $textColour;
    background-color: inherit;
    border: none;

    &.selected {
      color: $textColourLight;
    }   

    &:hover{
      color:$textColourHover;
    }
  }
}

</style>