<template> 
  <div>        
    <b-modal id="exportPresentedDialog"
    ref="exportPresentedDialog"
    :ok-disabled="!allValid"
    auto-focus-button="ok"    
    @show="exportPresentedDialogShow" 
    @shown="exportPresentedDialogShown" 
    @ok="okButtonClicked" 
    @hidden="createHidden"         
    centered>     
      <template slot="modal-title">      
        <div class="d-flex justify-content-center align-items-center">
          <font-awesome-icon icon="map-marked" class="mr-2"/>          
          Export presented map
        </div> 
      </template>          
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column">                   
           <div class="d-flex my-2">        
            <span class="d-flex mt-2" :style="{'width': '80px'}">Title:</span>            
            <span class="input"><b-form-input ref="titleInput" v-model="dataTitle" @keyup.enter="enterButton"/></span>
          </div>      
          <div class="d-flex my-2">        
            <span class="d-flex mt-1" :style="{'width': '80px'}">Template:</span>            
            <span class="d-flex"><DropdownSelect class="flex-grow-1" v-model="dataTemplate" :items="templateItems"></DropdownSelect></span>                            
          </div>      
          <div class="d-flex my-2">        
            <span class="d-flex mt-1" :style="{'width': '80px'}">Layout:</span>            
            <span class="d-flex"><DropdownSelect class="flex-grow-1" v-model="dataLayout" :items="layoutItems"></DropdownSelect></span>                            
          </div>            
          <div class="d-flex my-2">        
            <span class="d-flex mt-1" :style="{'width': '80px'}">Format:</span>            
            <span class="d-flex"><DropdownSelect class="flex-grow-1" v-model="dataFormat" :items="formatItems"></DropdownSelect></span>                            
          </div>            
          <template v-if="!allValid">
            <span class="mt-2">Exporting at scales 100 - 400, when using the OS vector basemap is currently unavailable (coming soon) </span>       
          </template>
        </div>
      </div>
    </b-modal>    
  </div>     
</template>

<script>

import axios from 'axios';
import DropdownSelect from "./shared/DropdownSelect.vue"; 

export default {	
  components: { DropdownSelect },   
  props: {   
    forceUpdate: {
      Type: Boolean
    },
    basemaps: {
      Type: Array
    },   
    userProfile: {
      Type: Object
    },
    template: {
      Type: Object
    },
    layout: {
      type: String
    },
    format: {
      type: String
    },
    title: {
      type: String
    }
  },
  data() {
    return {          
      dataTemplate: "",
      dataLayout: "",
      dataFormat: "",
      dataTitle: "",
    }
  },     
  computed: {
  
    templateItems() {
      return [
        {text: "Norfolk County Council", value: "NCC"},
        {text: "Great Yarmouth Borough Council", value: "GYBC"},
      ]
    },

    layoutItems() {
      return [
        {text: "A4 Landscape", value: "A4_Landscape"},
        {text: "A3 Landscape", value: "A3_Landscape"},
        {text: "A4 Portrait", value: "A4_Portrait"},
        {text: "A3 Portrait", value: "A3_Portrait"}
      ]      
    },
    
    formatItems() {
      return [        
        {text: "PNG8", value: "png8"},        
        {text: "PDF", value: "pdf"},
        {text: "PNG32", value: "png32"}, 
        {text: "JPG", value: "jpg"},
        {text: "GIF", value: "gif"}, 
        {text: "EPS", value: "eps"},
        {text: "SVG", value: "svg"},              
      ]      
    },    

    allValid() {      

      this.forceUpdate = this.forceUpdate; // force recompute when changed.
      
      if (!this.mapView) {
        return false;
      }      

      let extended = this.basemaps.filter(basemap => basemap.visible && (basemap.extendScalesUrl !== 'null'))
      if (this.mapView.scale < 500 && extended.length > 0) {
        return false;
      }      

      return true;
    },   
  },
  methods: { 
    show()
    {
      this.$refs.exportPresentedDialog.show();
    },
    hide()
    {
      this.$refs.exportPresentedDialog.hide();
    },    

    exportPresentedDialogShow(e) {                        
      this.dataTemplate = this.template;
      this.dataFormat = this.format;
      this.dataLayout = this.layout;
      this.dataTitle = this.title;     
    },

    exportPresentedDialogShown(e) {                        
      this.$refs.titleInput.focus();
      this.$refs.titleInput.select();
    },

    handleOK() {
      this.$emit("export", {
        template: this.dataTemplate,
        format: this.dataFormat,
        layout: this.dataLayout,
        title: this.dataTitle
      });
    },   

    createHidden() {     
    },

    enterButton()
    {
      if (!this.allValid) {
        return;
      }
      
      this.handleOK();
      this.hide();
    },
    errorHidden() {      
      this.show();
    },    
    okButtonClicked(bvModalEvt){
      this.handleOK();     
    }    
  }  
} 

</script>

<style lang="scss" scoped>

</style>

<style lang="scss">

@import "././../variables";
 
#exportPresentedDialog .modal-dialog {
    max-width: 500px !important;
    margin: 1.75rem auto;

    .input {
      position: relative;
     // top: -6px;
    }
}

</style>