<template>    
  <portal :to="'menus/' + menuName">       
      <div class="search h-100 d-flex flex-column">
        <SliderHeader 
          text="SEARCH" 
          :menuName="menuName"
          @showHelp="$emit('showHelp','search')"
          @openMenuItem="$emit('openMenuItem', $event)" 
          @closeMenuItem="$emit('closeMenuItem', $event)">            
        </SliderHeader>     
        <div id="searchMainContent" class="mainContent d-flex flex-column flex-grow-1 min-height-0">          
          <div class="d-flex p-2 align-items-center flex-shrink-0">
            <DropdownSelect id="searchDropdown" boundaryElement="#searchScrollBarPanel" class="mt-2 mr-1 flex-shrink-0" :value="selectedSearchItemId" @input="selectedSearchItemIdChanged" :items="searchListItems"></DropdownSelect>
            <SearchInput             
            class="flex-grow-1" 
            :placeholder="selectedSearchItem ? selectedSearchItem.placeHolder : ''"
            ref="searchInput" 
            :delayChangeMs="1000" 
            :value="searchText"             
            @input="searchTextChanged"
            :updateOnChange="true" ></SearchInput>
          </div>             
          <template v-if="isSearching">
            <div class="d-flex flex-grow-1 align-items-center justify-content-center">
              <font-awesome-icon :icon="['fad', 'spinner-third']" size="3x" class="mr-2 spinner" spin/>               
              <span class="message">{{message}}</span>
            </div>              
          </template>
          <template v-else>
            <div id="searchScrollBarPanel" class="d-flex flex-column flex-grow-1 scrollbarPanel p-2">
              <template v-for="(feature, index) in searchResults">                
                <div :key="index" 
                @click="gotoAndSelect(feature)"
                @mouseover="featureHovered(feature)"                
                @mouseout="featureNonHovered(feature)"     
                class="listItem d-flex p-2 align-items-center"
                :class="{ selected: feature && feature.isSelected }"
                >                    
                <!--    <div class="d-flex flex-column align-self-stretch justify-content-center index pr-2">
                    <div>{{index+1}}.</div>
                  </div> -->
                  <div class="d-flex flex-column text-left pl-2 flex-grow-1" v-html="itemRenderer(feature)">
                  </div>                                          
                  <button                                                  
                  v-b-popover.hover.top="'Select and view details'"               
                  @click.stop="selectAndOpenProperties(feature)">
                    <font-awesome-icon icon="info-circle"/>
                  </button>                                                                     
                  <button
                  v-b-popover.hover.top="'Pin bookmark'"
                  @click.stop="$emit('pinBookmark', feature)"
                  >
                    <font-awesome-icon :icon="['fas', 'bookmark']" transform="shrink-2"/>                  
                  </button>                                                       
                  <button
                  v-b-popover.hover.top="'Goto (retain scale)'"
                  @click.stop="$emit('goto', feature)"
                  >
                    <font-awesome-icon :icon="['far', 'dot-circle']"/>           
                  </button>                  
                </div>                
              </template>
            </div>         
          </template>        
        </div>   
        <SliderFooter>
            <template slot="prepend">
              <span 
              class="sliderFooterText ml-2" 
              transform="shrink-2">
                <template v-if="searchResultsMessage">
                  {{searchResultsMessage}}
                </template>
                <template v-else>
                  {{`${searchResults.length} ${searchResults.length ===1  ? 'result' : 'results'} found`}}
                </template>
              </span>
              </template>
              <template slot="append">
              </template>
          </SliderFooter>     
      </div>
  </portal>
</template> 

<script>

import { loadModules } from "esri-loader";
import SliderHeader from './shared/SliderHeader.vue';
import SliderFooter from './shared/SliderFooter.vue';

import SearchInput from "./shared/SearchInput.vue";  
import DropdownSelect from "./shared/DropdownSelect.vue";
import { default as FeatureMixin } from "./FeatureMixin.vue";

import {agsPointToCoordString, 
        getGeoLocationCurrentPosition, 
        latLonToAgsPoint,
        getPolylineDistance,        
        coordStringToAgsPoint, 
        isValidCoordString,        
        distanceBetweenPoints,
        getPointAlongPolyline
        } from "./SpatialUtils.js";

export default {
  components: { SliderHeader, SliderFooter, SearchInput, DropdownSelect },
  mixins: [ FeatureMixin ],
  props: {       
    menuItems: {
      required: true,
    },    
    searchResultsMessage: {
      type: String,
    },    
    searchItems: {
      type: Array,
      required: true,
    },   
    searchText: {
      type: String,
    },    
    selectedSearchItemId: {
      type: Number
    },
    searchResultFeatures: {
      type: Array,
      required: true    
    },
    message: {
      type: String,            
      default: ""
    },
    isSearching: { 
      type: Boolean,            
      default: ""
    },  
  },
  data() {
    return {      
      menuName: "search",                  
      esriQuery:null,
      esriPoint:null,      
      tableFields:[],      
      locatorHub:null,      
      templateRegEx: new RegExp(/\${(.*?)}/g),      
    }
  },   
  computed: {  

    searchResults() {
      return this.searchResultFeatures.filter(result => {
        return (this.selectedSearchItem && result.layer === this.selectedSearchItem.subLayer);
      })
    },
    
    selectedSearchItem() {
      let item = this.searchItems.find(item => item.id === this.selectedSearchItemId);
      return item ? item: null
    },

    searchListItems() {

      let items = this.searchItems.map(item => {
        return { 
          text: item.title,
          value: item.id
        }
      });
      
      return items.sort((itemA, itemB) =>{
        return itemA.text.localeCompare(itemB.text);
      })
    },     
  },  
  watch: {
    "menuItems.search"(value) {
      if (!this.$appGlobals.isRestoringState) {            
        if (value) {
          if (this.$refs.searchInput) {                
            this.$refs.searchInput.select();      
            this.$refs.searchInput.focus();            
          }        
        }
      }
    },               
  },
  methods: {    

    searchTextChanged(value) {          
      this.$emit("searchTextChanged", value);    
    },
        
    selectedSearchItemIdChanged(value) {      
      this.$emit("searchTextChanged", "");    
      this.$emit("selectedSearchItemChanged", value);                  
    },

    selectAndOpenProperties(feature) {       
      this.$emit('selectFeatures', [feature]);
      this.$emit("openMenuItem", "properties");      
    },

    featureHovered(feature) {
      this.$emit('featureHovered', feature);
    },

    featureNonHovered(feature) {
      this.$emit('featureNonHovered', feature);
    },

    gotoAndSelect(feature) {    
      let selection = window.getSelection();
      if(selection.toString().length === 0) {    
        
        if (this.canZoomToFit(feature)) {          
          this.$emit('gotoAndZoomToFit', feature);                        
        } else {                    
          this.$emit('goto', feature);                
        }
        
        this.$emit("selectFeatures", [feature]);
      }
    },
  
    itemRenderer(feature){           
      let template = (str, obj) => str.replace(this.templateRegEx, (x, g)=> { 
        return feature.attributes[g];     
      });

      return template(this.selectedSearchItem.htmlTemplate, feature);        
    },      
    itemClick(feature) {                         
      this.$emit("featureClicked", feature);
    }                  
	}
}    
</script>


<style lang="scss">
@import "./../variables";

.search {
  color: $textColour;
  background: $panelColour;

  .mainContent{
    background:  $panelContent;

    .locationSearch {
      font-size: $fontSize;
    }

    .scrollbarPanel {
      overflow-y: auto;
      overflow-x: hidden;    
    }

    .spinner {
      --fa-primary-color: #4fc3f7;
      --fa-secondary-color: dimgray;
    }

    .message {
      font-size: $fontSizeLg;
    }
  }

  button {
    color: $textColour;
    background-color: inherit;
    border: none;    

    &:hover {
      color: $textColourLight;      
    }
  }  

  .listItem {

    .index {
      border-right: 1px solid $controlSeperator;          
    }

    &.selected {      
      background-color: $pColourDarkest !important
    }

    border-bottom: 1px solid $panelColourSeperator;        

    &:hover {
      background-color: $panelColourHover;
    }
    
    .title {
      font-size: $fontSizeLg;
      color: $textColourLight;
    }

    .content {      
      font-size: $fontSize;
    }
  }
}

.list-group-item-action {
  background-color: lighten($panelColour,3%);
  color: $textColourLight;
}
.list-group-item-action:hover {
  background-color: lighten($panelColour,3%);
  color: $textColourLight;
}

.list-group-item-action:active, .list-group-item-action:focus {
  background-color: $pColourDark;
  color: $textColourLight;
}

.searchItem h6{
  color: $textColourLight;
  font-weight: bold;
}

.tableHeader{
  display:none;
}

.rowCollection td, .rowSingle td{
  padding:0.8rem 0.5rem;
  vertical-align: middle;
}
.rowCollection{
  background-color: lighten($panelColour,4%);
}
.sliderFooterText{
  font-size: $fontSize
}

</style>