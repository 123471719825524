<template>
  <div 
  class="h-100 min-height-0 overflow-auto noSelect"  
  ref="grid"
  >
    <TableWithResizableColumns class="h-100 mappingBrowserGrid" @columnHeaderClicked="columnHeaderClicked">    
        <thead>
          <tr ref="firstRow">
            <slot name="prependCustomHeadersFirstRow"></slot>
            <template v-for="column in columns">
              <th :key="column.field" :data-columnField="column.field">
                <div class="d-flex align-items-center">
                  <span class="mr-2">{{column.label}}</span>              
                  <font-awesome-icon class="ml-auto" :icon="sortIconForColumn(column)"/>
                </div>
              </th>        
            </template>
          </tr>
          <tr ref="secondRow">
            <slot name="prependCustomHeadersSecondRow"></slot>
            <template v-for="column in columns">              
              <th :key="column.field">
                <SearchInput                 
                :value="column.filter" 
                @input="columnFilterChanged(column, $event)"
                :delayChangeMs="300"
                :showSearchIcon="false" 
                placeholder="filter..." 
                class="w-100"/>
              </th>        
            </template>
          </tr>
        </thead>    
      <tbody >        
        <template v-for="(row, rowIndex) in filteredAndSortedRows">      
          <tr :key="rowIndex"          
          >            
            <slot name="prependCell" :row="row"/>            
            <template v-for="(value, valueIndex) in rowValues(row)">
              <td 
              :class="{ selected : row.isSelected }"              
              :key="valueIndex"              
              tabindex="-1"
              @keydown="keyDown($event)"
              @keyup="keyUp($event)"
              @click="rowClicked(row, rowIndex, $event)"              
              >
              <template v-if="isUrl(value)">
                <a :href="value" target="_blank">
                  {{value}}
                </a>
              </template>
              <template v-else>
                {{value}}
              </template>              
              </td>            
            </template>    
          </tr>
        </template>    
      </tbody>
    </TableWithResizableColumns>
  </div>
</template>

<script>

import Vue from 'vue';
import SearchInput from "./SearchInput.vue"
import TableWithResizableColumns from "./TableWithResizableColumns.vue"
import Moment from 'moment';

export default {
  components: { SearchInput, TableWithResizableColumns },
  name: "Grid",
  props: {    
    columns: {
      type: Array,
      required: true
    },  
    rows: {
      type: Array,
      required: true
    },    
    sortedColumnField: {
      type: String,
      required: false
    },
    sortedAscending: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {                      
    }
  },    
  computed:
  { 
    sortedColumn() {
      if (this.sortedColumnField) {
        return this.columns.find(col => col.field === this.sortedColumnField);
      }      
    },
    selectedRows() {
      return this.rows.filter(row => row.isSelected);
    },      
    filteredAndSortedRows() 
    {       
      let startTime = performance.now();
      let rows = this.filteredRows;            

      if (this.sortedColumn)
      {
        rows = rows.sort((rowA, rowB) => {                             
                    
          let valA = rowA[this.sortedColumn.field];
          let valB = rowB[this.sortedColumn.field];          

          let ret = 0;

          if ((valA && (!valB))) {
            ret = -1;
          } else if ((valB && (!valA))) {
            ret = 1;
          } else if (valA && valB) {
                    
            switch (this.sortedColumn.type) 
            {
              case "date":              

                let dateA = Moment(valA);
                let dateB = Moment(valB);                            
                if (dateA.isBefore(dateB)) {
                  ret = -1;
                } else if (dateA.isAfter(dateB)) {
                  ret = 1;                                
                }
                break;
              case "number":
                if (valA < valB) {
                  ret = -1;
                } else if (valB < valA) {
                  ret = 1;
                }              
                break;
              case "string":                
                ret = valA.localeCompare(valB);
                break;
            }          
          }

          if (!this.sortedAscending) {
            ret = ret - (ret * 2);
          }

          return ret;
        })      
      }

      return rows;
    },

    filteredRows() {
      
      return this.rows.filter(row => {
        let show = true;

        for (let i = 0; i < this.columns.length; i++)
        {
          let column = this.columns[i];
          let val = this.getValue(row, column);
          let filterString = column.filter;
          let filter = filterString ? filterString.toLowerCase() : "";

          if (filter && filter.length > 0) {

            if (!val) {
              show = false;                                
            }
            else {
              switch (column.type) 
              {
                case "string":
                case "date":                            
                  if (val.toLowerCase().includes(filter) === false)
                  {
                    show = false;                  
                  }
                  break;
                case "number":
                  if (val.toString().toLowerCase().includes(filter) == false)
                  {
                    show = false;                  
                  }
                  break;
              }                  
            }
          }

          if (!show) {
            break;
          }
        }  
        
        return show;
      })
    },  
  },
  watch: {   
    filteredAndSortedRows:  {
      immediate: true,
      handler(newShownRows, oldShownRows) {      

        let differs = false;

        if (oldShownRows == undefined) {
          oldShownRows = [];
        }

        if (newShownRows.length !== oldShownRows.length) {
          differs = true;
        }

        if (!differs) 
        {
          for (let i = 0; i < newShownRows.length; i++) {
            let newRow = newShownRows[i];
            let oldRow = oldShownRows[i];

            if (oldRow.id !== newRow.id) {
              differs = true;
              break;
            }
          }
        }
        
        if (differs) {
          this.$emit("filteredAndSortedRowsChanged", { 
            newShownRows: newShownRows,
            oldShownRows: oldShownRows
          });       
        }
      },  
    }
  },  
  methods: {   
    columnFilterChanged(column, value) {
      this.$emit("columnFilterChanged", {
        columnField: column.field,
        value: value
      });      
    },

    ensureInView(rowEl) {

      /* let gridEl = this.$refs.grid;
      let firstRowHeight = this.$refs.firstRow.clientHeight
      let secondRowHeight = this.$refs.secondRow.clientHeight

      let headerHeights = firstRowHeight + secondRowHeight; //dmtodo: use this somewhere in this method to cater for headers.
      
      //Determine container top and bottom
      let cTop = gridEl.scrollTop;
      let cBottom = cTop + gridEl.clientHeight;

      //Determine element top and bottom
      let eTop = rowEl.offsetTop - headerHeights;
      let eBottom = eTop + rowEl.clientHeight;

      //Check if out of view
      if (eTop < cTop) {
        gridEl.scrollTop -= (cTop - eTop);
      }
      else if (eBottom > cBottom) {
        gridEl.scrollTop += (eBottom - cBottom);
      } */
    },    

    getAsCSV(selectedOnly) {

      let columnCSVs = this.columns.map(column => {
        let cellCSV = column.label;
        cellCSV = this.sanatiseCSVText(cellCSV);
        return cellCSV;
      }).join(",");

      let rows;
      
      if (selectedOnly) {
        rows = this.filteredAndSortedRows.filter(row => row.isSelected);
      } else {
        rows = this.filteredAndSortedRows;
      }

      let rowCSVs = rows.map(row => {        
        
        let valueCSVs = this.rowValues(row).map(val => {
          let cellCSV = val ? val.toString() : "";
          cellCSV = this.sanatiseCSVText(cellCSV);
          return cellCSV;
        })

        return valueCSVs.join(",");
      })      

      rowCSVs.unshift(columnCSVs);
      return rowCSVs.join("\n");
    },

    sanatiseCSVText(text) {
      
      text.replace(/"/g, '""');
      if (text.search(/("|,|\n)/g) >= 0) {
        text = '"' + text + '"';
      }
      return text;
    },      
  
    columnHeaderClicked(columnElement) {

      let columnField = columnElement.getAttribute('data-columnField');      
      let column = this.columns.find(col => col.field === columnField);

      if (column)  {
        if (this.sortedColumn && this.sortedColumn.field === column.field) {
          this.$emit("sortedColumnChanged", {
            columnField: this.sortedColumn.field,
            ascending: !this.sortedAscending
          })
        } else
        {        
          this.$emit("sortedColumnChanged", {
            columnField: column.field,
            ascending: true
          });                
        }
      }
    },
    sortIconForColumn(column) {
      
      if (this.sortedColumn && this.sortedColumn.field === column.field) {
        return this.sortedAscending ? "sort-down" : "sort-up";
      }

      return "sort";    
    },

    getValue(row, column) {
      if (column.type === "date" && row[column.field]) {
        return Moment(row[column.field]).format("DD/MM/YYYY");
      } else {
        return row[column.field];
      }
    },

    rowValues(row) {
      let properties = this.columns.map(column => {        
        return this.getValue(row, column);
      })
      
      return properties;
    },

    keyDown(event) {      
  

      if ((event.key === "ArrowDown") || (event.key === "ArrowUp")) {
    
        let lastRowClickedId = this.multiSelectLastClickedRowId ? this.multiSelectLastClickedRowId : this.lastRowClickedId;

        if (lastRowClickedId) {
          let lastRowClicked = this.filteredAndSortedRows.find(row => row.id === lastRowClickedId);
          let lastRowClickedIndex = this.filteredAndSortedRows.indexOf(lastRowClicked);        
        
          if (event.key === "ArrowDown") {
            if (lastRowClickedIndex !== this.filteredAndSortedRows.length - 1) {
              this.rowClicked(this.filteredAndSortedRows[lastRowClickedIndex + 1], event);              
              this.ensureInView(event.target);
              event.preventDefault();
            }
          } else {
            if (lastRowClickedIndex !== 0) {
              this.rowClicked(this.filteredAndSortedRows[lastRowClickedIndex - 1], event);              
              this.ensureInView(event.target);
              event.preventDefault();
            }            
          }          
        }
      }      
    },   

    keyUp(event) {
     // console.log(event);

      if (event.key === "Shift") {        
        if (this.multiSelectLastClickedRowId) {          
          this.lastRowClickedId = this.multiSelectLastClickedRowId;
          this.multiSelectLastClickedRowId = null;
        }
      }
    },
    
    rowClicked(clickedRow, clickedRowIndex, event) {         
      //console.log(event);

      let selection = window.getSelection();
      if(selection.toString().length === 0) {    
      
        let newSelectedRows = [];      

        if (event.shiftKey) {

          newSelectedRows = this.selectedRows;
          
          let lastSelectedRow = this.filteredAndSortedRows.slice().reverse().find(row => row.isSelected);
          if (lastSelectedRow) {

            let lastSelectedRowIndex = this.filteredAndSortedRows.indexOf(lastSelectedRow);

            // Ensure all from last clicked row to clicked row is selected.                          
            if (clickedRowIndex > lastSelectedRowIndex) {
              newSelectedRows = newSelectedRows.concat(this.filteredAndSortedRows.slice(lastSelectedRowIndex, clickedRowIndex + 1));                        
            }                   
          }

          let firstSelectedRow = this.filteredAndSortedRows.slice().find(row => row.isSelected);
          if (firstSelectedRow) {

            let firstSelectedRowIndex = this.filteredAndSortedRows.indexOf(firstSelectedRow);

            // Ensure all from last clicked row to clicked row is selected.                          
            if (clickedRowIndex < firstSelectedRowIndex) {
              newSelectedRows = newSelectedRows.concat(this.filteredAndSortedRows.slice(clickedRowIndex, firstSelectedRowIndex + 1));                        
            }                   
          }
        } 
        else
        {
          if (event.ctrlKey) {
            // Toggle clicked row.
            if (clickedRow.isSelected) {
              newSelectedRows = this.selectedRows.filter(row => row !== clickedRow);
            } else
            {
              newSelectedRows = [...this.selectedRows, clickedRow];            
            }
          } else {
            // clear all, but ensure clicked row is selected.
            newSelectedRows = [clickedRow];
          }                  
        }                 

        if (!newSelectedRows.equals(this.selectedRows))
        {
          this.$emit("selectedRowChangeRequest", { 
            oldSelectedRows: this.selectedRows,
            newSelectedRows: newSelectedRows,
          });
        }    
      }
    }
  }
}

</script>

<style lang="scss">

@import "../../variables";

.mappingBrowserGrid {  
  border-collapse:collapse;      

  thead {
    tr {
      th {
        cursor: auto;
        position: sticky;
        background-color: $panelColour;
      }
    }

    tr:first-of-type {
      th {
        top: 0px;
      }
    }

    tr:last-of-type {
      th {
        top: 20px;        
      }
    }
  } 
  
  th, td {
    padding-left: 3px;
    padding-right: 3px;
  }       

  th {
    white-space: nowrap;          
  }    

  input {
    background: inherit;    
  }

  tbody {
    tr {
      cursor: auto;
      &:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.08);      
      }

      td {
        &.selected {
          background-color: $pColourDarkest;
          color: lighten($textColourLight,5%);
        }
      }

      &:hover {
        background-color: $panelColourHover;
      }
    }      
  }
}

</style>