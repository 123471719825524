<template>
	<b-button-group>
		<template v-for="(button, i) in items">			
			<b-button
				:id="_uid + 'button' + i"
				:key="_uid + 'button' + i" 	
				:pressed="button.value === value"							
				@click="buttonClick(button)" 
				v-b-popover.hover.bottom.html="button.toolTip ? button.toolTip : ''"
				>				
				<font-awesome-icon 
					:icon="button.icon" 
					:transform="button.transform ? button.transform : ''"
					:class="button.iconClass ? button.iconClass : ''">
				</font-awesome-icon>
			</b-button>		 
		</template>
	</b-button-group >
</template>

<script>
export default {
	props:{
		items: {
			type: Array, 
			required:true           
		},
		value: {
		}
	},    
	methods:{
		buttonClick(button) {			
			this.$emit('input', button.value);   
		}
	}
}
</script>

<style scoped lang="scss">
@import "../../variables";

button{
		//background:$panelColourLight;
		background:transparent;
		border:1px solid transparent;
		width:2.6rem;
		color:$textColour;
		margin-right:0.1em;
}
button:hover{
		background:$panelColourLight;
		border:1px solid $panelColourLight;
		color:$textColourHover;
}
.btn-secondary:not(:disabled):not(.disabled):active, 
.btn-secondary:not(:disabled):not(.disabled).active, 
.show > .btn-secondary.dropdown-toggle{
		background:transparent;
		border:1px solid transparent;
		color:$pColour;
		outline: 0;
		-webkit-box-shadow:none;
		box-shadow:none;
}

</style>
