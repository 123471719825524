<template>    
  <div id="sliderFooter" class="flex-shrink-0 mt-auto">           
    <div class="inner d-flex h-100 align-items-center mx-2 mt-1">
      <div class="d-flex" :class="{ 'mr-2' : $slots['prepend'] }">
        <slot name="prepend"></slot>        
      </div>      
      <div class="d-flex ml-auto">        
        <slot name="append"></slot>              
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderFooter', 
}    
</script>

<style lang="scss" scoped>
@import "../../variables";

#sliderFooter {    
    background:  $panelContent;
    height: 48px;            
    color:$textColour;                         
    .inner {
      border-top: 2px solid lighten($panelColourLight,3%);
    }
    button {
    color: $textColour;
    background-color: inherit;
    border: none;

    &.selected {
      color: $textColourLight;
    }   

    &:hover{
      color:$textColourHover;
    }
  }
}   

</style>