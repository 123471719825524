<script>

import { features, resourceUsage } from "process";
import semver from "semver";
import Vue from "vue";
import moment from 'moment';

var mappingBrowserPersistance = {
  methods: {         

    // Saving.
    
    getState(type) {                 

      if (type === "device") {
        return {
          layerManager: this.$refs.layerManager.getState(type),
          overviewVisible: this.overviewVisible,
          copyrightVisible: this.copyrightVisible,
          compassVisible: this.compassVisible,
          navigationVisible: this.navigationVisible,
          scalebarVisible: this.scalebarVisible,                                                                                          
        }     
      } else if (type === "project") {
        return {
          layerManager: this.$refs.layerManager.getState(type),                     
          activeOverviewBasemapId: this.activeOverviewBasemap ? this.activeOverviewBasemap.id : null,                                  
          coordinateMode: this.coordinateMode,
          bookmarks: this.getBookmarksState(),
          searchText: this.searchText,
          selectedSearchItemId: this.selectedSearchItem ? this.selectedSearchItem.id : null,
          identifyLayerItemId : this.identifyLayerItem ? this.identifyLayerItem.id : null,        
          viewpoint: this.mapView.viewpoint.toJSON(),        
          layers: this.getLayersState(),
          locatorLayers: this.getLocatorLayersState(),
          osLayers: this.getOSLayersState(),
          selectionOutlineColour: this.selectionOutlineColour,              
          createFeatureGeometryType: this.createFeatureGeometryType,                
          measureGeometryType: this.measureGeometryType,        
          creationDefaults: JSON.stringify(this.defaults),
          lengthUnit: this.lengthUnit,
          areaUnit: this.areaUnit,
          locationVisibleOnMap: this.locationVisibleOnMap,
          areaVisibleOnMap: this.areaVisibleOnMap,
          lengthVisibleOnMap: this.lengthVisibleOnMap,                  
          measurements: this.getMeasurementsState(),
          annotations: this.getAnnotationsState(),
          locationPin : this.locationPin ? this.locationPin.toJSON() : null,
          identify: this.getIdentifyState(type),
          exportTemplate: this.exportTemplate,
          exportLayout: this.exportLayout,
          exportFormat: this.exportFormat,
          exportTitle: this.exportTitle,    
          events: this.events,
          navHistory: this.getNavHistoryState(),
          selectedItems: this.getSelectedItemsState(),
          deletionHistory: this.getDeletionHistoryState(),                      
          activeDeleteAll: this.activeDeleteAll,
          featurePropertiesGeometryExpanded: this.featurePropertiesGeometryExpanded,          
        }
      }            
    },

    getMeasurementsState() {
      return this.measurements.map(measurement => {
        return this.getMeasurementState(measurement);
      })
    },

    getFeatureStateId(feature) {      
      if (feature.type === "system") {                  
        return {        
          type: "system",
          serviceId: feature.layer.rootLayer ? feature.layer.rootLayer.id : undefined,
          layerId: feature.layer.id,
          id: feature.attributes[feature.layer.objectIdPropertyName],          
          // Need to save these as supposedly ids can change beween publications.
          // This data is checked against the backend after a restore, to check it still matches.         
          // If it doesnt match, we assume it has since been removed.
          attributes: feature.attributes,   
        }
      }      
      if (feature.type === "measurement") {
        return {        
          type: "measurement",                 
          id: feature.id
        }            
      }
      
      if (feature.type === "annotationEnd") {
        return {        
          type: "annotationEnd",                 
          id: feature.id
        }            
      }            
      if (feature.type === "userFeature") {
        return {        
          type: "userFeature",                 
          id: feature.attributes[feature.layer.objectIdPropertyName]
        }            
      }      
    },

    getDeletionHistoryState() {
      return this.deletionHistory.map(items => {
        return items.map(item => {
          if (item.type === "userFeature") {
            return this.getFeatureState(item);
          }
          if (item.type === "measurement") {
            return this.getMeasurementState(item);
          }
          if (item.type === "annotation") {
            return this.getAnnotationState(item);
          }          
        })        
      })
    },

    getSelectedItemsState() {
      return this.selectedFeatures.map(feature => {
        return this.getFeatureStateId(feature);        
      })
    },

    getNavHistoryState() {      

      let navHistory = [...this.navHistory];
      let navIndex = this.navIndex
      
      // Strip multiple selectons, as we have to store attributes 
      // it could potentially be too much data.

      for (let i = navHistory.length - 1; i >= 0; i--) {
        let navItem = navHistory[i];                            
        if (navItem.length > 1) {
           // If currently selected nav item has more than 100 items, dont remember item.  Prob too much storage            
          if ((i !== navIndex) || (navItem.length > 100)) {            
            navHistory.splice(i, 1);                            
                    
            if (navIndex >= i) {
              navIndex -= 1;
            }    
          }
        }        
      }      

      navIndex = this.removeAdjacentDupsAndEmpties(navHistory, navIndex)
            
      let items = navHistory.map(item => {        
        return item.map(feature => {      
          return this.getFeatureStateId(feature);
        })
      })            

      return {        
        index: navIndex,        
        items: items
      }          
    },

    getIdentifyState(type) {
      if (type === "device") {
        return {
          selectionVisible: this.identifyFeature ? this.identifyFeature.agsFeature.visible : false
        }
      } else if (type === "project") {
        return {
          selectionGraphic: this.identifyFeature ? this.identifyFeature.agsFeature.toJSON() : null,
          bufferGraphic: this.identifyFeature && this.identifyFeature.bufferGraphic ? this.identifyFeature.bufferGraphic.toJSON() : null,        
          identifyGeometryType: this.identifyGeometryType,
          bufferSize: this.identifyBufferSize,
          lengthUnit: this.identifyBufferUnit,                       
        }
      }
    },

    restoreIdentifyState(type, fromVersion, state) {

      if (type === "project") {
        if (this.identifyFeature) {
          this.identifyGfxLayer.remove(this.identifyFeature.agsGraphic);                         
          this.identifyFeature.agsGraphic = null;

          if (this.identifyFeature.bufferGraphic) {
            this.identifyGfxLayer.remove(this.identifyFeature.bufferGraphic);                         
            this.identifyFeature.bufferGraphic = null;
          }
        }     

        this.identifyFeature = null;

        if (state.selectionGraphic) {        

          let agsFeature = this.agsGraphic.fromJSON(state.selectionGraphic);

          let feature = Vue.observable({
            type: "identify",     
            agsFeature: agsFeature,
            bufferGraphic: null
          });                                      
                            
          this.agsFeatureToVueFeature.set(agsFeature, feature);
          this.identifyGfxLayer.add(agsFeature);          

          if (state.bufferGraphic) {
            let bufferGraphic =  this.agsGraphic.fromJSON(state.bufferGraphic);
            if (bufferGraphic) {
              feature.bufferGraphic = bufferGraphic;
              this.identifyGfxLayer.add(bufferGraphic);      
            }
          }        
          this.identifyFeature = feature;      
        }  

        this.identifyBufferSize = state.bufferSize;
        this.identifyBufferUnit = state.lengthUnit;
        this.identifyGeometryType = state.identifyGeometryType;      
        this.identifySelectionGeometryChanged();      

      } else if (type === "device") {
        if (this.identifyFeature) {
          this.identifyFeature.agsFeature.visible = state.selectionVisible;
          if (this.identifyFeature.bufferGraphic) {
            this.identifyFeature.bufferGraphic.visible = state.selectionVisible;
          }
        }
      }
    },

    getBookmarksState() {
      return this.bookmarks.map(bookmark => {
        return {
          whenCreated: bookmark.whenCreated.toISOString(),
          name: bookmark.name, 
          coords: bookmark.coords.toJSON(),          
          //feature: bookmark.feature,
          scale: bookmark.scale,          
          thumbnail: {
            srcUrl: bookmark.thumbnail.srcUrl
          },
          visible: bookmark.visible
        }
      })
    },

    getLayersState() {    
      return this.layers.map(layer => {
        let state = {          
          id: layer.id,
          expandedInAllTree: layer.expandedInAllTree,
          expandedInFavsTree: layer.expandedInFavsTree,
          expandedInVisibleTree: layer.expandedInVisibleTree,
          visible: layer.visible,
          sublayers: layer.sublayers.map(child => this.getSubLayerState(child, layer))          
        }           

        return state;
      })      
    }, 

    getLocatorLayersState() {
      return this.locatorLayers.map(layer => {
        return this.getSubLayerState(layer, layer.parent);
      })
    },

    getOSLayersState() {
      return this.osLayers.map(layer => {
        return this.getSubLayerState(layer, layer.parent);
      })
    },
   
    getSubLayerState(subLayer, parentLayer) {              
      
      let state = { 
        id: subLayer.id,                 
        favourite: subLayer.favourite,
        visible: subLayer.visible,           
        identifyResultsSortedAscending: subLayer.identifyResultsSortedAscending,
        identifyResultsSortedFieldName: subLayer.identifyResultsSortedField ? subLayer.identifyResultsSortedField.name: null,
        identifyResultsSearchSortedAscending: subLayer.identifyResultsSearchSortedAscending,
        identifyResultsSearchSortedFieldName: subLayer.identifyResultsSearchSortedField ? subLayer.identifyResultsSearchSortedField.name: null,
        fields: subLayer.sublayers ? null : this.getSubLayerFieldsState(subLayer),
        expandedInAllTree: subLayer.expandedInAllTree,
        expandedInFavsTree: subLayer.expandedInFavsTree,
        expandedInVisibleTree: subLayer.expandedInVisibleTree,     
        sublayers: subLayer.sublayers ? subLayer.sublayers.map(child => this.getSubLayerState(child, subLayer)) : null,
        isSelected: subLayer.isSelected
      };      

      if (subLayer.rootLayer === this.privateLayersGroup) {                    
        state.title = subLayer.title;        
        state.objectIdPropertyName = subLayer.objectIdPropertyName;
        state.includeInLayerManager = subLayer.includeInLayerManager;        
        state.symbol = subLayer.symbol.toJSON();          
      }

      if (subLayer === this.userFeaturesLayer) {
        state.features = subLayer.features.map(feature => {     
          return this.getFeatureState(feature);
        })
      }

      if (state.sublayers) {
        if (state.sublayers.some(child => child.name)) {          
          state.name = subLayer.title;
        }
      } else if (state.favourite || state.visible) {
        state.name = subLayer.title;
      }

      return state;
    },

    getFeatureState(feature) {             
      return {                    
        type: feature.type,
        attributes: feature.attributes,          
        attributesVisibleOnMap: feature.attributesVisibleOnMap,          
        locationVisibleOnMap: feature.locationVisibleOnMap,          
        lengthVisibleOnMap: feature.lengthVisibleOnMap,
        sideLengthsVisibleOnMap: feature.sideLengthsVisibleOnMap,
        areaVisibleOnMap: feature.areaVisibleOnMap,
        lengthUnit: feature.lengthUnit,
        areaUnit: feature.areaUnit,                  
        agsGraphicJSON: feature.agsFeature ? feature.agsFeature.toJSON() : null,
        geometry: feature.geometry.toJSON(),          
        geometryTypeEx: feature.geometry.typeEx,
        symbol: feature.symbol,                              
      }            
    },

    getMeasurementState(measurement) {      
      return {                    
        type: "measurement",          
        id: measurement.id,
        locationVisibleOnMap: measurement.locationVisibleOnMap,          
        lengthVisibleOnMap: measurement.lengthVisibleOnMap,
        sideLengthsVisibleOnMap: measurement.sideLengthsVisibleOnMap,
        areaVisibleOnMap: measurement.areaVisibleOnMap,
        lengthUnit: measurement.lengthUnit,
        areaUnit: measurement.areaUnit,                  
        agsGraphicJSON: measurement.agsFeature.toJSON(),
        geometry: measurement.geometry.toJSON(),          
        geometryTypeEx: measurement.geometry.typeEx,
        symbol: measurement.symbol,                              
      }                      
    },

    getAnnotationsState() {
      return this.annotations.map(annotation => {
        return this.getAnnotationState(annotation);       
      })
    },

    getAnnotationState(annotation) {
      let line = annotation.line;
        
      let lineState = line ? {
        type: "annotationLine",      
        id: line.id,           
        agsGraphicJSON: line.agsFeature.toJSON(),
        geometry: line.geometry.toJSON(),          
        geometryTypeEx: line.geometry.typeEx,
        symbol: line.symbol,
      } : undefined 

      let end = annotation.end;
      
      let endState = {                    
        type: "annotationEnd",      
        id: end.id,           
        agsGraphicJSON: end.agsFeature.toJSON(),
        geometry: end.geometry.toJSON(),          
        geometryTypeEx: end.geometry.typeEx,
        symbol: end.symbol,          
      }

      let label = annotation.label;

      let labelState = {                    
        type: "annotationLabel",      
        id: label.id,           
        agsGraphicJSON: label.agsFeature.toJSON(),
        geometry: label.geometry.toJSON(),          
        geometryTypeEx: label.geometry.typeEx,
        symbol: label.symbol,          
      }

      return {
        type: annotation.type,
        id: annotation.id,
        line: lineState,
        end: endState,
        label: labelState
      }
    },

    getSubLayerFieldsState(subLayer) {      
       if (!subLayer.fields) {
        return null;
      }

      return subLayer.fields.map(field => {
        let state = {
          name: field.name,   
          type: field.type,
          visibleOnMap: field.visibleOnMap,
          identifyResultsFilterText: field.identifyResultsFilterText,
          identifyResultsSearchFilterText: field.identifyResultsSearchFilterText
        }

        if (subLayer.rootLayer === this.privateLayersGroup) {
          state.alias = field.alias;
        }

        return state;
      })      
    },

    migrateSubLayer(fromVersion, rootState, sublayer) {
      if (sublayer.features) {

        for (let i = sublayer.features.length -1; i >= 0; i--) {

          let feature = sublayer.features[i];                   
          let symbol = feature.symbol;

          if (semver.lt(fromVersion, "4.26.0"))
          { 
            if (feature.geometryTypeEx === "point") {              
              this.$set(symbol, "size", 10);
            } 

            if (feature.geometryTypeEx === "text") {              

              this.$set(symbol, "haloColor", [0, 0, 0, symbol.color[3]]);
              this.$set(symbol, "haloSize", 0);
              this.$set(symbol, "angle", 0);

              sublayer.features.splice(i, 1);                              

              let annotation = {
                id: this.uuid(),
                end: null,
                line: null,
                label: feature
              }
              
              rootState.annotations.push(annotation);
            } 
            else
            {            
              feature.attributesVisibleOnMap = Object.keys(feature.attributesVisibleOnMap);
              if (feature.geometryTypeEx === "polygon")
              {
                feature.lengthVisibleOnMap = feature.perimeterLengthVisibleOnMap;
              }

              if (feature.geometryTypeEx === "circle")
              {
                feature.lengthVisibleOnMap = feature.perimeterLengthVisibleOnMap;
              }

              if (feature.geometryTypeEx === "rectangle")
              {
                feature.lengthVisibleOnMap = feature.perimeterLengthVisibleOnMap;
              }

              if (feature.geometryTypeEx === "polyline") {  
                feature.areaVisibleOnMap = false;              
                feature.lengthVisibleOnMap = feature.lineLengthVisibleOnMap;
              }

              if (feature.geometryTypeEx === "point") {  
                feature.areaVisibleOnMap = false;
                feature.lengthVisibleOnMap = false;
              }            
            }
          }
        }        
      }

      if (sublayer.sublayers) {
        sublayer.sublayers.forEach(child => {
          this.migrateSubLayer(fromVersion, rootState, child);
        })          
      }
    },

    migrateState(type, fromVersion, state) {      

      if (type === "project") {
        if (semver.lt(fromVersion, "4.26.0"))
        {                            
          state.annotations = [];
        }    

        state.layers.forEach(layerState => {      
          this.migrateSubLayer(fromVersion, state, layerState);
        })                           
      }
    },

    async restoreState(type, fromVersion, options, state) {                    

      var now = new Date();

      this.migrateState(type, fromVersion, state);
      
      if (type === "project") {

        this.lastLoadedProject = {
          fromVersion: fromVersion,
          options: options,
          state: state,
        }

        if (state.exportTemplate !== undefined) this.exportTemplate = state.exportTemplate;
        if (state.exportLayout !== undefined) this.exportLayout = state.exportLayout;
        if (state.exportFormat !== undefined) this.exportFormat = state.exportFormat;
        if (state.exportTitle !== undefined) this.exportTitle = state.exportTitle;      
        
        this.bookmarks = this.restoreBookmarks(state.bookmarks, now);
        let viewpoint = this.agsViewpoint.fromJSON(state.viewpoint);

        this.mapView.goTo({
          target: viewpoint,        
        })                                     

        if (this.selectedSystemFeaturesGfxLayer.graphics.items.length > 0) {
          debugger;
        }        

        if (state.activeOverviewBasemapId) {
          let basemap = this.basemaps.find(basemap => basemap.id === state.activeOverviewBasemapId);
          if (basemap) {
            this.activeOverviewBasemap = basemap;
          }         
        }
        
        await this.$refs.layerManager.restoreState(type, fromVersion, state.layerManager);            
        this.searchText = state.searchText;
                  
        
        if (state.selectionOutlineColour) {
          this.selectionOutlineColour = state.selectionOutlineColour 
        }        
        
        if (state.createFeatureGeometryType) { 
          this.createFeatureGeometryType = state.createFeatureGeometryType;                  
        }    

        if (state.measureGeometryType) { 
          this.measureGeometryType = state.measureGeometryType;                  
        }    

        if (state.lengthUnit) {
          this.lengthUnit = state.lengthUnit;
        }

        if (state.areaUnit) {
          this.areaUnit = state.areaUnit;
        }

        if (state.locationVisibleOnMap) {
          this.locationVisibleOnMap = state.locationVisibleOnMap;
        }

        if (state.areaVisibleOnMap) {
          this.areaVisibleOnMap = state.areaVisibleOnMap;
        }

        if (state.lengthVisibleOnMap) {
          this.lengthVisibleOnMap = state.lengthVisibleOnMap;
        }

        if (state.featurePropertiesGeometryExpanded) {
          this.featurePropertiesGeometryExpanded = state.featurePropertiesGeometryExpanded;
        }        

        if (state.measurements) {

          let measurements = state.measurements.map(state => {       
            return this.restoreMeasurementState(state);
          })          

          measurements.forEach(measurement => {
            this.renderFeature(measurement);            
          })                    
        }

        if (state.annotations) {

          let annotations = state.annotations.map(state => {       
            return this.restoreAnnotationState(fromVersion, state);
          })          

          annotations.forEach(annotation => {
            this.renderFeature(annotation);            
          })                              
        }

        if (state.creationDefaults) {
          this.defaults = JSON.parse(state.creationDefaults);
        }

        if (state.locationPin) {
          this.locationPin = this.agsGraphic.fromJSON(state.locationPin);
          this.mapPinGfxLayer.graphics.add(this.locationPin);              
        }      
        
        if (state.events) {
          this.events = state.events;        
        }        

        if (state.deletionHistory) {
          this.deletionHistory = this.restoreDeletionHistory(fromVersion, state.deletionHistory);          
        }
        
        try {
          await this.restoreLayersState(fromVersion, state);             
        } catch (e) {
          this.errorMessage = e.message;    
          this.errorTitle = "Error restoring layer visibility."       
          console.error(e);
          this.$refs.ErrorDialog.show();                                     
        }

        if (this.selectedLayer) {
          let path = this.getLayerPath(this.selectedLayer, false, false);                  
          this.selectedLayer.meta = await this.mbApi.getLayerMeta(this.selectedLayer.rootLayer.arcGISPath, path);
        }        

        if (state.selectedSearchItemId && this.searchItems.find(item => item.id === state.selectedSearchItemId)) {
          try {
            await this.selectedSearchItemChanged(state.selectedSearchItemId);        
          } catch (e) {
            this.errorMessage = e.message;   
            this.errorTitle = "Could not restore search."          
            console.error(e);
            this.$refs.ErrorDialog.show();                                        
          }
        }  

        if (state.identifyLayerItemId && this.identifyLayerItems.find(item => item.id === state.identifyLayerItemId)) {
          this.identifyLayerItemId = state.identifyLayerItemId;
        } else {
          this.identifyLayerItemId = null;
        }        
        
        try {
          await this.restoreIdentifyState(type, fromVersion, state.identify);             
        } catch (e) {
          this.errorMessage = e.message;        
          this.errorTitle = "Could not restore identify."    
          console.error(e); 
          this.$refs.ErrorDialog.show();                
        }        
                
        if (state.navHistory) {

          try {
            await this.restoreNavHistory(state.navHistory);                     
            await this.restoreSelectedItems(state.selectedItems);                     
          } catch (e) {            
            this.errorMessage = e.message;    
            this.errorTitle = "Could not restore Nav history."       
            console.error(e);
            this.$refs.ErrorDialog.show();                                               
          }                    
        }        

      } else if (type === "device") {
        this.overviewVisible = state.overviewVisible,
        this.copyrightVisible = state.copyrightVisible,
        this.compassVisible = state.compassVisible,
        this.navigationVisible = state.navigationVisible,
        this.scalebarVisible = state.scalebarVisible,                       

        await this.$refs.layerManager.restoreState(type, fromVersion, state.layerManager);                       
      }      

      if (state.activeDeleteAll) 
        this.activeDeleteAll = state.activeDeleteAll;

      this.exportPresentedForceUpdate = !this.exportPresentedForceUpdate;               
    }, 

    restoreSelectedItems(selectedItemsState) {          
      selectedItemsState.forEach(itemState => {        
        if (itemState.type === "system") {          
          if (itemState.serviceId) {
            let service = this.layers.find(layer => layer.id === itemState.serviceId);          
            if (service) {            
              let layer = service.allSublayers.find(layer => layer.id === itemState.layerId);            
              if (layer) {
                let feature = layer.features.find(feature => feature.attributes[layer.objectIdPropertyName] === itemState.id)
                if (feature) {                                    
                  feature.isSelected = true;                                      
                }
              }
            }
          } else {
            let layer = this.locatorLayers.find(layer => layer.id === itemState.layerId);            
            if (layer) {
              let feature = layer.features.find(feature => feature.attributes[layer.objectIdPropertyName] === itemState.id);                            
              if (feature) {
                feature.isSelected = true;
              }
            }            
            layer = this.osLayers.find(layer => layer.id === itemState.layerId);            
            if (layer) {
              let feature = layer.features.find(feature => feature.attributes[layer.objectIdPropertyName] === itemState.id);                            
              if (feature) {
                feature.isSelected = true;
              }
            }            
          }
        } else if (itemState.type === "measurement") {          
          let measurement = this.measurements.find(measurement => measurement.id === itemState.id);
          if (measurement){
            measurement.isSelected = true;          
            measurement.transform = true;                                  
          }
        } else if (itemState.type === "annotationEnd") {                           
          let annotationEnds = this.annotations.filter(annotation => annotation.end)
                                               .map(annotation => annotation.end)
                                    
          let end = annotationEnds.find(end => end.id === itemState.id);               

          if (end) {
            end.isSelected = true;          
            end.transform = true;                        
          }
        } else if (itemState.type === "userFeature") {                 
          let feature = this.userFeatures.find(feature => feature.attributes[this.userFeaturesLayer.objectIdPropertyName] === itemState.id)
          if (feature) {
            feature.isSelected = true;          
            feature.transform = true;                        
          }
        }
      })      
    },

    async restoreNavHistory(navHistoryState) {                                

      let subLayerAndFeatureIds = new Map();

      // Setup to get system features.

      navHistoryState.items.forEach(async (item, i)  => {        
        item.forEach(async (featureState, j) => {                    
          if ((featureState.type === "system") && featureState.serviceId) {                        
            let service = this.layers.find(layer => layer.id === featureState.serviceId);          
            if (service && service.type === "arcGIS") {            
              let layer = service.allSublayers.find(layer => layer.id === featureState.layerId);            
              if (layer) {
                if (!subLayerAndFeatureIds.has(layer)) {
                  subLayerAndFeatureIds.set(layer, []);              
                }                                         

                let mappings = subLayerAndFeatureIds.get(layer);
                mappings.push(featureState)
              }
            }
          }          
        })
      })
      
      let layers = Array.from(subLayerAndFeatureIds.keys());                
      if (layers.length) {
        let promises = layers.map(layer => {
          let featureStates = subLayerAndFeatureIds.get(layer);
          return this.getFeaturesByState(layer, featureStates);        
        })      
        
        await Promise.all(promises);        
      }

      // Got system features.

      let navIndex = navHistoryState.index;
      let navHistory = [];      

      // Build nav history

      navHistoryState.items.forEach((itemState, i) => {        
                
        let features = [];
        let item = [];        

        itemState.forEach((featureState) => {          
          if (featureState.type === "system") {                                  
            if (featureState.serviceId) {
              let service = this.layers.find(layer => layer.id === featureState.serviceId); 
              if (service && service.type === "arcGIS") {
                if (featureState.feature) {
                  features.push(featureState.feature);
                }
              } 
              else
              {
                let layer = this.privateLayersGroup.allSublayers.find(layer => layer.id === featureState.layerId);
                if (layer) {
                  let feature = layer.features.find(feature => feature.attributes[layer.objectIdPropertyName] === featureState.id);                            
                  if (feature) {
                    features.push(feature);
                  }
                }                              
              }             
            }              
            else {
              let layer = this.locatorLayers.find(layer => layer.id === featureState.layerId);
              if (layer) {
                let feature = layer.features.find(feature => feature.attributes[layer.objectIdPropertyName] === featureState.id);                            
                if (feature) {
                  features.push(feature);
                }
              }              
              layer = this.osLayers.find(layer => layer.id === featureState.layerId);
              if (layer) {
                let feature = layer.features.find(feature => feature.attributes[layer.objectIdPropertyName] === featureState.id);                            
                if (feature) {
                  features.push(feature);
                }
              }              
            }            
          }
          else if (featureState.type === "measurement") {                        
            let measurement = this.measurements.find(measurement => measurement.id === featureState.id);
            if (measurement) {
              features.push(measurement);
            }
          }
          else if (featureState.type === "annotationEnd") {                           
            let annotationEnds = this.annotations.filter(annotation => annotation.end)
                                               .map(annotation => annotation.end)
            let end = annotationEnds.find(end => end.id === featureState.id);        
            if (end) {
              features.push(end);            
            }
          }
          else if (featureState.type === "userFeature") {                                                
            let feature = this.userFeatures.find(feature => feature.attributes[this.userFeaturesLayer.objectIdPropertyName] === featureState.id);                    
            if (feature) {
              features.push(feature);
            }            
          }
        })                               
        
        navHistory.push(features);                        
      })                  

      navIndex = this.removeAdjacentDupsAndEmpties(navHistory, navIndex)
      
      this.navHistory = navHistory;
      this.navIndex = navIndex;             
    },

    async getFeaturesByState(layer, featureStates) {      
      
      let featureIds =  featureStates.map(featureState => featureState.id).distinct();        
      let featureResults = await this.getFeaturesById(layer, featureIds);       
      

      featureResults.forEach(agsFeature => {
        let id = agsFeature.attributes[layer.objectIdPropertyName]
        let featureStateResults = featureStates.filter(featureState => featureState.id === id);                      
        featureStateResults.forEach(featureState => {    
         
          let keys = Object.keys(agsFeature.attributes);
          if (keys.length === (Object.keys(featureState.attributes).length)) 
          { 
            let attMatch = true;          

            for (let key of keys) {
              let val1 = agsFeature.attributes[key];
              let val2 = featureState.attributes[key];
              if (val2 === undefined) {
                attMatch = false;
                break;
              }

              if (val1 !== val2) {
                attMatch = false;
                break;
              }
            }
            
            if (attMatch) {            
              let feature = this.mergeFeature(layer, agsFeature);        
              featureState.feature = feature          
            } 
          }
        });        
      })   
    },

    restoreBookmarks(bookmarksState, now) {        

      return bookmarksState.map(state => {
        
        let bookmark;

        bookmark = {          
          name: state.name, 
          coords: this.agsPoint.fromJSON(state.coords),
          //feature: bookmark.feature,
          scale: state.scale,          
          thumbnail: {
            srcUrl: state.thumbnail.srcUrl
          },
          visible: state.visible
        }

        if (!state.whenCreated) {             
          bookmark.whenCreated = now;
        } else {
          bookmark.whenCreated = new Date(state.whenCreated);
        }
        
        return bookmark;
      })      
    },

    addPrivateLayers(fromVersion, state) {      
      let layerState = state.layers.find(layer => layer.id === "privateLayers");            
      if (layerState) {
        let subLayers = layerState.sublayers.map(subLayerState => {        

          let subLayer = Vue.observable({ 
            id: subLayerState.id,                 
            title: subLayerState.title,        
            objectIdPropertyName: subLayerState.objectIdPropertyName,
            includeInLayerManager: subLayerState.includeInLayerManager,                                       
            symbol: this.agsSimpleMarkerSymbol.fromJSON(subLayerState.symbol),          
            favourite: false,
            visible: false,           
            identifyResultsSortedAscending: true,
            identifyResultsSortedField: null,
            identifyResultsSearchSortedAscending: true,
            identifyResultsSearchSortedField: null,
            fields: [],          
            sublayers: null,
            loadPromise: null,
            features: [],
            parent: null,
            rootLayer: null,
            agsLayer: null,
          });      
          
          let fields = subLayerState.fields.map(fieldState => {          
            return Vue.observable({
              name: fieldState.name,
              alias: fieldState.alias,   
              type: fieldState.type,
              identifyResultsFilterText: "",
              identifyResultsSearchFilterText: "",
              visibleOnMap: false,
              domain: null
            })
          })        

          this.defineLayerProperties(subLayer);   
          this.$set(subLayer, "parent", this.privateLayersGroup);
          this.$set(subLayer, "rootLayer", this.privateLayersGroup);                
          subLayer.fields = fields;
          return subLayer;
        })                  

        this.privateLayersGroup.sublayers = subLayers;
        this.privateLayersGroup.allSublayers = subLayers.slice();
      }
    },

    async restoreLayersState(fromVersion, state) {

      // add private sublayers
      this.addPrivateLayers(fromVersion, state);

      this.restoreLayerOrder(fromVersion, state);

      state.layers.forEach(layerState => {
        let vueLayer = this.layers.find(layer => layer.id === layerState.id);
        if (vueLayer) {
          this.recurseRestoreSubLayerOrder(fromVersion, vueLayer, layerState);
        }
      })   

      let stateLookup = this.getLayerStateLookup(fromVersion, state);    

      for (let layer of this.allLayers) {                               

        let layerState = stateLookup[layer.globalId];
        layerState = layerState ? layerState: null;       

        if (layer.sublayers === null) {
          layer.favourite = layerState ? layerState.favourite : false;                     
          layer.identifyResultsSortedAscending = layerState ? layerState.identifyResultsSortedAscending : true,
          layer.identifyResultsSearchSortedAscending = layerState ? layerState.identifyResultsSearchSortedAscending : true
        }        

        layer.expandedInAllTree = layerState ? layerState.expandedInAllTree : false;
        layer.expandedInFavsTree = layerState ? layerState.expandedInFavsTree : true;
        layer.isSelected = layerState ? layerState.isSelected: false;
        layer.expandedInVisibleTree = layerState ? layerState.expandedInVisibleTree : true;
        
        if (layer.globalId.startsWith("locator_") || layer.globalId.startsWith("os_") || layer.rootLayer === this.privateLayersGroup) {          
          this.restoreFieldsState(layer);        
        }     
        
        if (layerState && layerState.features) {

          let features = layerState.features.map(state => {       
            return this.restoreFeatureState(state);
          })          

          features.forEach(feature => {
            this.renderFeature(feature);            
          })          
        }        
      }         

      // layer visibility                

      for (let layer of this.allLayers) {                               

        let layerState = stateLookup[layer.globalId];
        layerState = layerState ? layerState: null;

        if (layerState && layerState.visible) {
          layer.visible = true;
        } else {
          layer.visible = false;
        }                          
      }      

      let promises = this.layers.map(async (layer) => {
        return this.loadServiceLayerDeps(layer);
      })    
      
      try {
        await Promise.all(promises);      
        this.layers.forEach(layer => this.renderLayerVisiblity(layer));
      } catch (e) {
        throw e;
      }
    },      
       
    async restoreLayerOrder(fromVersion, state) {

      let index = 0;      
      // Working on non-reactive copy of array, as working direct with reactive version 
      // causes signficant delay due to computed properties that react to changes.
      // So do work on temp.  Then re-assign at end.          
      let tempLayers = [...this.layers];        

      // Order of states indicate layer order.
      for (let layerState of state.layers) {

        let tempLayer = tempLayers.find(layer => layer.id === layerState.id);                  
        if (tempLayer) 
        {                    
          // Re-order vue layer to match state order.
          let vueLayerIndex = tempLayers.indexOf(tempLayer);          
          let removedLayer = tempLayers.splice(vueLayerIndex, 1)[0];                              
          tempLayers.splice(index, 0, removedLayer);          

          index += 1;                    
        }                        
      } 
      
      this.layers = tempLayers;
    }, 

    async recurseRestoreSubLayerOrder(fromVersion, vueLayer, layerState) {

      if (layerState.sublayers) {

        let index = 0;      
        // Working on non-reactive copy of array, as working direct with reactive version 
        // causes signficant delay due to computed properties that react to changes.
        // So do work on temp.  Then re-assign at end.          
        let childLayers = [...vueLayer.sublayers];        

        // Order of states indicate layer order.
        for (let subLayerState of layerState.sublayers) {

          let childLayer = childLayers.find(layer => layer.id === subLayerState.id);                  
          if (childLayer) 
          {                    
            // Re-order vue layer to match state order.
            let vueLayerIndex = childLayers.indexOf(childLayer);          
            let removedLayer = childLayers.splice(vueLayerIndex, 1)[0];                              
            childLayers.splice(index, 0, removedLayer);          

            index += 1;        
            
            this.recurseRestoreSubLayerOrder(fromVersion, childLayer, subLayerState);
          }
        }
        
        vueLayer.sublayers = childLayers;
      }      
    }, 

    getSubLayerStateLookup(fromVersion, state, states, isRoot, rootId) {
      if (state) {

        let globalId;

        if (isRoot) {
          globalId = state.id;
        } else {
          globalId = rootId + "_MBID_" + state.id;
        }
        
        states[globalId] = state;
        if (state.sublayers) {
          state.sublayers.forEach(subLayer => {
            this.getSubLayerStateLookup(fromVersion, subLayer, states, false, rootId);
          })
        }
      }               
    },

    // Flatten layer state tree.
    getLayerStateLookup(fromVersion, state) {
      
      let states = {};    

      state.layers.forEach(layerState => {      

        this.getSubLayerStateLookup(fromVersion, layerState, states, true, layerState.id);
      })                     

      state.locatorLayers.forEach(layerState => {
        states["locator_" + layerState.id] = layerState;
      })

      if (state.osLayers) {
        state.osLayers.forEach(layerState => {
          states["os_" + layerState.id] = layerState;
        })
      }

      return states;
    },

    restoreFieldsState(subLayer) {
      
      let layerLookup = this.getLayerStateLookup(this.lastLoadedProject.fromVersion, 
                                                 this.lastLoadedProject.state);

      let state = layerLookup[subLayer.globalId];
        
      if (state && state.fields) { 
        for (let field of subLayer.fields) {
          let fieldState =  state.fields.find(fieldState => fieldState.name === field.name)
          if (fieldState) {
            field.identifyResultsFilterText = fieldState.identifyResultsFilterText;
            field.identifyResultsSearchFilterText = fieldState.identifyResultsSearchFilterText;
            if (fieldState.visibleOnMap) {
              field.visibleOnMap = fieldState.visibleOnMap;
            }
          }
        }         

        if (state.identifyResultsSortedFieldName) {
          let sortedField = subLayer.fields.find(field => field.name === state.identifyResultsSortedFieldName);
          if (sortedField) {
            subLayer.identifyResultsSortedField = sortedField;    
          }              
        } 

        if (state.identifyResultsSearchSortedFieldName) {
          let sortedField = subLayer.fields.find(field => field.name === state.identifyResultsSearchSortedFieldName);
          if (sortedField) {
            subLayer.identifyResultsSearchSortedField = sortedField;        
          }          
        } 
      }      
    },       

    restoreFeatureState(state) {            
      
      let agsFeature = this.agsGraphic.fromJSON(state.agsGraphicJSON);        

      if (!state.attributes["MBID"]) {  // fix id.
        state.attributes["MBID"] = this.uuid();
      }

      let feature = Vue.observable(
      {
        type: "userFeature",
        attributes: state.attributes,          
        attributesVisibleOnMap: state.attributesVisibleOnMap,          
        locationVisibleOnMap: state.locationVisibleOnMap,          
        lengthVisibleOnMap: state.lengthVisibleOnMap,
        sideLengthsVisibleOnMap: state.sideLengthsVisibleOnMap,
        areaVisibleOnMap: state.areaVisibleOnMap,
        lengthUnit: state.lengthUnit,
        areaUnit: state.areaUnit,                         
        geometry: agsFeature.geometry,                          
        symbol: state.symbol,                
        isSelected: false,    
        isSearchResult: false,           
        isInSelectionRange: false,
        isCreating: false,             
        agsFeature: agsFeature,
        layer: null,
        updateGfxLayer: null,
        updateGfxLayerIndex: null,
        transform: false              
      });        

      feature.geometry.typeEx = state.geometryTypeEx;                                            
      return feature;      
    },          

    restoreDeletionHistory(fromVersion, state) {

      // Fix empty element. T1001267
      let history = state.slice();
      history.forEach((deletion, i) => {
        history[i] = deletion.filter(item => item !== null);
      })          
      
      history = history.filter(deletion => deletion.length > 0);

      return history.map(deletionState => {
        return deletionState.map(featureState => {
          if (featureState.type === "userFeature") {
            return this.restoreFeatureState(featureState);
          }
          if (featureState.type === "measurement") {
            return this.restoreMeasurementState(featureState);
          }
          if (featureState.type === "annotation") {
            return this.restoreAnnotationState(fromVersion, featureState);
          }
        })
      })      
    },

    restoreAnnotationState(fromVersion, state) {
       // label.

      if (!state.type) {  // fix type.
        state.type = "annotation";
      }

      let labelState = state.label;
      let labelAgsFeature = this.agsGraphic.fromJSON(labelState.agsGraphicJSON);            

      if (semver.lt(fromVersion, "4.26.0")){
        // fix labelAgsFeature,   
        labelAgsFeature.geometry = labelAgsFeature.geometry.extent.center;
      }

      let label = Vue.observable({
        type: "annotationLabel",          
        id: labelState.id,     
        geometry: labelAgsFeature.geometry,                          
        symbol: labelState.symbol,                          
        isSelected: false,    
        isSearchResult: false,           
        isInSelectionRange: false,
        isCreating: false,                 
        agsFeature: labelAgsFeature,
        layer: null,
        updateGfxLayer: null,
        updateGfxLayerIndex: null,
        transform: false,
        annotation: null
      });      
      
      label.geometry.typeEx = "point"

      // end

      let end;
      
      if (semver.lt(fromVersion, "4.26.0")) {         
        end = this.createAnnotationEnd(label.geometry, null);
        labelAgsFeature = this.agsGraphic.fromJSON(labelState.agsGraphicJSON);                   
        end.agsFeature.geometry = labelAgsFeature.geometry.clone();
        end.agsFeature.geometry.typeEx = "rectangle";
        end.geometry = end.agsFeature.geometry;
      } else {          
        let endState = state.end;
        let endAgsFeature = this.agsGraphic.fromJSON(endState.agsGraphicJSON);    

        end = Vue.observable({
          type: "annotationEnd",          
          id: endState.id,     
          geometry: endAgsFeature.geometry,                          
          symbol: endState.symbol,                           
          isSelected: false,    
          isSearchResult: false,           
          isInSelectionRange: false,
          isCreating: false,                 
          agsFeature: endAgsFeature,
          layer: null,
          updateGfxLayer: null,
          updateGfxLayerIndex: null,
          transform: false,
          annotation: null
        });
                
        end.geometry.typeEx = endState.geometryTypeEx;            
      }                

      let line;

      if (state.line) {

        let lineState = state.line;       
        let lineAgsFeature = lineState ? this.agsGraphic.fromJSON(lineState.agsGraphicJSON) : undefined;
      
        line = Vue.observable({
          type: "annotationLine",          
          id: lineState.id,     
          geometry: lineAgsFeature.geometry,                          
          symbol: lineState.symbol,                   
          isSelected: false,    
          isSearchResult: false,           
          isInSelectionRange: false,
          isCreating: false,                 
          agsFeature: lineAgsFeature,
          layer: null,
          updateGfxLayer: null,
          updateGfxLayerIndex: null,
          transform: false,            
          annotation: null          
        });       
            
        line.geometry.typeEx = lineState.geometryTypeEx; 
      }                   

      let annotation = Vue.observable({
        type: state.type,
        id: state.id,
        end: end,
        label: label,
        line: line
      })

      end.annotation = annotation;
      label.annotation = annotation;        
      if (annotation.line) {
        annotation.line.annotation = annotation;
      }

      return annotation;
    },

    restoreMeasurementState(state) {            
      
      let agsFeature = this.agsGraphic.fromJSON(state.agsGraphicJSON);

      let measurement = Vue.observable(
      {
        type: "measurement",          
        id: state.id ? state.id : this.uuid(),  // Fix lack of id.
        locationVisibleOnMap: state.locationVisibleOnMap,          
        lengthVisibleOnMap: state.lengthVisibleOnMap,
        sideLengthsVisibleOnMap: state.sideLengthsVisibleOnMap,
        areaVisibleOnMap: state.areaVisibleOnMap,
        lengthUnit: state.lengthUnit,
        areaUnit: state.areaUnit,                  
        agsFeature: agsFeature,
        geometry: agsFeature.geometry,                          
        symbol: state.symbol,                
        isSelected: false,    
        isSearchResult: false,           
        isInSelectionRange: false,
        isCreating: false,                 
        agsFeature: agsFeature,
        layer: null,
        updateGfxLayer: null,
        updateGfxLayerIndex: null,
        transform: false              
      });        
        
      measurement.geometry.typeEx = state.geometryTypeEx;                                           
      return measurement;
    },          
  },
};

export default mappingBrowserPersistance;

</script>