import axios from 'axios';
import pako from 'pako'
import Moment from 'moment';
import { _ } from 'core-js';

export default class MBAPIService {
  
  constructor(clientVersion, msalService, endpoint, scope){

    this.clientVersion = clientVersion;    
    this.msalService = msalService;
    this.config = {
     endpoint: endpoint,     
      requestObject:{
        scopes: [scope]
      }     
    }
  }

  getToken(){
    const accessToken = this.msalService.getToken(this.config.requestObject);
    return accessToken;
  }

  async getArcGISTokens(){
    const accessToken = await this.msalService.getToken(this.config.requestObject);
   
    let response = await axios.get(`${this.config.endpoint}/ArcGISToken`,{
      headers: {
        Authorization: 'Bearer ' + accessToken 
      }
    });
    return response.data;
  }

  async getLocatorHubToken(){
    const accessToken = await this.msalService.getToken(this.config.requestObject);
   
    let response = await axios.get(`${this.config.endpoint}/LocatorHubToken`,{
      headers: {
        Authorization: 'Bearer ' + accessToken 
      }
    });
    return response.data;
  }
 
  async getConfig(){
    
    const accessToken = await this.msalService.getToken(this.config.requestObject);    
      
    let response;
    let url = `${this.config.endpoint}/Configuration?clientVersion=${this.clientVersion}`;    
    
    try {
      response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken 
        }
      });
    } catch (e) {
      console.log(e);
      let error =  "Could not get project. " + e;
      throw new Error(error);  
    }

    return response.data;
  }  

  

  async getMinClientVersion(){
    
    const accessToken = await this.msalService.getToken(this.config.requestObject);    
      
    let response;
    let url = `${this.config.endpoint}/App/GetMinClientVersion`;    
    
    try {
      response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken 
        }
      });
    } catch (e) {
      console.log(e);
      let error =  "Could not get min client version. " + e;
      throw new Error(error);  
    }

    return response.data;
  }  

  async addEvent(type, details) {
    const accessToken = await this.msalService.getToken(this.config.requestObject);   
    let url = `${this.config.endpoint}/App/AddEvent?type=${type}`

    if (details) {
      url += `&details=${details}`
    }
    
    let response;

    try {      

      response = await fetch(url, {
        keepalive: true,       // setting to true, is required if saving from beforeUnload.  However, this has a 64k body limit.  
        method: 'POST',
        headers: {          
          'Authorization': `Bearer ${accessToken}`,
        },        
      });               

      if (!response.ok) {
        let error =  "Could not send close. " + response.status + ". " + response.statusText + ". " + url
        throw new Error(error);  
      }           
    }
    catch (e) {      

      console.log(e);
      let error =  "Could not send close. " + e;
      throw new Error(error);  
    }        
  }


  async setMinClientVersion(version) {
    const accessToken = await this.msalService.getToken(this.config.requestObject);   
    let url = `${this.config.endpoint}/App/SetMinClientVersion?version=${version}`;
    
    let response;

    try {      

      response = await fetch(url, {
        keepalive: false,       // setting to true, is required if saving from beforeUnload.  However, this has a 64k body limit.  
        method: 'POST',
        headers: {          
          'Authorization': `Bearer ${accessToken}`,
        },        
      });               

      if (!response.ok) {
        let error =  "Could not set min client version. " + response.status + ". " + response.statusText + ". " + url
        throw new Error(error);  
      }           
    }
    catch (e) {      

      console.log(e);
      let error =  "Could not set min client version. " + e;
      throw new Error(error);  
    }        
  }

  async getDownForMaintenance(){
    
    const accessToken = await this.msalService.getToken(this.config.requestObject);    
      
    let response;
    let url = `${this.config.endpoint}/App/GetDownForMaintenance`;    
    
    try {
      response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken 
        }
      });
    } catch (e) {
      console.log(e);
      let error =  "Could not get for maintenance. " + e;
      throw new Error(error);  
    }

    return response.data;
  }  

  async setDownForMaintenance(maintType) {
    const accessToken = await this.msalService.getToken(this.config.requestObject);   
    let url = `${this.config.endpoint}/App/SetDownForMaintenance?maintType=${maintType}`;
    
    let response;

    try {      

      response = await fetch(url, {
        keepalive: false,       // setting to true, is required if saving from beforeUnload.  However, this has a 64k body limit.  
        method: 'POST',
        headers: {          
          'Authorization': `Bearer ${accessToken}`,
        },        
      });               

      if (!response.ok) {
        let error =  "Could not set down for maintenance flag. " + response.status + ". " + response.statusText + ". " + url
        throw new Error(error);  
      }           
    }
    catch (e) {      

      console.log(e);
      let error =  "Could not set down for maintenance. " + e;
      throw new Error(error);  
    }        
  }

  async getAllUsers(oldProjectDate) {
    
    const accessToken = await this.msalService.getToken(this.config.requestObject);    
      
    let response;

    let dateString = oldProjectDate ? oldProjectDate.toISOString() : "";
    let url = `${this.config.endpoint}/User/GetAll?oldProjectDate=${dateString}`;    
    
    try {
      response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken 
        }
      });
    } catch (e) {
      console.log(e);
      let error =  "Could not get all users. " + e;
      throw new Error(error);  
    }

    return response.data;
  }  

  async getUser(oldProjectDate) {
    
    const accessToken = await this.msalService.getToken(this.config.requestObject);    
      
    let response;
    let dateString = oldProjectDate ? oldProjectDate.toISOString() : "";
    let url = `${this.config.endpoint}/User?oldProjectDate=${dateString}`;    
    
    try {
      response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken 
        }
      });
    } catch (e) {
      console.log(e);
      let error =  "Could not get user. " + e;
      throw new Error(error);  
    }

    return response.data;
  }  

  async getSessionProject(dateTime) {    
    const accessToken = await this.msalService.getToken(this.config.requestObject);    
      
    let response;
    let dateString = dateTime.toISOString();
    let url = `${this.config.endpoint}/Project?dateTime=${dateString}&clientVersion=${this.clientVersion}`;    

    try {
      response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken 
        }
      });
    } catch (e) {
      console.log(e);
      let error =  "Could not get project. " + e;
      throw new Error(error);  
    }

    if (response.data === "") {
      return null;      
    }      
    
    return response.data;
  }  

  async getFeatures(layerId) {    
    const accessToken = await this.msalService.getToken(this.config.requestObject);    
      
    let response; 
    let url = `${this.config.endpoint}/Project/GetFeatures?layerId=${layerId}&clientVersion=${this.clientVersion}`;

    try {
      response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken 
        }
      });
    } catch (e) {
      console.log(e);
      let error =  "Could not get private features. " + e;
      throw new Error(error);  
    }

    if (response.data === "") {
      return null;      
    }      
        
    let compressedFeatures = response.data;
    
    let binary = atob(compressedFeatures);

    var rawLength = binary.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for(let i = 0; i < rawLength; i++) {
      array[i] = binary.charCodeAt(i);
    }
        
    let featuresJson =  pako.inflate(array, {to: "string"});         
    let features = JSON.parse(featuresJson);    
    return features;
  }  

  async createLayer(layer){

    const accessToken = await this.msalService.getToken(this.config.requestObject);   
    let url = `${this.config.endpoint}/Project/CreateLayer?layerId=${layer.id}&clientVersion=${this.clientVersion}`;
    
    let jsonFeatures =  JSON.stringify(layer.features);    
    let compressedFeatures = pako.gzip(jsonFeatures);           
    
    let layerBlob = new Blob([compressedFeatures]);
    var formData = new FormData();
    formData.append('features', layerBlob);    

    let response;

    try {      

      response = await fetch(url, {
        keepalive: false,       // setting to true, is required if saving from beforeUnload.  However, this has a 64k body limit.  
        method: 'POST',
        headers: {          
          'Authorization': `Bearer ${accessToken}`,
        },
        body: formData
      });               

      if (!response.ok) {
        let error =  "Could not create layer. " + response.status + ". " + response.statusText + ". " + url
        throw new Error(error);  
      }             
    }
    catch (e) {      

      console.log(e);
      let error =  "Could not create layer. " + e;
      throw new Error(error);  
    }        
  }

  async deleteLayer(layer){

    const accessToken = await this.msalService.getToken(this.config.requestObject);   
    let url = `${this.config.endpoint}/Project/DeleteLayer?layerId=${layer.id}&clientVersion=${this.clientVersion}`;
        
    let response;

    try {      

      response = await fetch(url, {
        keepalive: false,       // setting to true, is required if saving from beforeUnload.  However, this has a 64k body limit.  
        method: 'POST',
        headers: {          
          'Authorization': `Bearer ${accessToken}`,
        },    
      });               

      if (!response.ok) {
        let error =  "Could not delete layer. " + response.status + ". " + response.statusText + ". " + url
        throw new Error(error);  
      }             
    }
    catch (e) {      

      console.log(e);
      let error =  "Could not delete layer. " + e;
      throw new Error(error);  
    }        
  }

  async saveSessionProject(project){

    const accessToken = await this.msalService.getToken(this.config.requestObject);   
    let url = `${this.config.endpoint}/Project?clientVersion=${this.clientVersion}`;
        
    let jsonProject =  JSON.stringify(project);                
    let compressedProject = pako.gzip(jsonProject);               
    
    let projectBlob = new Blob([compressedProject]);
    var formData = new FormData();
    formData.append('compressedProject', projectBlob);    

    let response;

    try {      

      response = await fetch(url, {
        keepalive: false,       // setting to true, is required if saving from beforeUnload.  However, this has a 64k body limit.  
        method: 'POST',
        headers: {          
          'Authorization': `Bearer ${accessToken}`,
        },
        body: formData
      });               

      if (!response.ok) {
        let error =  "Could not save project. " + response.status + ". " + response.statusText + ". " + url
        throw { message: error}  
      } 
      
      let lastUpdated = await response.json();
      return Moment.utc(lastUpdated).toDate();                  
    }
    catch (e) {      

      console.log(e);
      let error =  "Could not save project. " + e;
      throw { message: error}  
    }        
  }

  async getLayerMeta(servicePath, path){
    
    const accessToken = await this.msalService.getToken(this.config.requestObject);    
      
    let response;
    let url = `${this.config.endpoint}/ArcGIS/GetLayerMeta?servicePath=${servicePath}&path=${path}`;    
    
    try {
      response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken 
        }
      });
    } catch (e) {
      console.log(e);
      let error =  "Could not get meta-data for layer. " + e;
      throw new Error(error);  
    }
        
    if (response.data === "") {
      return null;      
    }      
    
    return response.data;
  }  
}