<template>  
  <div class="topLeftToolbar d-flex">   
    <button 
    @click="previousItem" 
    :class="{inactive: !previousEnabled}"     
    class="button-border-top button-border-bottom radius-bottom-left" 
    v-b-popover.hover.top="'Select previous item'">              
      <font-awesome-icon transform="grow-6" icon="arrow-left"/>                                      
    </button>                             
    <div class="seperatorContainer button-border-top button-border-bottom d-flex align-items-center"><div class="seperator ml-1 mr-1"></div></div>             
    <button 
    @click="nextItem" 
    :class="{inactive: !nextEnabled}"     
    class="button-border-right button-border-top button-border-bottom radius-bottom-right" 
    v-b-popover.hover.top="'Select next item'">              
      <font-awesome-icon transform="grow-6" icon="arrow-right"/>                                    
    </button>    
  </div>
</template>

<script>

export default {               
  props: {
    previousEnabled: {
      type: Boolean,      
    },
    nextEnabled: {
      type: Boolean,
    }
  },
  methods: {
    previousItem() {
      if (this.previousEnabled) {
        this.$emit("previousItem");
      }      
    },

    nextItem() {
      if (this.nextEnabled) {
        this.$emit("nextItem");
      }      
    }
  }
}

</script>

<style lang="scss" scoped>
@import "../variables";

.topLeftToolbar {
  color: $textColour;        
  height: 48px;

  button
  {  
    font-size: 16px;
    border:none;    
    color: inherit;
    background:$panelColour;
    width: 48px;

    &:hover {        
      color: $textColourHover
    }  
  
    &.inactive
    {
      svg {
        color: $pColourDarkest;
      }        
    }    
    &.radius-top-left {      
      border-radius: 10px 0px 0px 0px;
    }

    &.radius-top-right {      
      border-radius: 0px 10px 0px 0px;
    }

    &.radius-bottom-right {      
      border-radius: 0px 0px 10px 0px;
    }

    &.radius-bottom-left {      
      border-radius: 0px 0px 0px 10px;
    }

    &.radius-bottom-left-and-right {      
      border-radius: 0px 0px 10px 10px;
    }    

    &.radius-top-left-and-right {      
      border-radius: 10px 10px 0px 0px;
    }
  }
  
  .button-border-left {
    border-left-style: solid;
  }

  .button-border-top {
    border-top-style: solid;
  }

  .button-border-right {
    border-right-style: solid;
  }

  .button-border-bottom {
    border-bottom-style: solid;
  }
  
  .seperatorContainer, button {    
    border-width: 2px;
    border-color: rgba(10, 10, 10, .2);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  } 

  .seperatorContainer {            
    height: 48px;
    background-color: $panelColour;

    .seperator {          
      height: 28px;
      width: 1px;
      background-color: $controlSeperator
    };
  };
}

</style>