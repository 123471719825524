<template>
	<div ref="tree" style="padding-right: 12px" :class="{'d-flex': layers}" class="h-100 layersList flex-column">                             
		<TreeLayerItem
    ref="rootItem"	                                                         
		:item="root"
    :root="root"
		:depth="-1"
		:key="root.id"
		:filterString="filterString"
		:showVisibleOnly="showVisibleOnly"
		:showFavouritesOnly="showFavouritesOnly"    
    :showLegendSymbology="showLegendSymbology"
    :orderAlphabetically="orderAlphabetically"
    :enableReorder="enableReorder"    
		@info="info"
		@layerVisibilityChange="layerVisibilityChange"
    @reorderLayerItem="$emit('reorderLayerItem', $event)"    
    @importIntoLayer="$emit('importIntoLayer', $event)"      
    @deleteLayer="$emit('deleteLayer', $event)"      
    @editLayer="$emit('editLayer', $event)"              
    >
		</TreeLayerItem>                             
	</div>                                             
</template>

<script>
import Vue from "vue";
import TreeLayerItem from "./LayerTreeItem.vue";    

export default {
  components: { TreeLayerItem }, 
  props: {    
    layers: {
      type: Array,       
    },
    filterString: { 
      type: String,
      default: ""      
    },    
    showVisibleOnly: {
      type: Boolean,
    },
    showFavouritesOnly: {
      type: Boolean,
    },         
    showLegendSymbology: {
      type: Boolean,
      default: true
    },
    orderAlphabetically: {
      type: Boolean
    },   
    enableReorder: {
      type: Boolean
    }
  },  
  computed: {
    root()
    {            
        return {
            id: -1,
            title: "root",
            sublayers: this.orderedSubLayers
        }
    },     
    orderedSubLayers() {      

      if (this.orderAlphabetically) {                  
        return [...this.layers].sort((a, b) => a.title.localeCompare(b.title));
      } else {
        return this.layers;
      }
    }
  },
  methods: {          
    info(item) {        
      this.$emit('info', item);
    },    
    layerVisibilityChange(subLayer) {        
      this.$emit('layerVisibilityChange', 
        { subLayer: subLayer, 
          visible: !subLayer.visible 
        })
    }    
  }
}    
</script>

<style lang="scss" scoped>

.layersList
{
    overflow-x: hidden;
    overflow-y: auto;    
}

</style>