<script>

import { agsPointToCoordString } from "./SpatialUtils.js";
import { loadModules } from "esri-loader";
import Moment from 'moment';

var featureMixin = {    
  created() {
    loadModules(["esri/geometry/Extent"]).then(([
      Extent      
    ]) => {
      this.agsExtent = Extent
    })
  },
  data() {
    return {
      agsExtent: null,      
    }
  },
  methods: {

    hasLayerProperties(layer) {
      return layer && layer.type !== 'OS' && layer.rootLayer.id !== 'privateLayers' && layer.rootLayer.id !== 'createdFeatures';
    },

    canDeleteFeature(feature) {            
      return (feature.type === "userFeature" || feature.type === "measurement" || feature.type === "annotationEnd");
    },  
    isFeatureTransformable(feature) {
      return (feature.type === "userFeature" || feature.type === "measurement" || feature.type === "annotationEnd");
    },

    getLocationString(feature, coordinateMode) {
        
      if (feature.geometry.extent) {
        return agsPointToCoordString(feature.geometry.extent.center, coordinateMode);
      } else {
        return agsPointToCoordString(feature.geometry, coordinateMode);
      }

      return "";
    },   

    getLengthString(geometry, lengthUnit) {        
      let length = this.agsGeometryEngine.planarLength(geometry, lengthUnit);          
      let rounded = length.toFixed(2);
      return rounded;
    },

    getAreaString(feature, areaUnit) {        
      let area = this.agsGeometryEngine.planarArea(feature.geometry, areaUnit);          
      let rounded = Math.abs(area.toFixed(2));
      return rounded;
    },    

    canZoomToFit(feature) {      
      if (feature.layer && feature.layer.type === 'OS') {
        return true;
      } else {
        return ((feature.geometry && feature.geometry.extent));      
      }
    },
    
    getFeatureId(feature) {
      if (feature.layer) {
        return feature.attributes[feature.layer.objectIdPropertyName];
      } else {
        return feature.id;
      }
    },
    
    getGlobalFeatureId(feature) {
      if (feature.layer) {
        return feature.layer.globalId + "_MBID_" + feature.id;
      } else {
        return feature.id
      }
    },

    getFeatureCodedValue(feature, field, value) {
      if (field.domain) {
        if (field.domain.type === "codedValue") {
          let val = field.domain.codedValues.find(codedValue => codedValue.code === value);
          if(val) {
            return val.name;
          }
        }
      }

      return value;      
    },

    getExtentForFeatures(features, padding = true) {     
        
      let totalExtent;

      let geometries = features.map(feature => feature.geometry);

      geometries.map(geometry => {

        let extent;

        if(geometry.type == "point") {         

          // for xmax and xmin etc. 
          var xmin = geometry.x; 
          var xmax = geometry.x; 
          var ymax = geometry.y; 
          var ymin = geometry.y; 

          extent = new this.agsExtent(xmin, ymin, xmax, ymax, geometry.spatialReference);                     
        } else {
          extent = geometry.extent;
        }

        if (!totalExtent)  
          totalExtent = extent;
        else         
          totalExtent.union(extent);                   
      })

      if (padding)
        return totalExtent.expand(this.extentPadding);
      else 
        return totalExtent;
    },    
  }
}

export default featureMixin;

</script>