let arcGISVersion = "4.24";     // Arcgis JS version

import Vue from "vue";
import App from "@/components/App.vue";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import Resizable from './components/shared/Resizable.vue'
import Slidable from './components/shared/Slidable.vue'
import PortalVue from 'portal-vue'
import { setDefaultOptions } from 'esri-loader';
import Velocity from 'velocity-animate'
import { default as globalMixin } from "./components/GlobalMixin.vue"
import { loadCss } from "esri-loader";
import VueRouter from 'vue-router';

loadCss(`https://js.arcgis.com/${arcGISVersion}/esri/css/main.css`);
import "@/index.scss"; //moved below boostrap import.

//  duotone
import {   
  faSpinnerThird,      
} from '@fortawesome/pro-duotone-svg-icons';


// Pro light

import {         
  faSquareDashed,
  faGameBoard,
  faRulerCombined,
  faDrawPolygon, 
  faTimes,
  faGlobe,
 // faCube, 
  faTable,           
} from '@fortawesome/pro-light-svg-icons';


// Pro-regular  
import {         
  faChevronUp,
  faChevronDown,
  faSearchPlus,
  faSearchLocation,  
  faDotCircle,
  faList,
  faUndoAlt,
  faRedoAlt,
  faCircle,
  faSquare,
  faTriangle,
  faWaveSquare,
  faFont,
  faThList,  
  faPlus as farPlus,
  faChevronSquareLeft,
  faSlidersH,
  faExternalLinkSquare,  
  faInfoCircle as farInfoCircle
} from '@fortawesome/pro-regular-svg-icons';

// Pro-solid
import {          
  faArrowRotateLeft,
  faObjectUnion,
  faObjectUngroup,
  faCaretCircleUp,
  faRulerTriangle,  
  faExclamationSquare,
  faWaveSine,
  faShareAlt,
  faInfoCircle,
  faSlidersV,
  faSlidersVSquare,
}from '@fortawesome/pro-solid-svg-icons';

// Free-solid (fas)
import {         
  faBan,
  faRunning,
  faCrop,
  faEllipsisH,       
  faEllipsisV,         
  faRulerVertical,  
  faCaretSquareDown,
  faGripLines,
  faGripLinesVertical,
  faArrowDown,
  faArrowUp,
  faClipboardList,    
  faMap,  
  faFileExport,
  faSort,
  faSortUp,
  faSortDown,
  faGlasses,
  faPoop,
  faCircle as fasCircle,
  faMapMarkerAlt,
  faArrowLeft,
  faArrowRight,
  faTrashAlt,
  faSkull,
  faLocationArrow,
  faUserSecret,
  faTh,
  faAlignJustify,
  faThLarge,
  faLayerGroup,
  faImage,
  faPlus,
  faMinus,
  faSyncAlt,
  faPalette,
  faFillDrip,
  faHome,  
  faComment,
  faSearch,
  faCog,
  faBullseye,
  faCube,
  faCaretRight,    
  faCaretLeft,    
  faCaretDown,      
  faCaretUp,      
  faCheckSquare,
  faBug,  
  faSpinner,  
  faBookmark,
  faClipboard,
  faAdjust,
  faExpand,
  faThumbtack,
  faEye,
  faEyeSlash,  
  faFish,    
  faGlobeEurope,
  faDatabase,
  faAsterisk,
  faTools,
  faHammer,
  faRuler,
  faHandPointer,
  faPencilAlt,
  faRoute,
  faQuestionCircle,
  faAddressBook,  
  faGraduationCap,
  faSnowflake,
  faStar,
  faVectorSquare,
  faEraser,
  faBezierCurve,
  faMousePointer,
  faExpandArrowsAlt,
  faExclamation,
  faArrowAltCircleRight,
  faBars,
  faFileDownload,
  faMapMarked,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";

// Free-regular
import {        
  faCompass,
  faUser,
  faUserCircle,
  faPlusSquare,  
	faStar as farStar,
  faEye as farEye,
  faObjectUngroup as farObjectUngroup
} from "@fortawesome/free-regular-svg-icons";

// Free-brands
import {
   faGoogle,
} from "@fortawesome/free-brands-svg-icons";

// Add to lib
library.add( 
  faArrowRotateLeft,  
  faRunning,
  faBan,
  faGameBoard,
  faCrop,  
  faCircle,  
  faChevronUp,
  faChevronDown,  
  faRulerCombined,
  faRulerVertical,
  faDrawPolygon,
  faSearchPlus,
  faExternalLinkSquare,
  faCaretSquareDown,
  faSlidersVSquare,
  faSlidersH,
  faGripLines,  
  faGripLinesVertical,
  faSlidersV,
  faSpinnerThird,
  faArrowDown,
  faArrowUp,
  faClipboardList,
  faTable,
  farObjectUngroup,
  faFileExport,
  faSort,
  faSortUp,
  faSortDown,
  faGlasses,
  faEllipsisH,
  faEllipsisV,
  faShareAlt,
  faWaveSine,
  faObjectUngroup,
  faThList,
  faPoop,
  faFont,
  fasCircle,
  faArrowAltCircleRight,
  faSearchLocation,
  faMapMarkerAlt,
  faExclamationSquare,
  farPlus,
  faPlusSquare,
  faDotCircle,
  faWaveSquare,
  faDrawPolygon,
  faTimes,
  faRulerTriangle,  
  faTrashAlt,
  faSkull,
  faUndoAlt,
  faRedoAlt,
  faLocationArrow,
  faCircleNotch,
  faCircle,
  faTriangle,
  faUserSecret,
  faTh,
  faAlignJustify,
  faThLarge,
  faLayerGroup,
  faImage,
  faPlus,
  faMinus,
  faSyncAlt,
  faPalette,
  faFillDrip,
  faTrashAlt,
  faCompass,
  faHome,  
  faComment,
  faUser,
  faUserCircle,
  faSearch,
  faCog,
  faBullseye,
  faCube,
  faCaretRight,
  faCaretLeft,
  faCaretDown,  
  faCaretUp,  
  faSquare,
  faCheckSquare,
  faBug,
  faGlobe,
  faSpinner,  
  faGoogle,
  faBookmark,
  faClipboard,
  faAdjust,
  faArrowLeft,
  faArrowRight,
  faExpand,
  faThumbtack,
  faEye,
  faMap,
  faEyeSlash,
  faFish,    
  faGlobeEurope,
  faDatabase,
  faAsterisk,
  faTools,
  faHammer,
  faRuler,
  faHandPointer,
  faPencilAlt,
  faRoute,
  faQuestionCircle,
  faAddressBook,
  faGraduationCap,
  faSnowflake,
  faStar,
  farStar,
  farEye,
  faVectorSquare,
  faEraser,
  faBezierCurve,
  faMousePointer,
	faExpandArrowsAlt,
  faExclamation,
  faList,
  faBars,
  faFileDownload,
  faMapMarked,
  faChevronSquareLeft,
  faInfoCircle,
  farInfoCircle,
  faSquareDashed,
  faObjectUnion
);

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('Resizable', Resizable)
Vue.component('Slidable', Slidable)
Vue.config.devtools = true
Vue.config.productionTip = false;
Vue.config.performance = true;

Vue.config.errorHandler = (err, vm, info) => {
  console.error(err);
};

const largeScreenSize = 1536;

let appGlobals = Vue.observable({  
  appVersion: import.meta.env.PACKAGE_VERSION,
  environment: import.meta.env.VITE_MB_ENV,  
  isLargeScreen: window.innerWidth > largeScreenSize,    
  isRestoringState: false,    
})

Vue.prototype.$appGlobals = appGlobals;

console.log("VERSION " + Vue.prototype.$appGlobals.appVersion);

console.log("ENVIRONMENT:");
console.log(import.meta.env);

console.log("APP GLOBALS:");
console.log(Vue.prototype.$appGlobals);

window.addEventListener("resize", () => {      
  Vue.prototype.$appGlobals.isLargeScreen = window.innerWidth > largeScreenSize
});

Vue.prototype.copyToClipboard = function copyToClipboard(text) {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

let bootstrapVueOptions = {
  BPopover: {
    //delay: { "show": 1000, "hide": 50 }    // Too slow to bring up colour picker.
  }
}

Vue.use(BootstrapVue, bootstrapVueOptions);
Vue.use(PortalVue);
// Vue.use(VueRouter);

// const routes = [
//   {
//     path: '/',
//     name: 'Mapping Browser',
//     component: App
//   },
//   {
//     path: '/admin',
//     name: 'admin',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component:  () => import(/* webpackChunkName: "admin" */ './components/Admin/Admin.vue')
//   }
// ]

// const router = new VueRouter({
//   routes
// })

Array.prototype.diff = function(array) { 
  
  let map = new Map();
  array.forEach(function(obj){
    map.set(obj, true);
  });

  // Return all elements in A, unless in B
  return this.filter(function(obj){
    return !(map.has(obj));
  });
};

// Hide method from for-in loops
Object.defineProperty(Array.prototype, "diff", {enumerable: false});

Array.prototype.distinct = function() { 
  
  let set = new Set();
  this.forEach(element => {
    set = set.add(element);
  })
  return [...set];
};

// Hide method from for-in loops
Object.defineProperty(Array.prototype, "distinct", {enumerable: false});

Array.prototype.equals = function (array) {
    // if the other array is a falsy value, return
    if (!array)
        return false;

    // compare lengths - can save a lot of time 
    if (this.length != array.length)
        return false;

    for (var i = 0, l=this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i]))
                return false;       
        }           
        else if (this[i] != array[i]) { 
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;   
        }           
    }       
    return true;
}

// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", {enumerable: false});


Vue.directive('focusOnInsert', {
  inserted: function (el) {
    el.focus()
  }
})

Vue.directive('sizeToPlaceholder', {
  inserted: function (el) {
    let placeholder = el.getAttribute('placeholder');
    if (placeholder) {
      el.setAttribute('size', placeholder.length);
    }
  }
})

Vue.directive('selectOnInsert', {
  inserted: function (el) {
    el.select();
  }
})

Vue.mixin(globalMixin);

setDefaultOptions({ 
  version: arcGISVersion, 
});


new Vue({
  name:"Mapping Browser",
  version: "0.0.1",    
  data: {mbMap: null, mbMapView: null, mbApiService: null, mbUser: null },
  errorCaptured: function(err, component, details) {
    console.error(err);
  },  
  render: h => h(App),      
}).$mount("#root");
