<template>
  <div ref='overviewMap' id='overviewMap' :class="{ isFullScreen:isFullScreen }">
    <div id="overviewMapContainer">
      <div class="componentsContainer">
        <div class="overviewMapButton">
          <DropdownSelect 
          alignRight
          boundary="viewport"
          v-b-popover.hover.top="'Choose Map'" 
          :value="value"
          @input="$emit('input', $event)"
          :icon="['fas', 'caret-square-down']" nocaret 
          :items="basemapMenuItems">
          </DropdownSelect>
        </div>                
        <div class="extentContainer">
          <div ref='overviewMapExtent' id="overviewMapExtent"></div>       
        </div>                        
      </div>
    </div>
  </div>
</template>

<script>
import { loadModules } from "esri-loader";
import DropdownSelect from "./shared/DropdownSelect.vue";
export default {
  components: { DropdownSelect}, 
  props: {
    value: {
      type: Object
    },
    arcGISBaseUrl: {
      type: String
    },
    basemaps: {
      type: Array,
      required: true
    },   
    parentMapViewLeftOffset: {
      type: Number,      
    },
    parentMapViewTopOffset: {
      type: Number,      
    },
    parentMapViewRightOfset: {
      type: Number,      
    },
    parentMapViewBottomOffset: {
      type: Number,      
    },
    isFullScreen: {
      type: Boolean,
      requied: true
    }
  },
  data: function(){
    return {    
      overviewMap: null,
      esriPoint : null, //annoyingly the ags autocast seems to not work for map center (point/sr)
      esriTileLayer: null,
      container: "overviewMapContainer",      
    }
  }, 
  created: function() {
    loadModules([
    "esri/Map",
    "esri/layers/TileLayer",
    "esri/views/MapView",
    "esri/Basemap",
    "esri/geometry/Point"      
    ]).then(
      ([
        Map,
        TileLayer,
        MapView,
        Basemap,
        Point
      ]) => {

        this.esriPoint = Point;                
        this.esriTileLayer = TileLayer;

        const basemap = new Basemap({baseLayers:[]});

        const map = new Map({
          basemap: basemap
        });

        this.overviewMap = new MapView({
          container: this.container,
          map: map,
          ui: {
            components: []
          },       
        })     
        this.overviewMap.when(_ => {   

            // Esri move all nodes defined under its mapView container to a esri-user-storage node
            // Move these to esri-view-root.            
            let componentsContainer = document.querySelector("#overviewMapContainer > .esri-view-user-storage > .componentsContainer");
            let overviewViewRoot = document.querySelector("#overviewMapContainer > .esri-view-root");						
            overviewViewRoot.appendChild(componentsContainer); 			              

            this.mapView.watch("extent",this.updateOverview);                                                
            this.updateBasemap();
            this.updateOverview();
        });

        this.overviewMap.on("drag",(e)=>{e.stopPropagation();})
        this.overviewMap.on("key-down",(e)=>{e.stopPropagation();})
        this.overviewMap.on("mouse-wheel",(e)=>{e.stopPropagation();})
        this.overviewMap.on("double-click",(e)=>{e.stopPropagation();})

        this.overviewMap.on("drag",["Shift"],(e)=>{e.stopPropagation();}) //? Not working
        this.overviewMap.on("drag",["Shift","Control"],(e)=>{e.stopPropagation();}) //? Not working
        this.overviewMap.on("pointer-move",["Shift"],(e)=>{e.stopPropagation();}) //? Not working
        this.overviewMap.on("pointer-move",["Shift","Control"],(e)=>{e.stopPropagation();}) //? Not working
        this.overviewMap.on("double-click",["Control"], (e)=>{e.stopPropagation();}) //? Not working
        this.updateBasemap();
        this.updateOverview();  
      });
    },    
    mounted() {
        this.respondToVisibility(this.$refs.overviewMap, visible => {                   
          this.updateOverview();                 
        })
    },
    watch: {
      value(value) {
        this.updateBasemap();
        this.updateOverview();
      }
    },
    computed: {
      basemapMenuItems() {
        return this.basemaps.map(basemap => {
          return { 
            text: basemap.title,
            value: basemap
          }
        });
      }
    },
    methods:{      
      
      updateBasemap() { 
        
        if (this.overviewMap) {          

          if (this.value) {
            this.overviewMap.map.basemap.baseLayers = [
              new this.esriTileLayer({ 
                id: this.value.id, 
                url: this.arcGISBaseUrl + '/' + this.value.arcGISPath,
                visible: true,
                opacity: 1,
                title: this.value.title,
                copyright: this.value.copyright
              })]
          } else {
            this.overviewMap.map.basemap.baseLayers = [];
          }
        }            
      },
    
      updateOverview(){ 
        if(this.overviewMap && this.$el.offsetParent) {

           let bottomLeftPoint = new this.esriPoint({
            x: this.mapView.extent.xmin,
            y: this.mapView.extent.ymin,
            spatialReference: this.mapView.spatialReference
          });
          let topRightPoint = new this.esriPoint({
            x: this.mapView.extent.xmax,
            y: this.mapView.extent.ymax,
            spatialReference: this.mapView.spatialReference
          });
          let bottomLeft = this.overviewMap.toScreen(bottomLeftPoint);
          let topRight = this.overviewMap.toScreen(topRightPoint);

          if (this.$refs.overviewMapExtent && topRight && bottomLeft)
          {
            this.$refs.overviewMapExtent.style.top = topRight.y + "px";        
            this.$refs.overviewMapExtent.style.left = bottomLeft.x + "px";
            this.$refs.overviewMapExtent.style.height = (bottomLeft.y - topRight.y) + "px";
            this.$refs.overviewMapExtent.style.width = (topRight.x - bottomLeft.x) + "px"; 
          }

          let point = new this.esriPoint({
            x: this.mapView.center.x,
            y: this.mapView.center.y,
            spatialReference: this.mapView.spatialReference
          });
          
          this.overviewMap.scale = this.mapView.scale * 2 * Math.max(this.mapView.width /this.overviewMap.width, this.mapView.height / this.overviewMap.height),
          this.overviewMap.center = this.mapView.center;                         
      }
    }
  }
}
</script>

<style lang="scss">
@import "./../variables";
#overviewMap {      

  background-color:$panelContent;
  position: absolute;
  width: 260px;
  height: 180px;    
  box-shadow: 0 0 0 3px rgba(10, 10, 10, 0.25);    
  z-index: 1;

  bottom: 8px;
  right: 8px;

  &.isFullScreen {      
    bottom: 26px;
    right: 26px;
  }

  #overviewMapContainer {
    height: 100%;

    .componentsContainer {          
      height: 100%;        
    }

    .extentContainer {
      position: relative;
      overflow: hidden;
      height: 100%;
      pointer-events: none;   
    }

    #overviewMapExtent {
      background-color: rgba(10, 10,10, 0.10);
      border:2px solid $pColourDark;
      position: absolute;
      z-index: 2;
      overflow: hidden;
      height: 100%;    
      pointer-events: none;  
    }
    
    .overviewMapButton{
      position: absolute;
      right:0px;
      color:white;
      background-color: rgba(10, 10,10, 0.30);
    }
  }  
}
 
</style>
