<template> 
  <div>            
    <b-modal id="layerEdit"
    ref="layerEdit"
    :ok-disabled="invalidMessage !== null"    
    @shown="layerEditShown" 
    @ok="okButtonClicked" 
    @hidden="layerEditHidden"         
    centered>  
    <template v-if="layer">   
      <template slot="modal-title">      
        <div class="d-flex justify-content-center align-items-center">          
          <font-awesome-icon icon="layer-group" class="mr-2" transform="grow-8"/>Edit layer
        </div> 
      </template>            
      <div class="d-flex mt-2 mx-5 groupPanel">
        <div class="d-flex flex-column options mr-4 labels">
          <b-popover     
          :id="_uid + 'nameLabelPopover'"             
          triggers="manual"       
          placement="top"          
          :target="_uid + 'nameLabel'"          
          > 
          {{invalidMessage}}          
          </b-popover>
          <span :id="_uid + 'nameLabel'" class="my-2">Name:</span>                  
          <span class="my-2">Colour:</span>                  
          <span class="my-2">Style:</span>              
          <span class="my-2">Size:</span>                                          
        </div>  
        <div class="d-flex flex-column flex-grow-1 options">                    
          <span class="mb-3"><b-form-input :id="_uid + 'nameInput'" ref="nameInput" v-model="name" @keyup.enter="enterButton"/></span>                    
          <b-button class="colorPickerButton mb-3" :style="{height: 14 + 'px', background: symbolColorHex}" size="sm" :id="_uid + 'symbolColor'"></b-button>
          <b-popover
          :show.sync="symbolColorPickerShow" 
          :target="_uid + 'symbolColor'"
          placement="left"
          triggers="click blur">                   
            <CompactColorPicker :value="symbolColorHex" @input="symbolColorChanged"></CompactColorPicker>                  
          </b-popover>          
          <span class="d-flex"><DropdownSelect id="styleDrop" class="flex-grow-1 mb-3" v-model="symbolStyle" :items="styleItems"></DropdownSelect></span>
          <span>
            <vue-slider class="vueSlider" v-model="symbolSize" :duration="0.125" :min="1" :max="24" :hideLabel="true"/>
          </span>                                                      
        </div>  
      </div>                
    </template>
    </b-modal>   
  </div>     
</template>

<script>

import ErrorDialog from './shared/ErrorDialog.vue';
import CompactColorPicker from "./shared/ColorPicker/CompactColorPicker.vue";
import {rgbToHex, hexToRgb}  from "./shared/ColorPicker/CompactColorPicker.vue";
import DropdownSelect from "./shared/DropdownSelect.vue";
import VueSlider from 'vue-slider-component'

export default {	
  components: { ErrorDialog, CompactColorPicker, DropdownSelect, VueSlider},   
  props: {   
    layer: {
      type: Object
    },     
    invalidMessageFn: {
      type: Function
    },
  },    
  data() {
    return {      
      symbolColorPickerShow: false,          
      name: "",   
      symbolColor: {},
      symbolStyle: "",
      symbolSize : 0
    }
  },         
  watch: {
    layer: {
      handler(newVal, oldVal) {
        if (this.layer) {          
          this.name = this.layer.title;
          this.symbolColor = this.layer.symbol.color;
          this.symbolStyle = this.layer.symbol.style;
          this.symbolSize = this.layer.symbol.size;          
        }      
      }, 
      immediate: true      
    },
    invalidMessage() {
      if (this.invalidMessage) {
        this.$root.$emit('bv::show::popover', this._uid + 'nameLabelPopover')
      } else {
        this.$root.$emit('bv::hide::popover', this._uid + 'nameLabelPopover')
      }
    }
  },
  computed: {    
    styleItems() {
      return [
        {text: "Circle", value: "circle"},        
        {text: "Cross", value: "cross"},
        {text: "Diamond", value: "diamond"},
        {text: "Square", value: "square"},
        {text: "Triangle", value: "triangle"},
        {text: "X", value: "x"},
      ]
    },
    symbolColorHex() {      
      return rgbToHex(this.symbolColor.r, 
                      this.symbolColor.g, 
                      this.symbolColor.b, 
                      255);          
    },
    invalidMessage() {      
      return (this.invalidMessageFn(
        this.layer,
        this.name        
      ))
    }   
  },
  methods: { 
    symbolColorChanged(e) {      
      this.symbolColorPickerShow = false;          
      this.symbolColor = e.rgba;
    },  
    show()
    {
      this.$refs.layerEdit.show();
    },
    hide()
    {
      this.$refs.layerEdit.hide();
    },    

    layerEditShown(e) {                              
      if (!this.layer) {
        this.$refs.nameInput.focus()
      }
    },

    handleOK() {    
      this.$emit("ok", {
        layer: this.layer,
        layerName: this.name,
        symbolColor: this.symbolColor,             
        symbolStyle: this.symbolStyle,             
        symbolSize: this.symbolSize,             
      });  
    },   

    layerEditHidden() {    

    },

    enterButton()
    {
      if (this.invalidMessage !== null) {
        return;
      }
      
      this.handleOK();
      this.hide();
    }, 

    okButtonClicked(bvModalEvt){
      this.handleOK();     
    }    
  }  
} 

</script>

<style lang="scss">

@import "././../variables";
 
#layerEdit .modal-dialog {
    max-width: 500px !important;
    margin: 1.75rem auto;
}

#layerEdit #styleDrop .btn {        
  font-size: 16px !important  
}

</style>