import * as Msal from 'msal'

export default class MsalService {

  constructor(clientId, authority) {
        
    let url = new URL(location.href)    
  
    this.msalConfig = {
      auth: {
        clientId: clientId,
        authority: authority, 
        redirectUri: url.origin,
        navigateToLoginRequestUrl: false
      },     
    };    

    this.userAgentApplication = new Msal.UserAgentApplication(this.msalConfig);    
    
    this.requestObject= {
      scopes: ["user.read"]      
    }    
  } 

  signIn(){
    this.userAgentApplication.loginRedirect(this.requestObject);
  }
  
  isAuthenticated() {
    return !!this.userAgentApplication.getAccount(); 
  }

  logout() {
    this.userAgentApplication._user = null
    this.userAgentApplication.logout()
  }

  async getToken(request){
    try {
      const response = await this.userAgentApplication.acquireTokenSilent(request);
      return response.accessToken;
    } 
    catch (e) {
      console.log(e);      
      const response = await this.userAgentApplication.acquireTokenRedirect(request);
      return response.accessToken;      
    }
  }
}