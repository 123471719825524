<template> 
  <b-modal :id="id"     
  @shown="shown"  
  @hide="hide"
  @hidden="$emit('hidden')"
  ok-title="Yes"
  cancel-title="No"
  @ok="$emit('yes', data)"  
  centered>     
    <template slot="modal-title">      
      <div class="d-flex justify-content-center align-items-center">
        <template v-if="icon">
          <font-awesome-icon  :icon="icon" class="mr-2"/>
        </template>
        {{title}}         
      </div>
    </template>
    <div class="d-flex justify-content-center align-items-center">
      {{message}}
    </div>      
  </b-modal>        
</template>

<script>

export default {	
  props: { 
    id: {
      type: String,
      required: true
    },
    icon: null,
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },        
    data: {
      type: Object,
      required: false
    }
  }, 
  methods: {
    shown(e) {      
      document.getElementById(this.id).addEventListener("keyup", this.enterKeyHandler);
    },
    enterKeyHandler(e) {
      // Enter key
      if (e.keyCode === 13) {          
        this.$emit('yes', this.data);
        this.$root.$emit('bv::hide::modal', this.id);
      }        
    },
    hide(e) {      
      document.getElementById(this.id).removeEventListener("keyup", this.enterKeyHandler);     
    },    
  }   
} 

</script>

<style lang="scss">


</style>