<template>
    <nav :style="style" ref="mb-context-menu" id="context-menu" class="context-menu">
        <ul class="context-menu__items">          
          <li class="context-menu__item">
              <a class="context-menu__link" @click="$emit('goto'); $emit('close')" data-action="goto"><span class="context-menu-icon mr-2"><font-awesome-icon class="context-menu-icon" :icon="['fas', 'running']"/></span>{{gotoMenuTitle}}</a>
          </li>          
          <li class="context-menu__item">
            <a class="context-menu__link" @click="$emit('setLocationMarker', coords); $emit('close')" data-action="set-location-marker"><span class="context-menu-icon mr-2"><font-awesome-icon :icon="['fas', 'map-marker-alt']"/></span>Set location marker here</a>
          </li>                      
          <li v-show="showDeleteLocationPinMenuOption" class="context-menu__item">
            <a class="context-menu__link" @click="$emit('deleteLocationPin'); $emit('close')" data-action="delete-location-marker"><span class="context-menu-icon mr-2"><font-awesome-icon icon="trash-alt"/></span>Remove location marker</a>
          </li>                      
          <div class="seperator"/>
          <li class="context-menu__item">
              <a class="context-menu__link"  @click="$emit('copyCoordsToClipboard'); $emit('close')" data-action="coordinates"><span class="context-menu-icon mr-2"><font-awesome-icon class="context-menu-icon" icon="clipboard-list"/></span>Coordinates to Clipboard</a>
          </li>          
          <li v-show="showIdentifyInSelectionMenuOption" class="context-menu__item">
            <a class="context-menu__link" @click="$emit('identifyInSelection'); $emit('close')" data-action="identify-in-selection"><span class="context-menu-icon mr-2"><font-awesome-icon :icon="['fal', 'game-board']"/></span>Use selection for identify area</a>
          </li>                            
          <li class="context-menu__item">
              <a class="context-menu__link" @click="$emit('pinBookmark'); $emit('close')" data-action="pin-bookmark-here"><span class="context-menu-icon mr-2"><font-awesome-icon :icon="['fas', 'bookmark']"/></span>Pin Bookmark here...</a>
          </li>          
          <div class="seperator"/>
          <li class="context-menu__item">
            <a class="context-menu__link" @click="$emit('bingMaps'); $emit('close')" data-action="street-view"><span class="context-menu-icon mr-2"><font-awesome-icon :icon="['fal', 'globe']"/></span>Open in Bing Maps</a>
          </li>      
          <li class="context-menu__item">
              <a class="context-menu__link" @click="$emit('googleMaps'); $emit('close')" data-action="street-view"><span class="context-menu-icon mr-2"><font-awesome-icon :icon="['fab', 'google']"/></span>Open in Google Maps</a>
          </li>            
          <li class="context-menu__item">
              <a class="context-menu__link" @click="$emit('streetView'); $emit('close')" data-action="street-view"><span class="context-menu-icon mr-2"><font-awesome-icon :icon="['fab', 'google']"/></span>Open in Google Street View</a>
          </li>                      
          <li v-show="showMapSheetOption" class="context-menu__item">
            <a class="context-menu__link" @click="$emit('openMapSheet'); $emit('close')" data-action="open-map-sheet"><span class="context-menu-icon mr-2"><font-awesome-icon icon="map"/></span>Open map sheet</a>
          </li>                      
          <div class="seperator"/>
          <li class="context-menu__item">
            <a class="context-menu__link" @click="$emit('clearSelection'); $emit('close')"><span class="context-menu-icon mr-2"><font-awesome-icon icon="ban"/></span>Clear selection</a>
          </li>            
        </ul>
    </nav>
</template>

<script>
export default {
  props: {
    coords: null,
    gotoMenuTitle: null,
    showDeleteLocationPinMenuOption: false,  
    showIdentifyInSelectionMenuOption: false,
    showMapSheetOption: false,        
  },
  
  computed: {
    style() {
      if (this.coords) {                
        let offset = 6;
        
        const menu = this.$refs['mb-context-menu'];
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const menuWidth = menu.offsetWidth + 4;
        const menuHeight = menu.offsetHeight + 4;
  
        let left, top;

        if ( (windowWidth - this.coords.x) < menuWidth ) {        
          left = windowWidth - menuWidth;
        } else {
          left = this.coords.x + offset;
        }
        if ( (windowHeight - this.coords.y) < menuHeight ) {
          top = windowHeight - menuHeight;
        } else {
          top = this.coords.y + offset;
        }                  

        return {
          left: left ? left + "px" : undefined,
          top: top ? top + "px" : undefined
        }
      }
    }
  },  
}
</script>

<style lang="scss">

@import "././../variables";

.context-menu {
  font-size: $fontSize;
  /* display: none; */
  position: absolute;
  z-index: 10;
  padding: 12px 0;
  width: 240px;
  background-color: $panelColour;
  color: $textColour;
  border: none;
  cursor: pointer;

  /*box-shadow: 1px 1px 2px #111;*/

  .seperator {
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 12px;
    margin-right: 12px;
    border-top: 1px solid $controlSeperator;    
  }
}

.context-menu--active {
  display: block;
}
.context-menu-icon {
   display: inline-block;
  width: 24px;
  text-align: center;
}
.context-menu__items {
  list-style: none;
  margin: 0;
  padding: 0;
}

.context-menu__item {
  display: block;
  margin-bottom: 4px;
}

.context-menu__item:last-child {
  margin-bottom: 0;
}

.context-menu__link {
  display: block;
  padding: 4px 12px;
  color: $textColour;
  text-decoration: none;
}

.context-menu__link:hover {
  color: $textColourLight;
  background-color: $panelColourHover;
  text-decoration: none;
}


</style>
