<template>
    <div class="d-flex">  
      <template v-if="items.length === 0">        
        <div class="ml-2 mr-1 d-flex align-items-center" style="height: 27px">{{noItemsText}}</div>
      </template>      
      <template v-else>
        <b-dropdown                
        :disabled="disabled"         
        size="sm" 
        :text="selectedText" 
        :right="alignRight" 
        :no-caret="nocaret || ((items.length === 1) && (value !== null))"
        :no-flip="noFlip"                
        >
            <template v-if="icon" slot="button-content">
                <font-awesome-icon :icon="icon"></font-awesome-icon>
                {{selectedText}}
            </template>
            <!--multi select -->
            <template v-if="multiselect">
                <b-dropdown-form>
                    <b-button-toolbar>
                        <b-button-group size="sm">
                        <b-button @click="toggleAll(true)">All</b-button>
                        <b-button @click="toggleAll(false)">None</b-button>
                        </b-button-group>
                    </b-button-toolbar>
                    <b-dropdown-divider></b-dropdown-divider>                
                    <b-form-checkbox-group style ="color:#fff; max-height:10rem;"                
                            id="items"
                            class="overflow-auto"
                            @input="checkboxGroupChange"
                            v-model="selected"
                            :options="items"
                            name="items"
                            stacked>
                    </b-form-checkbox-group>                
                </b-dropdown-form>
            </template>
            <!--single select -->
            <template v-else>
                <b-dropdown-item                   
                  v-for="(item, idx) in items" 
                  :class="{ selected : (item.value === value) }"
                  :key="idx" @click="singleSelectChange(item)">                                                      
                  {{ item.text }}                  
                </b-dropdown-item>
            </template>
        </b-dropdown>      
      </template>
    </div>
</template>

<script>
export default {
    name: "DropdownSelect",
    props: { 
        boundaryElement: {
            type: String,
            default: null
        },
        disabled:{
            type:Boolean,
            default:false
        },
        nocaret:{
            type:Boolean,
            default:false
        },
        multiselect:{
            type: Boolean,
            default: false
        },
        noItemsText: {
          type: String,
          default: ""          
        },
        noItemsSelectedText: {
          type: String,
          default: ""          
        },
        noItemsSelectedIcon: {},
        items: {
            type: Array, 
            required:true           
        },                       
        value:{},
        icon:{},
        alignRight:{
          type: Boolean,
          default:false
        },        
        noFlip: {
          type: Boolean,
          default: false          
        }
    }, 
    data() {
      return {        
        selected: [], 
        resizeObserver: null,
        currentBoundary: null
      }
    },     
    watch: {
      value(value) {
        this.selected = value;
      },   
    },
    mounted() {
      if (this.currentBoundary) {
          let el = document.querySelector(this.currentBoundary);              
          this.resizeObserver.unobserve(el);
          this.currentBoundary = null;
      }          

      if (this.boundaryElement) {
        this.resizeObserver = new ResizeObserver(resizables => {          
          let thisEl = this.$el;  
          let selector = '#' + thisEl.id + " .dropdown-menu";          
          let dropDown = document.querySelector(selector);        
          dropDown.style.overflow = "auto";
          dropDown.style.maxHeight = resizables[0].borderBoxSize[0].blockSize + "px"                    
        })

        let el = document.querySelector(this.boundaryElement);     
        this.resizeObserver.observe(el);
        this.currentBoundary = this.boundaryElement;
      }                            
    },
    computed:{       
        // activeItems(){
        //   return this.items.filter(item=> !item.hasOwnProperty('disabled'))
        // },
        dropDownItems() {
          if (this.value)
          {
            return this.items.filter(item => item.value != this.value);
          }
          else
          {
            return this.items;
          }
        },
        selectedText(){
            if(this.multiselect){
                if(this.value.length > 0)
                {
                    if(this.value.length === 1){
                        let item = this.items.find(item => item.value === this.value[0]);
                        return item ? item.text : "";
                    }
                    else 
                        return `${this.value.length} selected`
                }   
                else
                {
                    return "0 Selected";
                }
                    
            }
            else{              
              let item = this.items.find(item => item.value === this.value);
              if (!item)  {
                return this.noItemsSelectedText;
              } else {
                return item.selectedText ? item.selectedText : item.text;
              }              
            }
        }
    },
    destroyed() {      
      if (this.currentBoundary) {
        let el = document.querySelector(this.currentBoundary);              
        this.resizeObserver.unobserve(el);
        this.currentBoundary = null;
      }                
    },
    methods:{            
      singleSelectChange(item){
          this.$emit('input', item.value);       
      },
      checkboxGroupChange(values){
          this.$emit('input', values);  
      },
      toggleAll(selectAll) {

          this.selected = selectAll ? this.selected=this.items.map(item => item.value) : [];
      }        
  }
}
</script>

<style lang="scss">

@import "./../../variables";

.dropdown-item {
  color: $textColourLight !important
}

.dropdown .btn-secondary:focus {    
    background-color: transparent !important;
    color: $textColour
}

</style>

<style lang="scss" scoped>

@import "./../../variables";

#mb-dropdown .dropdown-menu {
  max-height: 100vh;
  overflow-y: auto; 
}

.dropdown.b-dropdown .b-dropdown-form {
    display: inline-block;
    padding: 0.25rem 0.50rem;
    width: 100%;
    clear: both;
    font-size: $fontSize;
}

.dropdown.b-dropdown .b-dropdown-form:focus {
    outline:none;
    outline: 0 !important;
}

</style>
