<template>
  <b-modal id="WaitDialog"
  ref="WaitDialog" 
  hide-header-close
  hide-footer
  no-close-on-backdrop
  no-close-on-esc
  centered>
    <template slot="modal-title">        
      <slot></slot>      
    </template>  
    <template v-if="spinner">
      <div class="d-flex justify-content-center">
        <font-awesome-icon :icon="['fad', 'spinner-third']" size="3x" class="align-self-center mr-2 spinner" spin/>  
      </div>
    </template>
  </b-modal>
</template>

<script>

export default {	 
  name: "WaitDialog",
  methods: {
    show() {
      this.$refs.WaitDialog.show();
    },    
    hide() {      
      this.$refs.WaitDialog.hide();
    },
  },   
  props: {    
    spinner: {
      type: Boolean      
    }
  }
} 

</script>

<style lang="scss" scoped>

</style>

<style lang="scss">

#WaitDialog .modal-dialog {
    max-width: 30% !important;    
    width: 30% !important;    
}

</style>