<template>
  <transition     
  @enter="enter"
  @enter-cancelled="enterCancelled"
  @after-enter="afterEnter"                
  @before-leave="beforeLeave"
  @leave="leave"
  @after-leave="afterLeave"
  @leave-cancelled="leaveCancelled"
  >    
  <slot></slot>        
  </transition>        
</template>

<script>
export default {
  name: "slidable",
  props: {
    from: { 
      type: String,
      required: true
    },    
    slideTimeMs: {
      type: Number,
      required: true
    },          
  },  
  data() {
    return {
      runBeforeEnterMethod: true,    
      isSliding: false,
      position: ""
    }
  },
  methods:
  {
    stripPx(pxString)
    {
      let ret = parseInt(pxString, 10);
      return ret;
    },
    
    beforeEnter(el)
    { 
      // This method is not a standard beforeEnter hook.   It is called from enter.  This is so that
      // JS properties such as element.clientHeight are available.      
      
      if (!this.isSliding) {

        this.$emit("showing", el);
        
        let computedStyle = window.getComputedStyle(el);    
        this.position = computedStyle.getPropertyValue("position");
				
				el.dataset.origLeft = el.style.left;
				el.dataset.origTop = el.style.top;
				el.dataset.origRight = el.style.right;
				el.dataset.origBottom = el.style.bottom;

        if (this.from === "top") {
								
					if ((this.position === "absolute") || (this.position === "fixed")) 
					{
						if (el.style.bottom) {    
							
							el.style.bottom = this.stripPx(el.style.bottom) + el.clientHeight + 'px';                                        
						} 
						
						if (el.style.top || !el.style.bottom) {  
							
							let currentTop =  el.style.top ? this.stripPx(el.style.top) : 0;
							el.style.top = (currentTop - el.clientHeight) + 'px';                    
						} 
          }   
          
          if (this.position === "relative") {

            if (el.style.bottom) {    							
              el.style.bottom = this.stripPx(el.style.bottom) + el.clientHeight + 'px';                                        
            }	else
            {
              let currentTop =  el.style.top ? this.stripPx(el.style.top) : 0;
							el.style.top = (currentTop - el.clientHeight) + 'px';                    
            }
          }
        }     

        if (this.from === "right") {
					
					if ((this.position === "absolute") || (this.position === "fixed")) 
					{
						if (el.style.right) {    
							
							el.style.right = this.stripPx(el.style.right) - el.clientWidth + 'px';                              
						} 
						
						if (el.style.left || !el.style.right) {    
													
							let currentLeft =  el.style.left ? this.stripPx(el.style.left) : 0;
							el.style.left = (currentLeft + el.clientWidth) + 'px';                    
						}            
          }      
          
          if (this.position === "relative") {

            if (el.style.right) {    							

              el.style.right = this.stripPx(el.style.right) - el.clientWidth + 'px';                              
              
            } else
            {
                let currentLeft =  el.style.left ? this.stripPx(el.style.left) : 0;
                el.style.left = (currentLeft + el.clientWidth) + 'px';                        
            }
          }
        }

        if (this.from === "bottom") {
					
					if ((this.position === "absolute") || (this.position === "fixed")) 
					{
						if (el.style.bottom) {    
							
							el.style.bottom = this.stripPx(el.style.bottom) - el.clientHeight + 'px';                              
						} 
						
						if (el.style.top || !el.style.bottom) {  

							let currentTop =  el.style.top ? this.stripPx(el.style.top) : 0;
							el.style.top = (currentTop + el.clientHeight) + 'px';          
						}          
          }        
          
          if (this.position === "relative") {
            if (el.style.bottom) {    							
              el.style.bottom = this.stripPx(el.style.bottom) - el.clientHeight + 'px';                              
              
						} else {  

							let currentTop =  el.style.top ? this.stripPx(el.style.top) : 0;
							el.style.top = (currentTop + el.clientHeight) + 'px';          
						}                      
          }
        }

        if (this.from === "left") {
				
					if ((this.position === "absolute") || (this.position === "fixed")) 
					{
						if (el.style.right) {    
							
							el.style.right = this.stripPx(el.style.right) + el.clientWidth + 'px';                    
						} 
						
						if (el.style.left || !el.style.right) {    								
							let currentLeft =  el.style.left ? this.stripPx(el.style.left) : 0;
							el.style.left = (currentLeft - el.clientWidth) + 'px';                    
						}             
          }
          
          if (this.position === "relative") {

            if (el.style.right) {    							
							el.style.right = this.stripPx(el.style.right) + el.clientWidth + 'px';                    
						} else  {    								
							let currentLeft =  el.style.left ? this.stripPx(el.style.left) : 0;
							el.style.left = (currentLeft - el.clientWidth) + 'px';                    
						}             
          }
        }                       
      }        
    },

    after(el) {      
		 
			el.style.left = el.dataset.origLeft;
			delete el.dataset.origLeft;     

			el.style.top = el.dataset.origTop;
			delete el.dataset.origTop;   

			el.style.right = el.dataset.origRight;
			delete el.dataset.origRight;

			el.style.bottom = el.dataset.origBottom;
			delete el.dataset.origBottom;

      this.position = "";
      this.runBeforeEnterMethod = true;
      this.isSliding = false;
    },

    progress(elements, complete, remaining, start, tweenValue) {           
      let el = elements[0];      

      let diffX = 0;
      let diffY = 0;      
    
      if (this.from === "top") {
							
				if ((this.position === "absolute") || (this.position === "fixed")) 
				{
					if (el.style.bottom) {    
						diffY = Math.abs((this.stripPx(el.style.bottom) - this.stripPx(el.dataset.origBottom)));				
					} 
					
					if (el.style.top || !el.style.bottom) { 
											
            let origTop = el.dataset.origTop ? this.stripPx(el.dataset.origTop) : 0;
            diffY = Math.abs(origTop - this.stripPx(el.style.top));										
					}             
        }     
                
        if (this.position === "relative") {

          if (el.style.bottom) {    
						diffY = Math.abs((this.stripPx(el.style.bottom) -  this.stripPx(el.dataset.origBottom)));				
					} else { 
											
					  let origTop = el.dataset.origTop ? this.stripPx(el.dataset.origTop) : 0;
            diffY = Math.abs(origTop - this.stripPx(el.style.top));		
					}             
        }
      }     

      if (this.from === "right") {
				
				if ((this.position === "absolute") || (this.position === "fixed")) 
				{
					if (el.style.right) {             
            diffX = Math.abs((this.stripPx(el.style.right) -  this.stripPx(el.dataset.origRight)));				
					} 
					
					if (el.style.left || !el.style.right) {  
											
				    let origLeft = el.dataset.origLeft ? this.stripPx(el.dataset.origLeft) : 0;
            diffX = Math.abs(origLeft - this.stripPx(el.style.left));
					}        
        }   
        
        if (this.position === "relative") {
          if (el.style.right) {             
						diffX = Math.abs((this.stripPx(el.style.right) -  this.stripPx(el.dataset.origRight)));				
					} else  {  											
						let origLeft = el.dataset.origLeft ? this.stripPx(el.dataset.origLeft) : 0;
            diffX = Math.abs(origLeft - this.stripPx(el.style.left));		
					}        
        }
      }

      if (this.from === "bottom") {
				
				if ((this.position === "absolute") || (this.position === "fixed")) 
				{
					if (el.style.bottom) {    
						diffY = Math.abs((this.stripPx(el.style.bottom) -  this.stripPx(el.dataset.origBottom)));		
					}
					
					if (el.style.top || !el.style.bottom) { 
												
						let origTop = el.dataset.origTop ? this.stripPx(el.dataset.origTop) : 0;
            diffY = Math.abs(origTop - this.stripPx(el.style.top));
					}       
        }           
        
        if (this.position === "relative") {
          if (el.style.bottom) {    
						diffY = Math.abs((this.stripPx(el.style.bottom) -  this.stripPx(el.dataset.origBottom)));		
					} else { 												
						let origTop = el.dataset.origTop ? this.stripPx(el.dataset.origTop) : 0;
            diffY = Math.abs(origTop - this.stripPx(el.style.top));		
					}       
        }
      }

      if (this.from === "left") {
				
				if ((this.position === "absolute") || (this.position === "fixed")) 
				{
					if (el.style.right) {    
											
						diffX = Math.abs((this.stripPx(el.style.right) -  this.stripPx(el.dataset.origRight)));		
					} 
					
					if (el.style.left || !el.style.right) {  
							
            let origLeft = el.dataset.origLeft ? this.stripPx(el.dataset.origLeft) : 0;            
            diffX = Math.abs(origLeft - this.stripPx(el.style.left));
					}       
        }           
        
        if (this.position === "relative") {
          if (el.style.right) {    
											
						diffX = Math.abs((this.stripPx(el.style.right) -  this.stripPx(el.dataset.origRight)));		
					} else {  
							
						let origLeft = el.dataset.origLeft ? this.stripPx(el.dataset.origLeft) : 0;
            diffX = Math.abs(origLeft - this.stripPx(el.style.left));		
					}       
        }
      }                  
            
      this.$emit("sliding", {el: el, diffX: diffX, diffY: diffY});      
    }, 

    enter(el, done) {      

      if (this.runBeforeEnterMethod) {
        this.beforeEnter(el);      
        this.runBeforeEnterMethod = false;  
      }

      this.isSliding = true;            

      let to = {};
      
      if (this.from === "top") {
							
				if ((this.position === "absolute") || (this.position === "fixed")) 
				{
					if (el.style.bottom) {    
						to.bottom = el.dataset.origBottom;				
					} 
					
					if (el.style.top || !el.style.bottom) { 
											
						let toTop = el.dataset.origTop ? this.stripPx(el.dataset.origTop) : 0;
						to.top = toTop + 'px';
					}             
        }     
                
        if (this.position === "relative") {

          if (el.style.bottom) {    
						to.bottom = el.dataset.origBottom;				
					} else { 
											
						let toTop = el.dataset.origTop ? this.stripPx(el.dataset.origTop) : 0;
						to.top = toTop + 'px';
					}             
        }
      }     

      if (this.from === "right") {
				
				if ((this.position === "absolute") || (this.position === "fixed")) 
				{
					if (el.style.right) {             
						to.right = el.dataset.origRight;
					} 
					
					if (el.style.left || !el.style.right) {  
											
						let toLeft = el.dataset.origLeft ? this.stripPx(el.dataset.origLeft) : 0;
						to.left = toLeft + 'px';
					}        
        }   
        
        if (this.position === "relative") {
          if (el.style.right) {             
						to.right = el.dataset.origRight;
					} else  {  											
						let toLeft = el.dataset.origLeft ? this.stripPx(el.dataset.origLeft) : 0;
						to.left = toLeft + 'px';
					}        
        }
      }

      if (this.from === "bottom") {
				
				if ((this.position === "absolute") || (this.position === "fixed")) 
				{
					if (el.style.bottom) {    
						to.bottom = el.dataset.origBottom;
					}
					
					if (el.style.top || !el.style.bottom) { 
												
						let toTop = el.dataset.origTop ? this.stripPx(el.dataset.origTop) : 0;
						to.top = toTop + 'px';
					}       
        }           
        
        if (this.position === "relative") {
          if (el.style.bottom) {    
						to.bottom = el.dataset.origBottom;
					} else { 
												
						let toTop = el.dataset.origTop ? this.stripPx(el.dataset.origTop) : 0;
						to.top = toTop + 'px';
					}       
        }
      }

      if (this.from === "left") {
				
				if ((this.position === "absolute") || (this.position === "fixed")) 
				{
					if (el.style.right) {    
											
						to.right = el.dataset.origRight;
					} 
					
					if (el.style.left || !el.style.right) {  
							
						let toLeft = el.dataset.origLeft ? this.stripPx(el.dataset.origLeft) : 0;
						to.left = toLeft + 'px';
					}       
        }           
        
        if (this.position === "relative") {
          if (el.style.right) {    
											
						to.right = el.dataset.origRight;
					} else {  
							
						let toLeft = el.dataset.origLeft ? this.stripPx(el.dataset.origLeft) : 0;
						to.left = toLeft + 'px';
					}       
        }
      }                  

      Velocity(el, to, { duration: this.slideTimeMs, easing: "easeOutCubic", complete: done, progress: this.progress });
    },

    enterCancelled(el) {                       
      Velocity(el, "stop");     
      this.$emit("showingCancelled", el);
    },

    afterEnter(el) {      
      this.after(el);
      this.$emit("shown", el);
    },

    beforeLeave(el) {      
      
      if (!this.isSliding) {

        this.$emit("hiding", el);
        
        let computedStyle = window.getComputedStyle(el);    
        this.position = computedStyle.getPropertyValue("position");
				
				el.dataset.origLeft = el.style.left;    
				el.dataset.origTop = el.style.top; 
				el.dataset.origRight = el.style.right;                         
				el.dataset.origBottom = el.style.bottom;    
      }
    },

    leave(el, done) {           
      this.isSliding = true;      

			let to = {};        
			
      if (this.from === "top") {

				if ((this.position === "absolute") || (this.position === "fixed")) 
				{
					if (el.style.bottom) {                  
						to.bottom =  this.stripPx(el.dataset.origBottom) + el.clientHeight + 'px';
					} 
					
					if (el.style.top || !el.style.bottom) { 
						let top = el.dataset.origTop ? this.stripPx(el.dataset.origTop) : 0;
						to.top = (top - el.clientHeight) + 'px';
					}        
        }
        
        if (this.position === "relative") {
          if (el.style.bottom) {                  
						to.bottom =  this.stripPx(el.dataset.origBottom) + el.clientHeight + 'px';
					} else { 
						let top = el.dataset.origTop ? this.stripPx(el.dataset.origTop) : 0;
						to.top = (top - el.clientHeight) + 'px';
					}        
        }
      }

      if (this.from === "right") {

				if ((this.position === "absolute") || (this.position === "fixed")) 
				{
					if (el.style.right) {                             
						to.right =  this.stripPx(el.dataset.origRight) - el.clientWidth + 'px';
					} 
					
					if (el.style.left || !el.style.right) { 
						let left = el.dataset.origLeft ? this.stripPx(el.dataset.origLeft) : 0;
						to.left = (left + el.clientWidth) + 'px';
					}        
        }
        
        if (this.position === "relative") {
          if (el.style.right) {                             
						to.right =  this.stripPx(el.dataset.origRight) - el.clientWidth + 'px';
					} else { 
						let left = el.dataset.origLeft ? this.stripPx(el.dataset.origLeft) : 0;
						to.left = (left + el.clientWidth) + 'px';
					}        
        }
      }

       if (this.from === "bottom") {

				if ((this.position === "absolute") || (this.position === "fixed")) 
				{
					if (el.style.bottom) {                                
						to.bottom =  this.stripPx(el.dataset.origBottom) - el.clientHeight + 'px';
					}
					
					if (el.style.top || !el.style.bottom) { 
						let top = el.dataset.origTop ? this.stripPx(el.dataset.origTop) : 0;
						to.top = (top + el.clientHeight) + 'px';
					}      
        }  
        
        if (this.position === "relative") {
          if (el.style.bottom) {                                
						to.bottom =  this.stripPx(el.dataset.origBottom) - el.clientHeight + 'px';
					} else  { 
						let top = el.dataset.origTop ? this.stripPx(el.dataset.origTop) : 0;
						to.top = (top + el.clientHeight) + 'px';
					}      
        }
      }

      if (this.from === "left") {

				if ((this.position === "absolute") || (this.position === "fixed")) 
				{
					if (el.style.right) {                              
						to.right =  this.stripPx(el.dataset.origRight) + el.clientWidth + 'px';
					}
					
					if (el.style.left || !el.style.right) { 
						let left = el.dataset.origLeft ? this.stripPx(el.dataset.origLeft) : 0;
						to.left = (left - el.clientWidth) + 'px';
					}        
        }
        
        if (this.position === "relative") {
          if (el.style.right) {                              
						to.right =  this.stripPx(el.dataset.origRight) + el.clientWidth + 'px';
					} else { 
						let left = el.dataset.origLeft ? this.stripPx(el.dataset.origLeft) : 0;
						to.left = (left - el.clientWidth) + 'px';
					}        
        }
			}
    
      Velocity(el, to, { duration: this.slideTimeMs, easing: "easeOutCubic", complete: done, progress: this.progress });
    },

    leaveCancelled(el) {                             
      Velocity(el, "stop");
      this.$emit("hidingCancelled", el);
    },

    afterLeave(el) {            
      this.after(el);            
      this.$emit("hidden", el);
    }
  }
}
</script>