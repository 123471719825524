<template>  
	<div 
  class="menuBar"
  :class="{'h-100': isVertical, 'w-100': isHorizontal}">  
    <div 
    class="d-flex position-relative"    
    :class="flexWrapperClasses">    
      <div       
      class="menuContainer position-relative z-index-2"
      :class="{'h-100': isVertical, 'w-100': isHorizontal }"
      :style="menuContainerStyle">
        <Resizable				            
        ref="menu"
        class="slider position-relative h-100"                                          
        :resizeTop="menu.isResizable && side === 'bottom'"
        :resizeRight="menu.isResizable && side === 'left'"
        :resizeBottom="menu.isResizable && side === 'top'"
        :resizeLeft="menu.isResizable && side === 'right'"        
        :minSize="menu.minSize"
        :maxSize="menu.maxSize"  
        :size="menu.size"      
        @resizing="menuResizing"
        @resized="menuResized"
        >
          <portal-target            
          :name="'menus' + '/' + menu.name"
          class="slider position-absolute h-100 w-100">
          </portal-target>
          
        </Resizable>    
      </div>
      <div       
      class="position-relative z-index-1"
      :style="subMenuContainerStyle"
      >
        <template v-for="subMenu in subMenus">                
          <Slidable        
          :key="'subMenu ' + subMenu.name"
          :from="side"
          :slideTimeMs="slideTimeMs"                
          @showing="subMenuShowing(subMenu, $event)"          
          @shown="subMenuShown(subMenu, $event)"
          @hiding="subMenuHiding(subMenu, $event)"          
          @hidden="subMenuHidden(subMenu, $event)"
          @sliding="subMenuSliding(subMenu, $event)"          
          >
            <MenuBar                    
            class="position-absolute"          
            :slideTimeMs="slideTimeMs"
            v-show="(menu.openItem === subMenu.name)"
            ref="subMenus"
            :side="side"
            :menu="subMenu"                
            @resizing="subMenuResizing"                  
            @resized="subMenuResized"            
            > 
            </MenuBar>               
          </Slidable>
        </template>                          
      </div>      
		</div>	
	</div>
</template>    

<script>

import MenuBar from "./MenuBar.vue"

export default {  
  name: "MenuBar",  
  props: { 
    menu: {
      type: Object,      
      required: true
    },    
    side: {
      type: String,      
      required: true
    },   
    slideTimeMs: {
      type: Number,
      default: 275
    },      
  },  
  mounted() {        
       
    let left, right, top, bottom;
    
    if (this.side === "left" || this.side === "top") {      
      left = 0;
      top = 0;      
    }         

    if (this.side === "right") {      
      right = 0;
      top = 0;      
    }

    if (this.side === "bottom") {      
      left = 0;
      bottom = 0;      
    }   

    this.subMenus.forEach((subMenu, index) => {    

      let el = this.$refs.subMenus[index].$el;      
      
      if (this.isVertical) {
        subMenu.visibleSize = el.clientWidth;
      } else {
        subMenu.visibleSize = el.clientHeight;
      }      

      if (left != undefined) el.style.left = left + "px";
      if (top != undefined) el.style.top = top + "px";
      if (right != undefined) el.style.right = right + "px";
      if (bottom != undefined) el.style.bottom = bottom + "px";
    })              
    
    // necessary so that slidableContainerStyle is run before handlers of emit event.
    // https://vuejs.org/v2/guide/reactivity.html
    this.$nextTick(() => { 
				this.$emit("resized", this.menu, this.$el);
    });
  },        
  
  computed:
  {     
    subMenus() {
      return this.menu.menus ? this.menu.menus : []
    },     

    menuContainerStyle() {      

      if (this.isVertical) {
        return { width: this.menu.size + "px" };
      } else {
        return { height: this.menu.size + "px" };
      }            
		},

    subMenuContainerStyle() {      

      let sizes = this.subMenus.map(subMenu => {return subMenu.visibleSize});      
			let size = Math.max(...sizes);   			      
 
      if (this.isVertical) {
        return { width: size + "px" };
      } else {
        return { height: size + "px" };
      }            
		},

    isVertical()
    {
      return (this.side === "left" || this.side === "right");      
    },

    isHorizontal()
    {
      return (this.side === "top" || this.side === "bottom");
    },

    flexWrapperClasses()
    {      
      return { 'flex-row-reverse' : this.side === "right",
                'h-100': this.isVertical, 
                'w-100': this.isHorizontal,
								'flex-column' : this.side === "top",
								'flex-column-reverse': this.side === "bottom"
      }            
    },        
  },  

  methods: {    

    definedOrZero(number) {
      return number ? number : 0;
    },

    subMenuShowing(slidable, el) {      
      el.style.zIndex = "2";            
    },
    
    subMenuShown(slidable, el) {      
			el.style.zIndex = "2";      
			if (this.isVertical) {
				slidable.visibleSize = el.clientWidth;
			} else {
				slidable.visibleSize = el.clientHeight;
      }   
      
			// necessary so that slidableContainerStyle is run before handlers of emit event.
			// https://vuejs.org/v2/guide/reactivity.html
			this.$nextTick(() => { 
				this.$emit("resized", this.menu, this.$el);
			});
    },

    subMenuHiding(subMenu, el) {
			el.style.zIndex = "1";                  
    }, 

    subMenuHidden(subMenu, el) {      
      el.style.zIndex = "2";      
      subMenu.visibleSize = 0;            
			     
    	this.$nextTick(() => {
				this.$emit("resized", this.menu, this.$el);
			});
    },

    subMenuSliding(subMenu, progress) {
       
      if (this.isVertical) {
			  subMenu.visibleSize = Math.abs(progress.el.clientWidth - progress.diffX);
      } else {
			  subMenu.visibleSize = Math.abs(progress.el.clientHeight - progress.diffY);
      }        

			this.$nextTick(() => {
				this.$emit("resizing", this.menu, this.$el);
			});      
    },  

     subMenuResizing(subMenu, el) {			

      if (this.isVertical) {
        subMenu.visibleSize = el.clientWidth;
      } else {
        subMenu.visibleSize = el.clientHeight;
      }          
			
      this.$nextTick(() => {        
        this.$emit("resizing", this.menu, this.$el);
			});
    },

    subMenuResized(subMenu, el) {			

      if (this.isVertical) {
        subMenu.visibleSize = el.clientWidth;
      } else {
        subMenu.visibleSize = el.clientHeight;
      }          
			
      this.$nextTick(() => {        
        this.$emit("resized", this.menu, this.$el);
			});
    },

    menuResized(resizableDivComponent, data) {
      this.menu.size = data.size;
      
			this.$nextTick(() => {      
				this.$emit("resized", this.menu, this.$el);
			});
    },

    menuResizing(resizableDivComponent, data) {      

      this.menu.size = data.size;
      
			this.$nextTick(() => {        
				this.$emit("resizing", this.menu, this.$el);
			});
    },    
  }
};
</script>