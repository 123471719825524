<template>
    <div id="load-wrapper" :style="style">
      <div class="text-center loaderIcon">
        <img v-if="$appGlobals.environment === 'production'" src="../assets/icon200x200.png"/>
        <img v-if="$appGlobals.environment === 'development'" src="../assets/icon200x200_dev.png"/>
        <img v-if="$appGlobals.environment === 'staging'" src="../assets/icon200x200_stage.png"/>
      </div>
      <template v-if="spinVisible">
        <div class="text-center loaderSpinner"><font-awesome-icon class="spinner" spin :icon="['fad', 'spinner-third']"/></div>
      </template>
      <div class="text-center loaderMessage">Mapping Browser</div>
      <div class="loaderInfo">{{message}}</div>
      <div class="loaderVersion">{{appVersion}}</div>
    </div> 
</template>

<script>
export default {
  props: {
    visible: { type: Boolean },
    appVersion: { type: String, required: true},    
    spinVisible: { type: Boolean, default: true },
    message: { type: String, default: "..." },    
    mainMessage: { type: String, default: "..." },
  },
  computed: {    
    style() {
      if (!this.visible) {
        return {
          opacity: 0,
          visibility: 'hidden',
          pointerEvents: 'none'
        }
      }
    }
  },  
}
</script>

<style lang="scss">
@import "../variables";


.center-loader {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
}






#load-wrapper {
  z-index:998;
  position: fixed;
  background: $panelContent;
  color:$textColourHover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity:1;
  transition:visibility 5.0s linear,opacity 1s linear;
  /*padding: 20px;*/

  .pointer-events-none {
     pointer-events: none;
  }
}	

.loaderIcon{
  margin-top: 10rem;
}
.loaderSpinner{
  position: relative;
  margin-top: 4rem;
}

.loaderMessage{
  margin-top: 4rem;
  font-size: 1.7rem;
  font-weight: 100;
   color:$textColourDark;
}


// .loadingInfo{
//     height: 2rem; 
//     position: fixed; 
//     bottom:0%;
//     width:100%; 
//     opacity: 1;
//     /*border-top: 2px solid #0093c4;*/

//     background: lighten($panelContent,2%);
// }

.loaderVersion{
  font-size: 0.8rem;
  position: fixed;
  bottom:1.5rem;
  right:1.5rem;
  color:$textColourDark;
}
.loaderInfo{
  font-size: 0.8rem;
  position: fixed;
  bottom:1.5rem;
  left:1.5rem;
  color:$textColourDark;
}

.spinner{
    --fa-primary-color: #4fc3f7;
    --fa-secondary-color: dimgray;
    font-size: 3rem;
}
</style>
