<template> 
  <div>        
    <b-modal id="ProjectSaveLoadDialog"
    ref="ProjectSaveLoadDialog"
    :ok-disabled="!allValid"    
    @shown="projectSaveLoadDialogShown" 
    @ok="okButtonClicked" 
    @hidden="dialogHidden"         
    centered>     
      <template slot="modal-title">      
        <div class="d-flex justify-content-center align-items-center">
          <font-awesome-icon icon="map-marked" class="mr-2"/>
          <template v-if="isLoad">
            Load Project
          </template>
          <template v-else>
            Save Project
          </template>
        </div> 
      </template>          
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column">          
          <div class="d-flex my-2">        
            <span class="d-flex align-items-center" :style="{'width': '80px'}">Name:</span>
            <span><b-form-input ref="nameInput" v-model="name" @keyup.enter="enterButton"/></span>
          </div>
          <div class="d-flex my-2">        
            <h6 class="d-flex mt-1" :style="{'width': '80px'}">Include bookmarks:</h6>
            <div class="d-flex flex-column">                            
              <b-form-checkbox 
              id="includeBookmarksInput" 
              name="includeBookmarksInput" 
              class="mt-1"
              value="yes"
              unchecked-value="no"
              v-model="includeBookmarks"
              @keyup.enter="enterButton"/>
            </div>            
          </div>        
        </div>  
      </div>
    </b-modal>    
  </div>     
</template>

<script>

import axios from 'axios';
import ErrorDialog from './shared/ErrorDialog.vue';
import DropdownSelect from "./shared/DropdownSelect.vue"; 
import {  isValidCoordString } from "./SpatialUtils.js";

export default {	
  components: { ErrorDialog, DropdownSelect },   
  props: { 
    isLoad: false
  },
  data() {
    return {
      name: "",
      error: "",
      saveErrorTitle: "Cannot save project",      
      includeBookmarks: "yes",
    }
  },    
  computed: {
    allValid() {      
      return true;
    },     
  },
  methods: { 
    show()
    {
      this.$refs.ProjectSaveLoadDialog.show();
    },
    hide()
    {
      this.$refs.ProjectSaveLoadDialog.hide();
    },  
    projectSaveLoadDialogShown(e) {                  
      this.includeBookmarks = "yes"; 
      this.error = "";
      this.$refs.nameInput.focus()
    },
    handleOK() {      
      this.error = "";      

      if (!this.error) {                               
        this.name = "";        
      }
    },   
    dialogHidden() {
      if (this.error){        
        this.$refs.ProjectSaveLoadDialog.show();
      }
    },
    enterButton()
    {
      if (!this.allValid) {
        return;
      }
      
      this.handleOK();
      this.hide();
    },
    errorHidden() {      
      this.show();
    },    
    okButtonClicked(bvModalEvt){
      this.handleOK();     
    }    
  }  
} 

</script>

<style lang="scss" scoped>

</style>

<style lang="scss">

@import "././../variables";
 
#ProjectSaveLoadDialog .modal-dialog {
    max-width: 500px !important;
    margin: 1.75rem auto; 
}

</style>