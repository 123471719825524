<template>
	<portal to="menus/left">
    <div class="leftMenu d-flex flex-column align-items-center h-100">
      <ToggleButton 
      :menuItems="menuItems" 
      :height="48" 
      :width="48" 
      selectionSide="left" 
      subMenuName="search" 
      icon="search" 
      v-b-popover.hover.right="'Search for features'"
      @openMenuItem="$emit('openMenuItem', $event)" 
      @closeMenuItem="$emit('closeMenuItem', $event)"/>            
      <ToggleButton 
      @openMenuItem="$emit('openMenuItem', $event)" 
      @closeMenuItem="$emit('closeMenuItem', $event)" 
      :menuItems="menuItems" 
      :height="48" 
      :width="48" 
      selectionSide="left" 
      subMenuName="layerManager" 
      icon="layer-group"
      v-b-popover.hover.right="'Layers'"
      />
      <ToggleButton 
      @openMenuItem="$emit('openMenuItem', $event)" 
      @closeMenuItem="$emit('closeMenuItem', $event)" 
      :menuItems="menuItems" 
      :height="48" 
      :width="48"
      selectionSide="left" 
      subMenuName="basemapManager"
      icon="globe-europe"
      v-b-popover.hover.right="'Basemaps'"
      />
      <ToggleButton 
      @openMenuItem="$emit('openMenuItem', $event)" 
      @closeMenuItem="$emit('closeMenuItem', $event)" 
      :menuItems="menuItems" 
      :height="48" 
      :width="48" 
      selectionSide="left" 
      subMenuName="bookmarkManager" 
      :icon="['fas', 'bookmark']"
      v-b-popover.hover.right="'Bookmarks'"
      />     
      <template v-if="!$appGlobals.isLargeScreen">
        <ToggleButton 
        v-b-popover.hover.right="'Properties'"
        :menuItems="menuItems" 
        :height="48" 
        :width="48"
        selectionSide="left" 
        subMenuName="properties" 
        icon="info-circle"   
        iconTransform="grow-2"
        @openMenuItem="$emit('openMenuItem', $event)" 
        @closeMenuItem="$emit('closeMenuItem', $event)"/>       
      </template>
    </div>
	</portal>  
</template>

<script>  

import ToggleButton from "./ToggleButton.vue"

export default {         
  components: { ToggleButton },  
  props:
  {
    menuItems: {
      type: Object,
    }
  },
}

</script>

<style lang="scss">
@import "../../variables";

.leftMenu {
   background-color: $panelColour;
   
   button {      
    background-color: inherit;
    border: none;
    color: $textColour;      
    cursor: pointer;          

    &:hover {    
      background-color: transparent;
      color: $textColourHover
    }  
  }   
}


</style>