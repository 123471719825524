<template>
    <div class="basemapItem pb-2 pt-2 " >
      <div class="d-flex mb-10" >
			<div class="flex-shrink-1 p-2"><img class="thumbnail rounded-circle mr-2" :src="basemapThumbnail" alt="..."></div>
            <div class="w-100 pt-2">
                <div class="basemapItemTitle">{{basemap.title}}</div>
                <div class="basemapItemDescription">{{basemap.description}}</div>
            </div>
        </div>
        <div class="d-flex pt-2 align-items-center">
            <div class="flex-grow-1 p-2 mr-1">
                <vue-slider :duration="0.125" :value="basemap.opacity*100" @change="onSliderChange"/>
            </div>
            <div class="flex-shrink-0 pl-2 pr-2">
                <button 
                    v-b-popover.hover.top="'Visibility'" 
                    class="basemapItemButton bg-transparent border-0 p-0 text-center ml-auto mr-2" 
                    :class="{ selected : basemap.visible }"
                    @click="visibilityChangeHandler()">
                      <font-awesome-icon                       
                      :icon="basemap.visible ? 'eye' : ['far', 'eye']"/>
                </button>
            </div>        
        </div>
    </div>
</template>

<script> 
import VueSlider from 'vue-slider-component'
export default {
   components:{VueSlider},
    props: ['basemap'],
    computed: {         
        basemapThumbnail() {            
            return new URL(`/src/assets/basemaps/${this.basemap.thumbnail}`, import.meta.url)
        }
    },
    methods:{
        visibilityChangeHandler(){
            this.$emit('onBasemapChange',{
                basemap: this.basemap,
                opacity: this.basemap.opacity,
                visible: !this.basemap.visible
            })
        },
        onSliderChange(payload){
            this.$emit('onBasemapChange',{
                basemap: this.basemap,
                opacity: payload/100,
                visible: this.basemap.visible
            })
        }
    }
}
</script>

<style scoped lang="scss">

@import "./../variables";

.basemapItem{ 
    border-bottom: 1px solid $panelColourSeperator;

    .thumbnail {
      //border: 3px solid $panelColourSeperator;
      box-shadow: 0 0 0 6px rgba(10, 10, 10, 0.125)
    }
}

.basemapItemTitle{
    color: $textColourLight;
    font-size:$fontSizeLg;
}    
.basemapItemDescription{
    font-size: $fontSize;    
    font-family: $fontfamilyCondensed;
    color: $textColourDark;
}
.basemapItemButton{
    color: $textColourDark;

    &.selected {
      color: $textColourLight;
    }   
}
.basemapItemButton:hover{
    color:$textColourHover;
}
</style>
