<template>    
  <div class="PasteableBoxes d-flex" :class="fullWidth ? 'w-100' : ''">            
    <template v-if="canEdit && editing">
      <input             
      :class="fullWidth ? 'flex-grow-1' : ''"
      ref="editableInput"
      v-focusOnInsert v-selectOnInsert v-sizeToPlaceholder
      v-model="editedText"       
      @focusout="onFocusOut"
      @keydown.esc="escape"
      :placeholder="editingPlaceHolder"
      @keydown.enter="enter"/>    
    </template>
    <template v-else-if="canEdit && !value && placeHolder">      
      <span :class="fullWidth ? 'flex-grow-1' : ''" class="break-word min-width-0 text-ellipsis overflow-hidden font-italic" v-on:click="onClick">{{placeHolder}}</span>    
    </template>
    <template v-else>
      <template v-if="isUrl(value)">
        <a :class="fullWidth ? 'flex-grow-1' : ''" :href="value" target="_blank" class="break-word min-width-0 text-ellipsis overflow-hidden" v-on:click="onClick">
          {{value}}
        </a>
      </template>
      <template v-else>        
        <span :class="fullWidth ? 'flex-grow-1' : ''" class="break-word min-width-0 text-ellipsis overflow-hidden" v-on:click="onClick">{{value}}</span>    
      </template>
    </template>
  </div>
</template>

<script>

export default {
  name: 'PasteableBoxes',    
  props: {
    value: "",
    commitOnClickAway: {
      type: Boolean,         
    },
    canEdit: {
      type: Boolean,      
      default: true
    },
    placeHolder: {
      type: String
    },
    editingPlaceHolder: {
      type: String,      
    },
    fullWidth: {      
      type: Boolean,      
      default: false
    },
    edit: null          
  },  
  watch: {
    edit() {      
      this.editing = this.edit;
    },
    editing() {
      if (this.editing) {
        this.editedText = this.value;        
      } 
    }
  },
  data() {
    return {  
      editing: false,
      editedText: "",
      entered: false,
      escaped: false,
    }
  },      
  methods: {   
    onFocusOut() {
  
      if (this.escaped) {
        this.escaped = false;
        return;
      } else if (this.entered) {
        this.entered = false;
        this.$emit("input", this.editedText);
      } else if (this.commitOnClickAway)  {
        this.$emit("input", this.editedText);        
        this.setEditing(false);
      } else {
        this.setEditing(false);
      }
    },
    escape() {   
      this.escaped = true;
      this.setEditing(false);
    },
    enter() {   
      this.entered = true;      
      this.setEditing(false);
    },
    onClick() {      
      if (!this.canEdit) {
        return;
      }

      this.setEditing(true);
    },    
    setEditing(edit) {            
      if (this.edit !== undefined) {
        this.$emit("edit", edit);                                    
      } 
      else 
      {        
        this.editing = edit;
      }              
    }    
  },   
}    
</script>

<style lang="scss" scoped>
@import "../../variables";

.break-word {
  word-wrap: break-word;
}

</style>