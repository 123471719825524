<template>
    <portal :to="'menus/' + menuName">
        <div class="basemapManager h-100 d-flex flex-column"> 
            <SliderHeader text="BASEMAPS" :menuName="menuName"  
            @showHelp="$emit('showHelp','basemaps')"
            @openMenuItem="$emit('openMenuItem', $event)" 
            @closeMenuItem="$emit('closeMenuItem', $event)" 
            >
            </SliderHeader>                        
            <div class="mainContent flex-grow-1 d-flex overflow-auto flex-column">
              <ul id="v-for-object" class="px-2">
              <li v-for="basemap in basemaps" v-bind:id="basemap.id" :key="basemap.id">
                  <BasemapItem :basemap="basemap" 
                  @onBasemapChange="$emit('onBasemapChange', $event)"
                  />
              </li>
              </ul>                            
            </div>
        </div>
    </portal>      
</template>

<script>
import SliderHeader from './shared/SliderHeader.vue';
import BasemapItem from "./BasemapItem.vue";
export default {
    components: { SliderHeader, BasemapItem },
    props: { 
        basemaps: {
            type: Array, 
            required:true           
        }
    },   
    data() {
      return {
        menuName: "basemapManager"
      }
    },        
}
</script>

<style scoped lang="scss">
@import "./../variables";

.basemapManager {
  color: $textColour;
  background-color: $panelColour;

  .mainContent{
    background:  $panelContent;
  }

  ul {
    list-style-type: none;
  }
  ul, li { /* reset the margin and padding */
      margin: 0;
      padding: 0;
  }
  li:not(:last-child) {
      margin-bottom: 2px;
  }
}

</style>
