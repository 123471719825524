<template>  
    <div class="h-100" style="overflow: hidden">
        <transition 
            @before-enter="beforeEnter"
            @enter="enter"
            @after-enter="afterEnter"                
            @before-leave="beforeLeave"
            @leave="leave">            
            <div v-show="!showChildWhen"
                 key="parent" 
                 ref="parent" 
                 class="parent h-100"> 
                <slot name="sliderParent"></slot>
            </div>
        </transition>              
        <transition 
            @before-enter="beforeEnter"
            @enter="enter"
            @after-enter="afterEnter"                
            @before-leave="beforeLeave"
            @leave="leave">            
            <div v-show="showChildWhen"
                key="child" 
                ref="child"                 
                class="child h-100">
                <slot name="sliderChild"></slot>
            </div>        
        </transition>              
    </div>
</template>

<script>
export default {
    name: 'SubSlider',
    props: {
        showChildWhen: {
            type: Boolean,
            required: true
        },        
        slideTimeMs: {
            type: Number,
            required: true
        }
    },

    methods: {

        beforeEnter(el) {                     	              
            let isChild = el.classList.contains("child"); 
            if (isChild) {                              
                el.style.position = 'relative';                                                      
                el.style.left = this.$refs.parent.offsetWidth + 'px';
                el.style.top = -this.$refs.parent.offsetHeight + 'px';                       
            }
        },  
    
        enter(el, done) {                        
            let isChild = el.classList.contains("child"); 
            if (isChild) {              
                Velocity(el, { left: '0px' }, this.slideTimeMs, "easeOutCubic", done );
            }
            else{
                Velocity(el, { left: this.$refs.parent.offsetLeft + 'px' }, this.slideTimeMs, "easeOutCubic", done );   
            }
        },

        afterEnter(el) {            
            let isChild = el.classList.contains("child"); 
            if (isChild) 
            {
                el.style.left = "";      
                el.style.position = "static";
                el.style.top = "";
            }
        },
            
        beforeLeave (el) {                    
            let isChild = el.classList.contains("child"); 
            if (isChild) {                                               
                el.style.position = 'relative';                   
                el.style.top = -this.$refs.parent.offsetHeight + 'px'; 
            }
        },  
        
        leave (el, done) {                
            if (el.classList.contains("child")) {
                Velocity(el, { left: this.$refs.parent.offsetWidth + 'px' }, this.slideTimeMs, "easeOutCubic", done )   
            }
            else {                
                Velocity(el, { left: this.$refs.parent.offsetLeft + 'px' }, this.slideTimeMs, "easeOutCubic", done )   
            }				
        },
    }
}    
</script>