<template>
    <div 
      :class="{ isFocused: isFocused }" 
      class="searchInput d-flex flex-column">      
      <div class="d-flex align-items-center">
        <template v-if="showSearchIcon">
          <font-awesome-icon class="searchIcon mr-1" icon="search" transform="shrink-2"/>
        </template>
        <b-form-input ref="inputControl" class="inputControl" 
          :placeholder="placeholder"
          @focus.native="isFocused = true"
          @focusout.native="isFocused = false"          
          @input="onInputTextChanged"
          v-model="inputValue">
        </b-form-input>
        <button class="clearIcon pr-2" v-if="inputValue.length" @click="clearButtonClicked">
            <font-awesome-icon :icon="['fal', 'times']" transform="grow-2"/>
        </button>       
      </div>
      <div class="slither" :class="{ isFocused: isFocused }"/>
    </div>    
</template>
<script>

export default {
    props:{      
        value: 
        { 
          required: true
        },
        updateOnChange: {
          type: Boolean,
          default: true
        },
        placeholder: {
            type: String,
            default: ""
        },
        showSearchIcon: {
          type: Boolean,
          default: true
        },
        delayChangeMs: {
          type: Number,
          default:0
        }        
    },
    data() {
      return {
        inputValue: this.value,
        isFocused: false,
        delayTimer: null,
      }
    },
    watch: {
      value(value) {
        this.inputValue = value;
      }
    },
    methods:{
        focus(){
          this.$refs.inputControl.focus();
        },      
        select() {
          this.$refs.inputControl.select();
        },
        clearButtonClicked() {          
          this.inputValue = "",
          this.$emit("input", this.inputValue);
        },
        onInputTextChanged() {
          if (this.updateOnChange) {
            if (this.delayChangeMs > 0) {
              if (this.delayTimer !== null) {
                clearTimeout(this.delayTimer);
              } 

              this.delayTimer = setTimeout(() => {
                this.delayTimer = null;
                this.$emit("input", this.inputValue);                
              }, this.delayChangeMs);
            } else
            {
              this.$emit("input", this.inputValue);
            }             
          } 
        },
        onInputEnterKey() {
          if (!this.updateOnChange) {
            this.$emit("input", this.inputValue);
          }
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../variables";
   
.searchInput{

  border-bottom: 1px solid #9da9c4;  

  &.isFocused {
    border-bottom:1px solid $pColour;
  }

  .slither {
    height: 0px;
    border-top: 1px solid transparent;

    &.isFocused {
      border-top:1px solid $pColour;
    }
  }
  
  .inputControl {
    border-bottom: none;
  }

  button {
    border:none;
    padding: 0px;
    color:inherit;
    background: inherit;
    cursor: pointer;
    outline: 0 !important;   
  }
}

</style>
